import React, { Component } from 'react';
import {
  Card, Badge, Button,Layout,Banner,Link,Select,Stack
} from '@shopify/polaris';
import ToastMessage from '../Common/ToastMessage';
import PageTitleBar from '../Common/TitleBar';
import SkeletonPageComp from './SkeletonPageComp';
import { ChevronRightMinor, ClipboardMinor } from '@shopify/polaris-icons';
import axios from '../Axios';
const BUILD_VERSION_EXTENSION =  process.env.REACT_APP_BUILD_VERSION_EXTENSION;

var request_cancel = null;
export default class InstallationConfigurationList extends Component {

    /* Component Settings */
    constructor(props) {
        super(props);
        this.state = {

            // Verification + Installation

            token_verified:false,
            token:'',
            config_id:null,
            selected_base_theme:null,
            verify_btn_loading:false,
            theme_compatible: true, /* if selected theme is compatible or not */

            // Auto removal
            removal_show:false,
            removal_theme_selected_id:'',
            removal_config_id:null,
            removal_base_theme:'',
            show_file_removal_article:false,

            /* Table Data */
            items:[],
            theme_selected_id:'',

            successfully_deleted:false,
            deleted_theme_name:'',


            /* To send shop info with ajax */
            host_name: this.props.host_name,
            shop: this.props.shop,

            /* UI state */
            page_loaded: false,
            next_btn_loading: false,
            delete_btn_loading:false,
            
            /* Toast managemnt */
            showToast: false,
            toastError: false,
            toastMsg: ""
        };
        axios.defaults.headers.common['X-Ws-Shop-Domain'] = "https://"+this.props.shop;
        
    }

    /* Will set states that will show the success toast or error toast */
    showToastMessage(msg, error){
        this.setState({
        showToast: true,
        toastMsg: msg,
        toastError: error||false,
        });
    }

    /* Hide the toast */
    dissmissToast = () =>{
        this.setState({
        showToast: false,
        });
    }

    /* React Lifecycle: when page is jsut laoded */
    componentDidMount() {
        this.fetch_data();

        this.can_remove_themes();
    }

        // WIll check if any config found, only show removal section  then
        can_remove_themes(){

            axios.get('/app/v238/get-shop-config-count?shop='+this.props.shop)
            .then(x=>x.data)
            .then(response => {
                if(typeof response.error !== "undefined" && response.error)return;
                
                this.setState({
                    removal_show: response.show_removal,
                });
            })
            .catch(error => {});
    
    
        }
    
    
    /* Will fetch items from server via API call */
    fetch_data(){
        if(request_cancel){
        // Cancel request
        try {
            request_cancel.cancel();
        } catch (e) {}
        }
        
        const cancelTokenSource = axios.CancelToken.source();
        request_cancel = cancelTokenSource;

        var url = '/app/v238/get-themes-list?shop='+this.props.shop;
        
        axios.get(url, {
        cancelToken: cancelTokenSource.token
        })
        .then(x=>x.data)
        .then(response => {
            if(typeof response.error !== "undefined" && response.error)return;
            
            var items = response.themes || [];
            this.setState({
                page_loaded: true,
                items:items
            });
        })
        .catch(error => {});
    }

    /* Will fetch items from server via API call */
    fetch_removal_data(){
        if(request_cancel){
        // Cancel request
        try {
            request_cancel.cancel();
        } catch (e) {}
        }
        
        const cancelTokenSource = axios.CancelToken.source();
        request_cancel = cancelTokenSource;

        var url = '/app/v238/get-removal-data?shop='+this.props.shop+'&theme_id=';
        
        axios.get(url, {
        cancelToken: cancelTokenSource.token
        })
        .then(x=>x.data)
        .then(response => {
            if(typeof response.error !== "undefined" && response.error)return;
            
            var items = response.themes || [];
            this.setState({
                page_loaded: true,
                items:items
            });
        })
        .catch(error => {});
    }

    /* Delete item */
    delete_item(){
        var base_theme = this.state.removal_base_theme;
        var config_id = this.state.removal_config_id;
        var confirmation = config_id !== '0' ? window.confirm(`Press 'OK' to continue to remove Wholesale All In One app integration from all '${base_theme.toUpperCase()}' themes.`) : true;
        if(confirmation){

            this.setState({delete_btn_loading:true});

            var theme_id = this.state.removal_theme_selected_id.replace('wholesale','');

            var url = '/app/v238/delete-theme-installation?shop='+this.props.shop+'&id='+config_id+'&theme_id='+theme_id;
            axios.delete(url)
            .then(x=>x.data)
            .then( response => {
                

                if(typeof response.files_detected !== "undefined" && response.files_detected === true){
                    this.setState({
                        delete_btn_loading:false, 
                        show_file_removal_article:true,
                        removal_config_id:'',
                        removal_base_theme:'',
                    });
                }
                else{
                    this.setState({
                        show_file_removal_article:false,
                        delete_btn_loading:false, 
                        removal_theme_selected_id:'',
                        removal_config_id:'',
                        removal_base_theme:'',
                    });

                    if(response.status === 1){
                        this.setState({
                            successfully_deleted:true,
                            deleted_theme_name:response.theme_name
                        });
                    }
                    else{
                        this.showToastMessage("Server Error!", true);
                    }
                }

                
            }).catch(err => {
                this.showToastMessage("Server Error", true);
                this.setState({delete_btn_loading:false});
            });
        }
    }

    // Will check if configuration exist against theme id
    CanAutoRemove(removal_theme_selected_id){
        this.setState({
            delete_btn_loading:true,
            successfully_deleted:false,
            show_file_removal_article:false
        });

        var theme_id = removal_theme_selected_id.replace('wholesale','');

        axios.post('/app/v238/check-installation', {
            theme_id:theme_id,
            shop:this.props.shop
        })
        .then(x=>x.data)
        .then(response => {
            this.setState({ delete_btn_loading: false });

            if(typeof response.error !== "undefined" && response.error){
                this.showToastMessage(response.error, true);
                return;
            }

            
            if(response.installed && response.installed === true){

                this.setState({
                    removal_config_id:response.id,
                    removal_base_theme:response.base_theme
                });
                
            }
            else{
                if(typeof response.files_detected !== "undefined" && response.files_detected === true){
                    this.setState({
                        removal_config_id:'0',
                        removal_base_theme:''
                    });
                }
                else{
                    this.setState({
                        removal_config_id:'',
                        removal_base_theme:''
                    });
                }
                
            }
            
            

        }).catch(error => {
            this.showToastMessage("Error", true);
        });
    }
  
    /* Verify current installation */
    verifyInstallation(){

        this.setState({ verify_btn_loading: true });

        var theme_id = this.state.theme_selected_id.replace('wholesale','');

        axios.post('/app/v238/verify-installation', {
            theme_id:theme_id,
            base_theme:this.state.selected_base_theme,
            shop:this.props.shop
        })
        .then(x=>x.data)
        .then(response => {
            this.setState({ verify_btn_loading: false });

            if(typeof response.error !== "undefined" && response.error){
                this.showToastMessage("Server Error", true);
                return;
            }

            if(response.is_varified === false){
                this.showToastMessage("Token is not verfied", true);
                return;
            }

            this.setState({
                token_verified:response.is_varified,
                token:response.token,
                config_id:response.config_id
            });

        }).catch(error => {
          this.showToastMessage("Error", true);
        });

    }

    copyToken(){
        const elem = document.createElement('textarea');
        elem.value = this.state.token;
        document.body.appendChild(elem);
        elem.select();
        document.execCommand('copy');
        document.body.removeChild(elem);

        this.showToastMessage("Token copied!");
    }
  
    // Varify basic installation and send to next page based on that
    handleNextAction(){

        this.setState({ next_btn_loading: true, selected_base_theme:null });

        var theme_id = this.state.theme_selected_id.replace('wholesale','');

        axios.post('/app/v238/do-installation', {
            theme_id:theme_id,
            shop:this.props.shop
        })
        .then(x=>x.data)
        .then(response => {
            this.setState({ next_btn_loading: false });

            if(typeof response.error !== "undefined" && response.error){
                this.showToastMessage(response.error, true);
                return;
            }
            
            // theme is already configured, auto installed or already configured
            if(response.newly_configured === true){
                // Show toast
                this.showToastMessage("Theme is configured");
            }

            this.setState({
                token_verified: response.is_verified,
                token:response.token,
                config_id:response.config_id,
                selected_base_theme:response.base_theme,
                theme_compatible:response.is_compatible,
            });


        }).catch(error => {
            this.showToastMessage("Error", true);
        });

    }

    /* MAIN lifecyle */
    render() {

        var toast = this.state.showToast? (
        <ToastMessage
            onDismiss={this.dissmissToast}
            message={this.state.toastMsg}
            error={this.state.toastError}
        />
        ): (null);

        let options = [];

        if (this.state.items.length > 0) {
            options = this.state.items.map((theme, i) => {
                return ({
                label: theme.role === 'main' ? theme.name + ' [Current live theme]' : theme.name,
                value: theme.id + 'wholesale',
                id: theme.id
                });
            });
        }

        var removal_theme_selected_id = options.length>0 ? options.findIndex(x=>x.value===this.state.removal_theme_selected_id) : -1;
        var removal_selected_theme_name = '';
        if(removal_theme_selected_id>-1)removal_selected_theme_name = options[removal_theme_selected_id].label;

        var selected_theme_index = options.length>0 ? options.findIndex(x=>x.value===this.state.theme_selected_id) : -1;
        var selected_theme_name = '';
        if(selected_theme_index>-1)selected_theme_name = options[selected_theme_index].label;

        if(this.state.page_loaded === false){
            return(
                <div>
                <PageTitleBar
                    title="Theme Configuration"
                    />
                <SkeletonPageComp />
                </div>
            );
        }
        else{

            return (
                <div>
                    {toast}
                    <PageTitleBar
                        title="Theme Configuration"
                    />
                    <Layout>
                    <Layout.Section>
                        
                        <Card sectioned title="App Code Installation">

                            <Stack vertical spacing="loose">

                                <Select
                                    placeholder="-- Please Select --"
                                    label="Choose Theme to Install App Code"
                                    options={options}
                                    onChange={(value) => {
                                        this.setState({
                                            theme_selected_id:value,
                                            selected_base_theme:null
                                        });
                                    }}
                                    value={this.state.theme_selected_id}
                                ></Select>

{!this.state.selected_base_theme?
                                    <Button
                                        onClick={() => {
                                            this.handleNextAction();
                                        }}
                                        primary
                                        slim
                                        disabled={!this.state.theme_selected_id}
                                        icon={ChevronRightMinor}
                                        loading={this.state.next_btn_loading}
                                    >
                                        App Installation
                                    </Button>
                                :this.state.theme_selected_id!==''?
                                <>

                                    <Stack vertical spacing="tight">

                                        {selected_theme_name?
                                            <Stack spacing="loose">
                                                <span>Selected Theme Name: </span>
                                                <span>{selected_theme_name}</span>
                                            </Stack>
                                        :null}

                                        {this.state.selected_base_theme?
                                            <Stack spacing="loose">
                                                <span>Base Theme Name:</span>
                                                <span>{this.state.selected_base_theme}</span>
                                            </Stack>
                                        :null}

                                        {this.state.config_id !== null ?
                                            <Stack vertical spacing="baseTight">

                                                {!this.state.token_verified && this.state.token!==''?
                                                <Banner
                                                    title="Action Required - (Access Token not found)"
                                                    status="warning"
                                                    
                                                >
                                                    <Stack vertical spacing="tight">
                                                        <Stack spacing="tight" alignment="center">
                                                            <span>Step #1: Copy Access Token: </span>
                                                            <Badge status="attention">
                                                                {this.state.token}
                                                            </Badge> 
                                                            <Button 
                                                                size="slim"
                                                                outline={true} 
                                                                icon={ClipboardMinor} 
                                                                onClick={()=>{ this.copyToken(); }}
                                                            ></Button>
                                                        </Stack>
                                                        <p>Step #2: Follow <Link external url="https://support.digitalcoo.com/hc/en-us/articles/4414475701140">this article</Link> to add the access token to the wholesale app.</p>
                                                        <p>Step #3: Press the 'Verify access token' button'.</p>
                                                        
                                                        
                                                    </Stack>
                                                </Banner>
                                                :null}

                                                <Stack spacing="tight">
                                                    {this.state.token_verified?
                                                    <Button
                                                        slim
                                                        url={BUILD_VERSION_EXTENSION+'/theme-installations/edit/'+this.state.config_id}
                                                        target="APP"
                                                    >
                                                        Edit App Installation
                                                    </Button>
                                                    :
                                                    <Button
                                                        onClick={() => {
                                                            this.verifyInstallation();
                                                        }}
                                                        primary
                                                        slim
                                                        loading={this.state.verify_btn_loading}
                                                    >
                                                        Verify Access Token
                                                    </Button>
                                                    }
                                                </Stack>

                                            </Stack>
                                        :
                                            <Stack vertical spacing="tight">
                                                <div>
                                                    {!this.state.theme_compatible?
                                                    <Banner
                                                        title="Theme is not compatible"
                                                        status="warning"
                                                    >
                                                        <p>
                                                            In order to use the Wholesale All In One app, please <Link external={true} url="https://support.digitalcoo.com/hc/en-us/requests/new">submit a request</Link> to integrate the wholesale app to your website. We'll make some necessary adjustments in the theme code to ensure your theme will run smoothly with our app.
                                                            <br />
                                                            Alternatively, <Link target="APP" url={BUILD_VERSION_EXTENSION+'/theme-installations/manual/'+this.state.theme_selected_id.replace('wholesale','')}>Click Here</Link> to manually install.
                                                        </p>
                                                    </Banner>
                                                    :null}
                                                </div>

                                                
                                            </Stack>
                                        }
                                    </Stack>
                                </>
                                :null}

                                
                            </Stack>

                        </Card>
                        
                        {this.state.removal_show?
                        <Card sectioned title="App Code Uninstallation">

                            <Stack vertical spacing="loose">

                                <Select
                                    placeholder="-- Please Select --"
                                    label="Choose Theme to Uninstall App Code"
                                    options={options}
                                    onChange={(value) => {
                                        this.setState({
                                            removal_theme_selected_id:value
                                        });

                                        this.CanAutoRemove(value);
                                    }}
                                    value={this.state.removal_theme_selected_id}
                                ></Select>

                                {!this.state.delete_btn_loading && this.state.removal_config_id==='' && this.state.removal_theme_selected_id && !this.state.show_file_removal_article ? 
                                <Banner
                                    title="Installation not found!"
                                    status="warning"
                                >
                                    <p>The Wholesale All In One app is already uninstalled from the {removal_selected_theme_name} theme.</p>
                                </Banner>
                                
                                :null}

                                {this.state.successfully_deleted ? 
                                <Banner
                                title={"The Wholesale All In One app code has been successfully removed from the '"+this.state.deleted_theme_name+"' theme."}
                                status="success"
                            />
                                
                                :null}

                                {!this.state.delete_btn_loading && this.state.show_file_removal_article ? 
                                <Banner
                                    title="Action Required! [Last Step]"
                                    status="info"
                                >
                                    <p>
                                    <Link external url="https://support.digitalcoo.com/hc/en-us/articles/4404783206420">CLICK HERE</Link> to follow the article to delete the Wholesale All In One app files from the theme. <br />
                                        Alternatively, <Link external url="https://support.digitalcoo.com/hc/en-us/requests/new">Click Here</Link> to submit a request to our team, and we will delete the wholesale app files for you.
                                    </p>
                                </Banner>
                                
                                :null}

                                

                                <Button
                                    onClick={() => {
                                        this.delete_item();
                                    }}
                                    slim
                                    destructive
                                    disabled={!this.state.removal_theme_selected_id || this.state.removal_config_id === ''}
                                    icon={ChevronRightMinor}
                                    loading={this.state.delete_btn_loading}
                                >
                                    Uninstall App Code
                                </Button>
                            </Stack>

                        </Card>
                        :null}

                    </Layout.Section>
                    
                    </Layout>
                </div>
            );
        }
    }

}