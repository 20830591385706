import React, { Component } from 'react';
import {PageActions,Subheading,Banner,Stack,Layout,TextField,Card,Button,List, Badge, Link } from '@shopify/polaris';
import SkeletonPageComp from './SkeletonPageComp';
import PageTitleBar from '../Common/TitleBar';
import ToastMessage from '../Common/ToastMessage';
import Common from '../Helpers/Common';
import LoadingOverlay from './LoadingOverlay';
import {ClipboardMinor} from '@shopify/polaris-icons';
import axios from '../Axios';
const BUILD_VERSION_EXTENSION =  process.env.REACT_APP_BUILD_VERSION_EXTENSION;

var _this = null;
export default class CreateInstallationConfiguration extends Component {
    constructor(props) {
        super(props);
        this.state = {

            // Verification page
            token_verified:false,
            token:'',
            verified_on_start:false, // Will check for verification on page load

            /* Will have base theme of seleted option */
            selected_base_theme:null,
            selected_theme_theme:null,

            /* Installed theme count (Max 5 is allowed) */
            theme_count:0,

            /* Will be used on edit page */
            single_theme:null,
            
            static_metafields_selectors:'', // will used to detect edits
            static_metafields_html:'',// will used to detect edits
            static_metafields_customcode:'',// will used to detect edits

            metafields_selectors:'',
            metafields_html:'',
            metafields_customcode:'',
            
            error_metafields_selectors:'',
            error_metafields_html:'',

            code_metafields_selectors:'',
            code_metafields_html:'',


            updaing:false, 
            delete_btn_loading:false,
            verify_btn_loading:false,
            verify_trigger_from_button:false, // When verify token button is pressed

            /* Will have selected theme id from the list */
            theme_selected_id:'',

            /* If ajax has some errors, will render the array */
            errorList: [],

            /* Loading state for save action */
            save_btn_loading: false,
            /* if selected theme is compatible or not */
            theme_compatible: true,
            
            /* Skeleton page until data loaded */
            page_loaded: false,

            /* Toast specefic */
            showToast: false,
            toastError: false,
            toastMsg: ""
        }
        axios.defaults.headers.common['X-Ws-Shop-Domain'] = "https://"+this.props.shop;
    }

    /* Lifecycle: Begin component */
    componentDidMount() {
        _this = this;

        if(this.props.show === "manual"){

            document.addEventListener('keydown',this.keydownHandler);

            this.setState({ theme_selected_id: this.props.match.params.id });
            this.getSingleTheme(this.props.match.params.id);

        }
        else if(this.props.show === "edit"){
            this.getForEdit();
            document.addEventListener('keydown',this.keydownHandler);
            
        }
    }

    /* Lifecycle: End componenet */
    componentWillUnmount(){
        document.removeEventListener('keydown',this.keydownHandler);
    }

    /* handling ctrl+s for convenience */
    keydownHandler(event){
        if (Common.getPlanID() !== 1 && ((event.key === "s"||event.key === "S") || event.keyCode === 83) && (event.metaKey || event.ctrlKey)) {
            event.preventDefault();
            try {
                var allow_edit = _this.detectEdit();
                if(!(_this.state.updating || !allow_edit || _this.state.error_metafields_html !== '' || _this.state.error_metafields_selectors !== ''))_this.update();
            } catch (error) {}
        }
    }

    /* Get data of single theme on edit page */
    getForEdit(){
        axios.get('/app/v238/get-theme?shop='+this.props.shop+'&id=' + this.props.match.params.id)
        .then(x=>x.data)
        .then(response => {
            if(typeof response.status !== "undefined" && response.status === 1){

                var code_metafields_selectors='';
                var code_metafields_html='';
                
                var metafields_selectors = '';
                if(response.theme.metafileds && response.theme.metafileds.selectors && response.theme.metafileds.selectors.value){
                    metafields_selectors = response.theme.metafileds.selectors.value;

                    // Parse json to code
                    code_metafields_selectors = this.parseMetafield("selectors", JSON.parse(response.theme.metafileds.selectors.value), "code");
                }
                
                var metafields_html = '';
                if(response.theme.metafileds && response.theme.metafileds.html && response.theme.metafileds.html.value){
                    metafields_html = response.theme.metafileds.html.value;

                    // Parse json to code
                    code_metafields_html = this.parseMetafield("html", JSON.parse(response.theme.metafileds.html.value), "code");
                };
                
                var metafields_customcode = '';
                if(response.theme.metafileds && response.theme.metafileds.custom_code && response.theme.metafileds.custom_code.value)metafields_customcode = response.theme.metafileds.custom_code.value;

                

                
                this.setState({
                    page_loaded:true,
                    single_theme:response.theme,

                    metafields_selectors:JSON.stringify(JSON.parse(metafields_selectors), null, 2),
                    metafields_html:JSON.stringify(JSON.parse(metafields_html), null, 2),
                    metafields_customcode:metafields_customcode,

                    static_metafields_selectors:JSON.stringify(JSON.parse(metafields_selectors), null, 2),
                    static_metafields_html:JSON.stringify(JSON.parse(metafields_html), null, 2),
                    static_metafields_customcode:metafields_customcode,

                    code_metafields_selectors:code_metafields_selectors,
                    code_metafields_html:code_metafields_html


                });
            }
            else{
                this.setState({
                    page_loaded:true
                });
                
                this.showToastMessage("Could not load data. Try again", true);
                /* Redirect to list page */
                this.props.linkOverride.push(BUILD_VERSION_EXTENSION+'/theme-installations/list');
            }
        })
        .catch(error => {
            this.setState({page_loaded: true});
        });
    }

    /* Get data of single theme on create page */
    getSingleTheme(theme_id){
        axios.get('/app/v238/get-single-theme?shop='+this.props.shop+'&theme_id=' + theme_id)
        .then(x=>x.data)
        .then(response => {
            if(typeof response.status !== "undefined" && response.status === 1){
                
                this.setState({
                    theme_compatible: true,
                    selected_base_theme:response.base_theme,
                    selected_theme_theme:response.theme_merchant_name,
                    page_loaded: true,
                    theme_count:response.theme_count
                });
            }
            else{
                // Theme not compatible
                this.setState({
                    theme_compatible: false,
                    selected_base_theme:response.base_theme,
                    selected_theme_theme:response.theme_merchant_name,
                    page_loaded: true,
                    theme_count:response.theme_count
                });
            }
            
        })
        .catch(error => {
            this.setState({
                theme_compatible: false,
                page_loaded: true
            });
        });
    }

    /* this will detect if anything changes on page */

    /**
    | const_data: constant data, that should not be change
    | compare_data: data to compare, is not match, data is changed
    */
    _comparechange(const_data, compare_data){

        /* Function that will compare both objects */
        let deepCompare = () => {
            var i, l, leftChain, rightChain;

            function compare2Objects(x, y) {
                var p;

                // remember that NaN === NaN returns false
                // and isNaN(undefined) returns true
                if (isNaN(x) && isNaN(y) && typeof x === 'number' && typeof y === 'number') {
                    return true;
                }

                // Compare primitives and functions.     
                // Check if both arguments link to the same object.
                // Especially useful on the step where we compare prototypes
                if (x === y) {
                    return true;
                }

                // Works in case when functions are created in constructor.
                // Comparing dates is a common scenario. Another built-ins?
                // We can even handle functions passed across iframes
                if ((typeof x === 'function' && typeof y === 'function') ||
                    (x instanceof Date && y instanceof Date) ||
                    (x instanceof RegExp && y instanceof RegExp) ||
                    (x instanceof String && y instanceof String) ||
                    (x instanceof Number && y instanceof Number)) {
                    return x.toString() === y.toString();
                }

                // At last checking prototypes as good as we can
                if (!(x instanceof Object && y instanceof Object)) {
                    return false;
                }

                if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) {
                    return false;
                }

                if (x.constructor !== y.constructor) {
                    return false;
                }

                if (x.prototype !== y.prototype) {
                    return false;
                }

                // Check for infinitive linking loops
                if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
                    return false;
                }

                // Quick checking of one object being a subset of another.
                // todo: cache the structure of arguments[0] for performance
                for (p in y) {
                    if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
                        return false;
                    } else if (typeof y[p] !== typeof x[p]) {
                        return false;
                    }
                }

                for (p in x) {
                    if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
                        return false;
                    } else if (typeof y[p] !== typeof x[p]) {
                        return false;
                    }

                    switch (typeof (x[p])) {
                        case 'object':
                        case 'function':

                            leftChain.push(x);
                            rightChain.push(y);

                            if (!compare2Objects(x[p], y[p])) {
                                return false;
                            }

                            leftChain.pop();
                            rightChain.pop();
                            break;

                        default:
                            if (x[p] !== y[p]) {
                                return false;
                            }
                            break;
                    }
                }

                return true;
            }

            if (arguments.length <= 1) {
                return true; //Die silently? Don't know how to handle such case, please help...    
            }

            for (i = 1, l = arguments.length; i < l; i++) {

                leftChain = []; //Todo: this can be cached
                rightChain = [];

                if (!compare2Objects(arguments[0], arguments[i])) {
                    return false;
                }
            }

            return true;
        }

        return !deepCompare(const_data, compare_data);
    }

    /* WIll detect edit and enable save button */
    detectEdit(){
        // Match objects

        var mfSelectors = '';
        var mfHtml = '';
        var mfCustomcode = '';

        try { mfSelectors = JSON.parse(this.state.static_metafields_selectors);} catch (e) { mfSelectors = ''; }
        try { mfHtml = JSON.parse(this.state.static_metafields_html);} catch (e) { mfHtml = ''; }
        try { mfCustomcode = this.state.static_metafields_customcode;} catch (e) { mfCustomcode = ''; }

        var staticData={
            selectors:mfSelectors,
            html:mfHtml,
            customcode:mfCustomcode,
        };

        try { mfSelectors = JSON.parse(this.state.metafields_selectors);} catch (e) { mfSelectors = ''; }
        try { mfHtml = JSON.parse(this.state.metafields_html);} catch (e) { mfHtml = ''; }
        try { mfCustomcode = this.state.metafields_customcode;} catch (e) { mfCustomcode = ''; }

        
        var dynamicData={
            selectors:mfSelectors,
            html:mfHtml,
            customcode:mfCustomcode,
        };

        // Match them
        if(this._comparechange(staticData, dynamicData)){
            return true;
        }
        else{
            return false;
        }

    }

    /* Show/Hide toast */
    showToastMessage(msg, error){
        this.setState({
          showToast: true,
          toastMsg: msg,
          toastError: error||false,
        });
    }

    /* After save, scroll to top */
    scrollToTop(){
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    /* Verify current installation */
    verifyInstallation(){

        this.setState({ verify_btn_loading: true, verified_on_start:true });

        var theme_id = this.state.theme_selected_id;

        axios.post('/app/v238/verify-installation', {
            theme_id:theme_id,
            base_theme:this.state.selected_base_theme,
            shop:this.props.shop
        })
        .then(x=>x.data)
        .then(response => {
            this.setState({ verify_btn_loading: false });

            if(typeof response.error !== "undefined" && response.error){
                return;
            }

            if(this.state.verify_trigger_from_button){

                if(response.is_varified === false){
                    this.showToastMessage("Access token not found", true);
                }
                else{
                    this.showToastMessage("Access token verified");
                }


            }

            this.setState({
                token_verified:response.is_varified,
                errorList:[],
                verify_trigger_from_button:false,
                token:response.token
            });

        }).catch(error => {
          this.showToastMessage("Error", true);
        });

    }

    /* Update the config */
    update(){

        if(this.validate()){
            /* Send update request */
            this.setState({ updating: true });

            var state = this.props.show;
            var url = '/app/v238/update-installation';
            if(state === "manual"){
                url = '/app/v238/manual-installation';
            }

            axios.post(url, {
                id:this.props.match.params.id,
                shop:this.props.shop,
                selectors:this.state.metafields_selectors,
                html:this.state.metafields_html,
                custom_code:this.state.metafields_customcode
            })
            .then(x=>x.data)
            .then(response => {
                this.setState({ updating: false });

                if(typeof response.error !== "undefined" && response.error){
                    this.showToastMessage("Server Error", true);
                    return;
                }
                
                if(state==="manual"){
                    this.showToastMessage("Configuration saved!");

                    var config_id = null;
                    if(typeof response.theme !== "undefined" && response.theme)config_id=response.theme._id;

                    // redirect to edit page
                    if(config_id)this.props.linkOverride.push(BUILD_VERSION_EXTENSION+'/theme-installations/edit/'+config_id);
                    else this.props.linkOverride.push(BUILD_VERSION_EXTENSION+'/theme-installations/list');
                    
                }
                else{
                    this.showToastMessage("Configuration updated!");

                    // Reload theme
                    this.getForEdit();
                }

            }).catch(error => {
                this.setState({ updating: false });

                this.showToastMessage("Error", true);
            });
        }

    }

    /* Delete item */
    delete_item(){
        var base_theme = this.state.single_theme ? this.state.single_theme.theme_name : "";
        if(window.confirm(`Press 'OK' to continue to remove Wholesale All In One app integration from all '${base_theme.toUpperCase()}' themes.`)){

            this.setState({delete_btn_loading:true});

            var url = '/app/v238/delete-theme-installation?shop='+this.props.shop+'&id='+this.props.match.params.id;
            axios.delete(url)
            .then(x=>x.data)
            .then( response => {
                if(response.status === 1){
                    this.showToastMessage("Configuration deleted successfully!");
                    this.getForEdit();
                }
                else{
                    this.showToastMessage("Server Error!", true);
                }
                this.setState({delete_btn_loading:false});
            }).catch(err => {
                this.showToastMessage("Server Error", true);
                this.setState({delete_btn_loading:false});
            });
        }
    }

    validate(){
        var is_valid = true;
        var errors=[];

        /* Selector metafields should be JSON */
        function IsJsonString(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        }

        var selectors = this.state.metafields_selectors;
        if(selectors.trim()===''){
            is_valid = false;
            errors.push("Selectors Metafield cannot be empty.");
        }

        if(!IsJsonString(selectors)){
            is_valid = false;
            errors.push("Selectors Metafield must be valid JSON.");
        }

        var html = this.state.metafields_html;
        if(html.trim()===''){
            is_valid = false;
            errors.push("HTML Metafield cannot be empty.");
        }

        if(!IsJsonString(html)){
            is_valid = false;
            errors.push("HTML Metafield must be valid JSON.");
        }

        /* If manual page, token need to verified too */
        if(this.props.show === "manual" && !this.state.token_verified){
            is_valid = false;
            errors.push("Access token needs to be verified.");
        }

        if(!is_valid){
            let msg = `There ${errors.length > 1?"are":"is"} ${errors.length } ${errors.length > 1?"errors":"error"}`;
            this.showToastMessage(msg, true);
            this.setState({ errorList : errors });
            this.scrollToTop();
        }
        else{
            this.setState({ errorList : [] });
        }

        return is_valid;

    }

    copyToken(){
        const elem = document.createElement('textarea');
        elem.value = this.state.token;
        document.body.appendChild(elem);
        elem.select();
        document.execCommand('copy');
        document.body.removeChild(elem);

        this.showToastMessage("Token copied!");
    }


    /* Will get the javascript text, convert in into JSON */
    parseMetafield(kind, payload, to="json"){
        if(to==="json"){
            // Parse into JSON

            var is_valid = true;

            if(kind === "selectors"){
                var SELECTORS = {};
                var SELECTOR_JSON = "";

                try{

                    const evalStr = `(function(){"use strict";${payload}})();`;

                    // eslint-disable-next-line
                    eval(evalStr);

                    SELECTOR_JSON = JSON.stringify(SELECTORS);

                    this.setState({
                        error_metafields_selectors:""
                    });

                }catch(e){
                    is_valid = false;
                    this.setState({
                        error_metafields_selectors:e.message
                    });
                }

                this.setState({
                    metafields_selectors:SELECTOR_JSON
                });
    
            }
            else if(kind === "html"){
                var HTML = {};
                var HTML_JSON = "";

                try{

                    const evalStr = `(function(){"use strict";${payload}})();`;
                    // eslint-disable-next-line
                    eval(evalStr);

                    HTML_JSON = JSON.stringify(HTML);

                    this.setState({
                        error_metafields_html:""
                    });

                }catch(e){
                    is_valid = false;
                    this.setState({
                        error_metafields_html:e.message
                    });
                }

                this.setState({
                    metafields_html:HTML_JSON
                });
    
            }

            return is_valid;
        }
        else{
            // Parse into CODE

            var GLOBAL_VAR='';
            // var state_selector = '';

            /* Setting based on input */
            if(kind === "selectors"){
                GLOBAL_VAR='SELECTORS';
                // state_selector='code_metafields_selectors';
            }
            else if(kind === "html"){
                GLOBAL_VAR='HTML';
                // state_selector='code_metafields_html';
            }

            /* COnverting object to source code */
            if(GLOBAL_VAR!==''){
                var code=[];
                Object.keys(payload).forEach(function(key){
                    var value = payload[key];

                    /* If value is string, add single qoute ('') so it can convert to string object */
                    if(typeof value === "string"){
                        value = `'${value.replace(/(\r\n|\n|\r)/gm, "")}'`;
                    }
                    else if(typeof value === "object"){
                        try{
                            value = JSON.stringify(value, null, "\t");
                        } catch(e){
                            value = `'${e.message}'`; // Show error instead of value
                        }
                    }
                    code.push(`${GLOBAL_VAR}.${key}=${value}`);
                });
                
            }

            /* Updating state so code can apply to textareas */
            return code.join(";\n");
        }
    }

    /* Lifecycle: Main Event */
    render() {

        var {single_theme} = this.state;

        var allow_edit = this.detectEdit();

        var is_edit = this.props.show === "edit";
        var manual_creating = this.props.show === "manual";



        /* Enable edit mode on manual mode */
        if(manual_creating){
            is_edit=true;

            if(this.state.page_loaded && !this.state.verified_on_start)this.verifyInstallation();
        }




        var PageSecondaryActions = [];
        is_edit && !manual_creating && (PageSecondaryActions.push({
            content: 'Remove app installation',
            destructive: true,
            loading:this.state.delete_btn_loading,
            onAction:()=>{
                this.delete_item();
            }
        }));
        PageSecondaryActions.push({ content: 'Cancel', url: BUILD_VERSION_EXTENSION+'/theme-installations/list', target: "APP" });

        /* Page Actions to show on top & bottom */
        let page_actions = <PageActions
            secondaryActions={PageSecondaryActions}   
            primaryAction = {is_edit && {
                disabled: this.state.updating || !allow_edit || this.state.error_metafields_html !== '' || this.state.error_metafields_selectors !== '',
                content: "Save Installation",
                onAction: () => { this.update() },
                loading:this.state.updating,
                icon: false
            }}
            
        />;

        /* If we have errors to display */
        let errors_section = <div>
            <Banner status="critical" title={`There ${this.state.errorList.length > 1?"are":"is"} ${this.state.errorList.length } ${this.state.errorList.length > 1?"errors":"error"}`}>
                <List type="number">
                {
                    this.state.errorList.map((error,iN) => {
                        return <List.Item key={iN}>{error}</List.Item>
                    })
                }
                </List>
            </Banner>
        </div>

        /* Main sections to be rendered */
        let renderSections;
        if(is_edit){

            var configType = '';
            if(single_theme){
                switch (single_theme.config_type) {
                    case "auto":
                        configType = 'Auto';
                        break;
                    case "manual":
                        configType = 'Manual';
                        break;
                    case "auto_manual":
                        configType = 'Auto|Edited';
                        break;
                
                    default:
                        break;
                }
            }

            renderSections = <Layout>
                { this.state.errorList.length > 0 && <Layout.Section>{errors_section}</Layout.Section> }
                {manual_creating && !this.state.token_verified && this.state.token!==''?
                <Layout.Section>
                    <Banner
                        title="Action Required - (Access Token not found)"
                        status="warning"
                        action={{
                            content: 'Verify Access Token', 
                            loading:this.state.verify_btn_loading,
                            disabled:this.state.verify_btn_loading,
                            onAction: ()=>{
                                this.setState({
                                    verify_trigger_from_button:true
                                });
                                this.verifyInstallation();
                            } 
                        }}
                    >
                        <Stack vertical spacing="tight">
                            <Stack spacing="tight" alignment="center">
                                <span>Step #1: Copy Access Token: </span>
                                <Badge status="attention">
                                    {this.state.token}
                                </Badge> 
                                <Button 
                                    size="slim"
                                    outline={true} 
                                    icon={ClipboardMinor} 
                                    onClick={()=>{ this.copyToken(); }}
                                ></Button>
                            </Stack>
                            <p>Step #2: Follow <Link external url="https://support.digitalcoo.com/hc/en-us/articles/4414475701140">this article</Link> to add the access token to the wholesale app.</p>
                            <p>Step #3: Press the 'Verify access token' button'.</p>
                            
                        </Stack>
                    </Banner>
                </Layout.Section>
                :null}
                <Layout.Section>
                    <Card title={manual_creating?"Manual App Installation":"Edit App Installation"} actions={is_edit && !manual_creating?{content: 'Remove app installation', destructive: true, loading:this.state.delete_btn_loading, onAction:()=>{ this.delete_item(); } }:null}>
                        {!manual_creating?
                        <Card.Section>
                            <Stack vertical spacing="tight">
                                {single_theme&&single_theme.config_type === "manual"&&single_theme.theme_name === "Manual"?null:
                                <Stack spacing="loose">
                                    <Subheading>Base theme name: </Subheading>
                                    <Badge>{single_theme&&single_theme.theme_name}</Badge>
                                </Stack>
                                }
                                <Stack spacing="loose">
                                    <Subheading>Configuration Type</Subheading>
                                    <Badge status="info">{configType}</Badge>
                                </Stack>
                            </Stack>
                            </Card.Section>
                        :
                        <Card.Section>
                            <Stack vertical spacing="tight">
                                {this.state.selected_theme_theme?
                                    <Stack spacing="loose">
                                        <span>Selected Theme Name: </span>
                                        <span>{this.state.selected_theme_theme}</span>
                                    </Stack>
                                :null}

                                {this.state.selected_base_theme?
                                    <Stack spacing="loose">
                                        <span>Base Theme Name:</span>
                                        <span>{this.state.selected_base_theme}</span>
                                    </Stack>
                                :null}
                            </Stack>
                        </Card.Section>
                        }

                        <Card.Section title="Metafields" >
                            <Card.Subsection>
                                <TextField
                                    label="Selectors"
                                    monospaced
                                    spellCheck={false}
                                    value={this.state.code_metafields_selectors}
                                    error={this.state.error_metafields_selectors}
                                    onChange={(v)=>{
                                        this.parseMetafield("selectors", v, "json")
                                        
                                        this.setState({
                                            code_metafields_selectors:v
                                        });
                                        
                                    }}
                                    multiline={4}
                                    labelAction={manual_creating?{content: 'Documentation', url:"https://support.digitalcoo.com/hc/en-us/articles/4414482317588", external:true}:{}}
                                    autoComplete="off"
                                />
                            </Card.Subsection>
                            <Card.Subsection>
                                <TextField
                                    label="HTML"
                                    monospaced
                                    spellCheck={false}
                                    value={this.state.code_metafields_html}
                                    error={this.state.error_metafields_html}
                                    onChange={(v)=>{
                                        this.parseMetafield("html", v, "json")
                                        
                                        this.setState({
                                            code_metafields_html:v
                                        });                                        
                                        
                                    }}
                                    labelAction={manual_creating?{content: 'Documentation', url:"https://support.digitalcoo.com/hc/en-us/articles/4414482317588", external:true}:{}}
                                    multiline={4}
                                    autoComplete="off"
                                />
                            </Card.Subsection>
                            <Card.Subsection>
                                <TextField
                                    label="Custom Code"
                                    monospaced
                                    value={this.state.metafields_customcode}
                                    onChange={(v)=>{
                                        this.setState({
                                            metafields_customcode:v
                                        });
                                    }}
                                    labelAction={manual_creating?{content: 'Documentation', url:"https://support.digitalcoo.com/hc/en-us/articles/4414482317588", external:true}:{}}
                                    multiline={4}
                                    autoComplete="off"
                                />
                            </Card.Subsection>
                        </Card.Section>


                    </Card>
                </Layout.Section>
                <Layout.Section>{page_actions}</Layout.Section>
            </Layout>;

        }                
        
        /* Will show toast based on state */
        var toast = this.state.showToast? (<ToastMessage message={this.state.toastMsg} error={this.state.toastError} onDismiss={()=>{this.setState({showToast: false})}} />):(null);
        
        /* Return Method: */
        if(this.state.page_loaded){
            return(
                <div>
                    <PageTitleBar
                        title={this.props.show === "edit"?"Edit App Installation":"App Code Installation"}
                        secondaryActions={[
                            { content: 'Back', url: BUILD_VERSION_EXTENSION+'/theme-installations/list', target: 'APP' }
                        ]}
                        primaryAction={is_edit&&{
                            disabled: this.state.updating || !allow_edit || this.state.error_metafields_html !== '' || this.state.error_metafields_selectors !== '',
                            content: "Save Installation",
                            icon: false,
                            onAction: () => {this.update()}
                        }}
                    />
                    {renderSections}
                    {toast}
                    {
                        this.state.updating === true && <div>
                            <LoadingOverlay
                                message="Saving Installation. Please wait..."
                            />
                        </div>
                    }
                </div>
            )
        }
        else{
            return(
                <SkeletonPageComp />
            )
        }
    }

}
