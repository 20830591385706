import React, { Component } from 'react';
import {  Layout, Button, Banner, Card, TextStyle } from '@shopify/polaris';
import store from 'store2';
import PageTitleBar from '../Common/TitleBar';
const BUILD_VERSION_EXTENSION =  process.env.REACT_APP_BUILD_VERSION_EXTENSION;

var owner_email = store("owner_email") || null;

export default class AccountSetupInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shopData: this.props.shopData || {},
            install_status: this.props.shopData?this.props.shopData.install_status:"in_development",
        }
    }

    render() {

        const page1 = <div>
            <Layout>
                <Layout.Section>
                        <div className="float-right">
                            <Button
                                primary
                                url={BUILD_VERSION_EXTENSION+'/dashboard'}
                            > Dashboard </Button>
                        </div>
                        <div className="clearfix"></div>

                        <div style={{margin:"2rem 0"}}>
                            <Banner status="info">
                            To finalize the installation of the Wholesale All In One app, please add us as a staff member with the necessary permissions listed below. This will allow us to seamlessly integrate the app into your website and ensure its proper functionality.<br/>
                            <b>Note:</b> If you prefer to provide dashboard access via a collaborator account rather than a staff account, kindly send your store URL to support@digitalcoo.com Our team will then initiate a request for a collaborator account.

                            </Banner>
                        </div>

                        <Card sectioned>
                            <b>Step 1:</b> On your Shopify admin page, click <TextStyle variation="strong">"Settings"</TextStyle> -> <TextStyle variation="strong">"Users and permissions"</TextStyle>
                        </Card>

                        <Card sectioned>
                            <div style={{margin: "2rem 0rem"}}>
                            <b>Step 2:</b> On the Staff section, click <TextStyle variation="strong">"Add staff"</TextStyle>.
                            </div>
                            <div>
                                <img src="/images/add-staff-account-new.png" alt="add-staff-account" width="100%" style={{ maxWidth: '100%', width: 'auto' }} />
                            </div>
                        </Card>
                        
                        <Card sectioned>
                            <b>Step 3:</b> To add us :
                            <ul>
                                <li>
                                    Enter <TextStyle variation="strong">Wholesale</TextStyle> as first name, <TextStyle variation="strong">Application</TextStyle> as last name and <TextStyle variation="strong">support@digitalcoo.com</TextStyle> as an email address.
                                </li>
                            </ul>
                            <div>
                                <img src="/images/enter-name-email-new.png" alt="unchecked-option" width="100%" style={{ maxWidth: '100%', width: 'auto' }}/>
                            </div>
                        </Card>

                        {typeof this.props.customerAccountsDisabled !== "undefined" && this.props.customerAccountsDisabled === true ?

                            <Card sectioned actions={[{content: "Learn more", external:true, url:"https://support.digitalcoo.com/hc/en-us/articles/360044981891"}]}>
                            <b>Step 4:</b> We required the following permissions to integrate the Wholesale All In One application into your website:
                            <div>
                                <ol>
                                    <li>Products</li>
                                    <li>Themes</li>
                                    <li>Blog posts and pages</li>
                                    <li>Manage and install apps and channels</li>
                                    <li>Manage settings</li>
                                </ol>
                            </div>
                            <div>
                                <img src="/images/user-permissions-with-settings.png" alt="set-permissions" width="100%" style={{ maxWidth: '100%', width: 'auto' }} />
                            </div>
                            </Card>
                        
                        : <Card sectioned actions={[{content: "Learn more", external:true, url:"https://support.digitalcoo.com/hc/en-us/articles/360044981891"}]}>
                            <b>Step 4:</b> We required the following permissions to integrate the Wholesale All In One application into your website:
                            <div>
                                <ol>
                                    <li>View Products</li>
                                    <li>Themes</li>
                                    <li>Themes > Edit Code</li>
                                    <li>Blog posts and pages</li>
                                    <li>Manage and install apps and channels</li>
                                </ol>
                            </div>
                            <div>
                                <img src="/images/user-permissions-new.png" alt="set-permissions" width="100%" style={{ maxWidth: '100%', width: 'auto' }} />
                            </div>
                        </Card>
                        }

                        <Card sectioned>
                            <div>
                                <b>Step 5:</b> Click "Send Invite"
                                <div>
                                    - Yay! You're done - please hold tight!
                                </div>
                                <div>
                                    - Once we complete your theme edit for your store we'll send you a notification email to <TextStyle variation="strong"> {owner_email?owner_email: "admin@storeowner.com"} </TextStyle>
                                </div>
                                <div>
                                    - Do you want to receive notification on different email address? Please write us an email to support@digitalcoo.com
                                </div>
                            </div>
                        </Card>
                </Layout.Section>
            </Layout>
        </div>;
        return (
            <div>
                <PageTitleBar
                    title="Create staff account"
                    breadcrumbs={[
                        { content: 'Dashboard', url: BUILD_VERSION_EXTENSION+'/dashboard' }
                    ]}
                />
                {page1}
            </div>
        );
    }
}