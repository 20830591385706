import React, { Component } from 'react';
import axios from '../Axios';
import * as PropTypes from 'prop-types';
import queryString from 'qs';
import { FooterHelp, Link, Spinner, Banner } from '@shopify/polaris';
import SkeletonPageComp from '../Components/SkeletonPageComp';
import PageTitleBar from '../Common/TitleBar';

const parsed = queryString.parse(window.location.search);
export default class PricingPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pricing_plans: [],
      host_name: this.props.host_name,
      shop: this.props.shop,
      shop_id: this.props.shop_id,
      charge_id: parsed.charge_id,
      user_current_plan: '',
      active_plan: null,
      shop_payment: this.props.shop_payment,
      plan_btn_disable: false,
      page_load: false,
      button_was_clicked : false
    }
  }
  static contextTypes = {
    easdk: PropTypes.object,
  };
  componentDidMount() {
    if (parsed.charge_id && parsed.plan) {
      axios.get('/admin/recuring-charge/callback/' + parsed.charge_id + '/' + this.props.shop + '/' + this.props.shop_id + '/' + parsed.plan)
        .then(response => {
          window.top.location.href = "https://"+this.props.shop+"/admin/apps/"+process.env.REACT_APP_APPLICATION_HANDLE+"/pricing-plans";
        })
        .catch(error => {
          
        })
    }
    axios.get('/admin/get-plans/' + this.props.shop)
      .then(plans => {
        if (plans.data.length > 0) {
          this.setState({
            pricing_plans: plans.data,
            page_load: true
          });
        }
        else{
          this.setState({
            page_load: true
          });
        }
      })
      .catch(error => {
        this.setState({
          page_load: true
        });
      });

    axios.get('/admin/get-payments/' + this.props.shop)
      .then(response => {
        if (response.data.status === "Pending") {
          // this.context.easdk.showFlashNotice("You did not subsctibe any plan.");
        }
        else if (response.data.status === "active") {
          this.setState({
            user_current_plan: response.data.plan_id
          });
        }

        
        this.setState({
          active_plan: response.data
        });

      })
      .catch(error => {
        
      });
  }

  UI_Included(included) {
    let inc = included.split(',');
    let res = '';
    if (inc.length > 0) {
      res = inc.map((el, i) => {
        return <li className="list-included" key={i}>{el} </li>
      });
    }
    return res;
  };
  UI_Not_included(not_included) {
    let not_inc = not_included.split(',');
    let res = '';
    if (not_inc.length > 0) {
      res = not_inc.map((el, i) => {
        return <li className="list-not-included" key={i}>{el} </li>
      });
    }
    return res;
  };
  subscribePlan(plan) {
    this.setState({ plan_btn_disable: true, button_was_clicked: true });
    let data = {
      _id: plan._id,
      shop: plan.shop,
      price: plan.price,
      trial_days: plan.trial_days,
      plan_name: plan.plan_name
    };
    axios.post('/payment/generate-invoice', data)
    .then(response => {
      window.top.location.href = response.data.confirmation_url;
    })
    .catch(error => {
      this.setState({button_was_clicked: false, plan_btn_disable: false})
    });
  }
  render() {
    let PricingUI = '';
    if(this.state.button_was_clicked){
      return (
        <div>
          <div className="spinner-container" style={{textAlign:"center", height:"50vh", padding: "2rem 0rem"}}>
            <span style={{marginBottom: "2rem"}}><Spinner color="teal" size="large" /></span>
            <h1>Generating Invoice. Please wait...</h1>
          </div>
        </div>
      )
    }
    else if(this.state.page_load){
      if (this.state.pricing_plans.length > 0) {

                // Check if active plan exists

                var active_plan_exists = true;
                if(this.state.active_plan){
                  let pricingPlanId = this.state.active_plan.plan_id;
                  if(pricingPlanId){
                    active_plan_exists = this.state.pricing_plans.some(x=>x.plan_id === pricingPlanId);
                  }
                }

        PricingUI = this.state.pricing_plans.map((pp, i) => {
          return <div key={i} className={this.state.user_current_plan.toLocaleLowerCase() === pp.plan_id.toLowerCase() ? 'columns plan-selected' : 'columns'}>
            {
              this.state.user_current_plan.toLocaleLowerCase() === pp.plan_id.toLowerCase() && <div class="card-ribbon"><span>Subscribed</span></div>
            }
            <ul className="price">
              <li className="header">{pp.plan_name} Plan</li>
              <li className="grey">${pp.price} / {pp.payment_cycle.toLowerCase()}
                {/* <div className="free-trial-text">{pp.trial_days} days free trial</div> */}
              </li>
              {this.UI_Included(pp.included)}
              {pp.not_included !== '' ? this.UI_Not_included(pp.not_included) : ''}
              <li className="grey">
                {
                  this.state.user_current_plan.toLocaleLowerCase() === pp.plan_id.toLowerCase() ?
                  <button className='button btn-active' disabled>Subscribed</button>
                  :<div>
                    {
                      pp.btn_text &&
                      <button className='button' onClick={this.subscribePlan.bind(this, pp)} disabled={this.state.plan_btn_disable}>{pp.btn_text}</button>
                    }
                  </div>
                }
              </li>
            </ul>
          </div>;
        });
        return (
          <div>
            <PageTitleBar
              title="Plans"
            />
            {
              !active_plan_exists &&
              <div>
                <Banner
                  title="Action Required"
                  status="warning"
                >
                    <p>Currently, you're using an older version of Wholesale All In One, please active a plan to continue with the latest version.</p>
                </Banner>
              </div>
            }
            <div className="pp-version-238">
              {PricingUI}
            </div>
            <div style={{clear: "both"}}>
              <div style={{clear: "both"}}></div>
              <FooterHelp>
                Which plan is best fit for me? <Link url="https://wholesale-all-in-one.myshopify.com/#shopify-section-pricing_details" external={true}> Click Here</Link>
              </FooterHelp>
            </div>
          </div>
        );
      }
      else {
        return (
          <div>
          No plan found
          </div>
        )
      }
    }
    else{
      return (
        <div>
          <SkeletonPageComp />
        </div>
      )
    }
  }
}