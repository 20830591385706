import React, { Component } from 'react';
import store from 'store2';
import {TextStyle,Form,Link,Stack,Layout,Card,Button,ActionList, Checkbox,  TextField, Popover, ButtonGroup, Select, RadioButton, Tag, Badge, FormLayout, PageActions } from '@shopify/polaris';
import { DeleteMinor,ArrowUpMinor, ArrowDownMinor } from '@shopify/polaris-icons';
import { v4 as uuidv4 } from 'uuid';
import { TitleBar, Modal } from '@shopify/app-bridge-react';
import form_fields_json from '../Json/ws-default-form-fields.json';
import all_countries_list from '../Json/all_countries_list.json';
import ToastMessage from '../Common/ToastMessage';
import SkeletonPageComp from './SkeletonPageComp';
import Common from '../Helpers/Common';
import axios from '../Axios';
import LoadingOverlay from './LoadingOverlay';
const BUILD_VERSION_EXTENSION =  process.env.REACT_APP_BUILD_VERSION_EXTENSION;

class CreateWSForm extends Component{
  constructor(props){
    super(props);
    this.state = {
      page_loaded: typeof this.props.match.params.id !== "undefined"? false : true,
      edit_id: this.props.match.params.id,
      uid: uuidv4(),
      title: "",
      usage: 0,
      state: "published",
      greeting_message: "Apply for a Wholesale Account and Start Saving Today!",
      signin_text: `Already have an account? <a href="/account/login">Login here</a>`,
      approval_type: "manual",
      customer_tags: [],
      terms_of_service_checkbox: false,
      terms_of_service_label: `I accept the <a href="https://${this.props.shop}/">Terms and Conditions</a>`,
      accepts_email_marketing_label: "I agree to receive promotional emails",
      terms_of_service_default: false,
      accepts_email_marketing_default: false,
      customer_accepts_email_marketing: true,
      fields_label: true,
      customer_tax_exempt: false,
      placeholders: false,
      default_country: "",
      default_country_checkbox: false,
      specific_countries: [],
      specific_countries_checkbox: false,
      temp_specific_countries_value: "",
      customer_redirect_url: "",
      customer_redirect_url_checkbox: false,
      submit_button_text: "Apply for an account",
      form_submited_message: "Thank you for submitting wholesale account opening request. We are processing your request for approval. We'll get back to you as soon as possible.",
      already_account_message: "A wholesale account with this email address or phone number already exists.",
      approval_pending_message: "You have already submitted the wholesale account opening request. We are processing your request for approval. We'll get back to you as soon as possible",
      auto_approved_message: "Congratulations! Your account has been approved, Please check your email to activate your account.",
      form_fields_json: form_fields_json,
      temp_customer_tags_value:  "",
      shop: this.props.shop,
      enable_address: true,
      enable_password: true,
      actionListActive: false,
      showToast: false,
      toastMsg: "",
      toastError: false,
      customer_notification_account_rejected: true,
      customer_notification_account_acknowledgment: false,
      notification_account_approved_new_customer: true,
      notification_account_approved_old_customer: true,
      owner_notification_new_account: true,
      meona: false,//modal_email_owner_new_account
      meoaana: false,//modal_email_owner_auto_approved_new_account
      mnaaoc: false,//modal_notification_account_approved_old_customer
      mcnar: false,//modal_customer_notification_account_rejected
      mnaanc: false,//modal_notification_account_approved_new_customer
      showmodal_notification_account_acknowledgment_new_customer: false,
      btn_loading: false,
      email_from: store("shop_sender_email") || store("owner_email") || "",
      email_to: store("owner_email") || "",
      type: "custom",
      auto_approved_email_to: "",
      approval_pending_email_to: "",
      reply_to: "",

      const_data:null, // if any changes occur in this object, save button will be shown
    }
    this.save = this.save.bind(this);
    this.messageHandler = this.messageHandler.bind(this)
  }

  messageHandler(event){
    if(window.location.origin === event.origin){
      /* Event came from inside app */
      if(event.data.hasOwnProperty('closemodal') && event.data.closemodal){
        const templateId = event.data.closemodal;
        
        /* Close all modals */
        this.setState({
          meona: false,
          meoaana: false,
          mnaaoc: false,
          mnaanc: false,
          mcnar: false,
          showmodal_notification_account_acknowledgment_new_customer: false,
        }); 

        if(this.state.email !== event.data.email_to){
          this.setState({email_to: event.data.email_to});
          if(this.state.approval_type === "auto") {
            this.setState({auto_approved_email_to: event.data.email_to});
          }
          else{
            this.setState({approval_pending_email_to: event.data.email_to});
          }
          this.update_email();
        }
        // Reload data if these templates
        else if(parseInt(templateId) === 1 || parseInt(templateId) === 5){
          if(!this.detectChanges())this.getForEdit();
        }
      }
    }
    
  }

  update_email(){
    var data = {
      email_to: this.state.email_to,
      shop: this.props.shop,
      uid: this.state.uid,
    };
    axios.put("/app/v2/ws-form/"+this.state.edit_id, data).then(response => {
      if(response){
        // console.log(response);
      }
    }).catch(error => {
      this.flashMessage("Server error", true);
      this.setState({btn_loading: false});
    })
  }

  componentDidMount() {
    if(typeof this.props.match.params.id !== "undefined"){
      this.getForEdit();
    }
    if(!this.state.edit_id && this.state.show === "edit"){
      this.props.history.push(BUILD_VERSION_EXTENSION+'/ws-customers');
    }

    /* Listen to postMessage [used to close the modal since modal is in iframe] */
    if (window.addEventListener) {
      window.addEventListener("message", this.messageHandler, false);
      } else {
      window.attachEvent("onmessage", this.messageHandler);
    }
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  handleBeforeUnload = (event) => {
    if(this.detectChanges()) {
      event.preventDefault();
      event.returnValue = '';
    }
  };
  
  isAddress(arr){
    var is_address = false;
    if(arr && arr.length > 0){
      try {
        var indx = arr.findIndex(x => x.type === "address");
        if(indx > -1){
          is_address = true;
        }
      } catch (e) { }
    }
    return is_address;
  }

  isPassword(arr){
    var is_password = false;
    if(arr && arr.length > 0){
      try {
        var indx = arr.findIndex(x => x.type === "password");
        if(indx > -1){
          is_password = true;
        }
      } catch (e) { }
    }
    return is_password;
  }

  getForEdit(){
    axios.get('/app/v2/ws-form?shop='+this.props.shop+'&id=' + this.props.match.params.id)
    .then(response => {
      if(response.data){
        let data = response.data;
        let statesJson = {
          uid: data.uid,
          title: data.title,
          state: data.state,
          email_from: data.email_from,
          email_to: data.email_to,
          greeting_message: data.greeting_message,
          signin_text: data.signin_text,
          approval_type: data.approval_type,
          customer_tags: data.customer_tags,
          terms_of_service_checkbox: data.terms_of_service_checkbox,
          terms_of_service_label: data.terms_of_service_label,
          terms_of_service_default: data.terms_of_service_default,
          customer_accepts_email_marketing: data.customer_accepts_email_marketing,
          accepts_email_marketing_label: data.accepts_email_marketing_label,
          accepts_email_marketing_default: data.accepts_email_marketing_default,
          fields_label: data.fields_label,
          customer_tax_exempt: data.customer_tax_exempt,
          placeholders: data.placeholders,
          default_country: data.default_country,
          default_country_checkbox: data.default_country !== "" ? true : data.default_country_checkbox,
          specific_countries: data.specific_countries,
          specific_countries_checkbox: data.specific_countries_checkbox,
          customer_redirect_url: data.customer_redirect_url,
          customer_redirect_url_checkbox: data.customer_redirect_url_checkbox,
          submit_button_text: data.submit_button_text,
          form_fields_json: data.form_fields_json,
          form_submited_message: data.form_submited_message,
          already_account_message: data.already_account_message,
          approval_pending_message: data.approval_pending_message,
          auto_approved_message: data.auto_approved_message,
          customer_notification_account_rejected: data.customer_notification_account_rejected,
          customer_notification_account_acknowledgment: data.customer_notification_account_acknowledgment,
          notification_account_approved_new_customer: data.notification_account_approved_new_customer,
          notification_account_approved_old_customer: data.notification_account_approved_old_customer,
          owner_notification_new_account: data.owner_notification_new_account,
          reply_to: data.reply_to || "",
        };
        let const_data = Object.assign({}, statesJson);
        // copy arrays too to fix immutable data
        const_data.customer_tags = data.customer_tags && data.customer_tags.length > 0 ? JSON.parse(JSON.stringify(data.customer_tags)) : [];
        const_data.form_fields_json = data.form_fields_json && data.form_fields_json.length > 0 ? JSON.parse(JSON.stringify(data.form_fields_json)) : [];
        const_data.specific_countries = data.specific_countries && data.specific_countries.length > 0 ? JSON.parse(JSON.stringify(data.specific_countries)) : [];

        statesJson.page_loaded = true;
        statesJson.enable_address = this.isAddress(data.form_fields_json);
        statesJson.enable_password = this.isPassword(data.form_fields_json);
        statesJson.type = data.type;
        statesJson.usage = data.usage;
        statesJson.const_data = const_data;
        this.setState(statesJson);

        axios.get("/app/v2/ws-form-template?uid="+data.uid+"&template_id=5").then( response1 => {
          axios.get("/app/v2/ws-form-template?uid="+data.uid+"&template_id=1").then( response2 => {
            if(response1 && response1.data && response1.data.to){
              this.setState({
                auto_approved_email_to: response1.data.to
              });
            }
            if(response2 && response2.data && response2.data.to){
              this.setState({
                approval_pending_email_to: response2.data.to
              });
            }
            if(this.state.approval_type === "auto"){
              this.setState({
                email_to: this.state.auto_approved_email_to
              })
            }
            else{
              this.setState({
                email_to: this.state.approval_pending_email_to
              })
            }
          }).catch(e => { });
        }).catch(e => { });

      }
      else{
        this.toast("Could not load data. Try again", true);
      }
    })
    .catch(error => {
      this.setState({page_loaded: true});
    });
  }

  validation(){
    var isValid = false;
    if(!this.state.title || this.state.title.trim() === ""){
      isValid = false;
    }
    return isValid;
  }

  save(){
    if(!this.state.title || this.state.title.trim() === ""){
      this.flashMessage("Title field is required", true);
      return;
    }
    if(this.state.approval_type === "auto" && this.state.customer_tags.length === 0){
      this.flashMessage("Add atleast 1 customer tag for auto approval.", true);
      return;
    }
    if(this.state.email_from.trim().match(/^([A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,})$/g) === null){
      this.flashMessage("Invalid Sender Email Address", true);
      return;
    }
    if(this.state.email_from.trim() === "noreply@digitalcoo.com" && this.state.reply_to.trim().match(/^([A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,})$/g) === null){
      this.flashMessage("Invalid Reply To Email Address", true);
        return;
    }
    this.setState({btn_loading: true});
    var form_data = {
      owner_email: store("owner_email") || "",
      email_from: this.state.email_from,
      email_to: this.state.email_to,
      shop: this.props.shop,
      uid: this.state.uid,
      title: this.state.title,
      state: this.state.state,
      greeting_message: this.state.greeting_message,
      signin_text: this.state.signin_text,
      approval_type: this.state.approval_type,
      customer_tags: this.state.customer_tags,
      terms_of_service_checkbox: this.state.terms_of_service_checkbox,
      terms_of_service_label: this.state.terms_of_service_label,
      terms_of_service_default: this.state.terms_of_service_default,
      customer_accepts_email_marketing: this.state.customer_accepts_email_marketing,
      accepts_email_marketing_label: this.state.accepts_email_marketing_label,
      accepts_email_marketing_default: this.state.accepts_email_marketing_default,
      fields_label: this.state.fields_label,
      customer_tax_exempt: this.state.customer_tax_exempt,
      placeholders: this.state.placeholders,
      default_country: this.state.default_country_checkbox === true ? this.state.default_country : "",
      default_country_checkbox: this.state.default_country_checkbox,
      specific_countries: this.state.specific_countries,
      specific_countries_checkbox: this.state.specific_countries_checkbox,
      customer_redirect_url: this.state.customer_redirect_url,
      customer_redirect_url_checkbox: this.state.customer_redirect_url_checkbox,
      submit_button_text: this.state.submit_button_text,
      form_fields_json: this.state.form_fields_json,
      form_submited_message: this.state.form_submited_message,
      already_account_message: this.state.already_account_message,
      approval_pending_message: this.state.approval_pending_message,
      auto_approved_message: this.state.auto_approved_message,
      customer_notification_account_rejected: this.state.customer_notification_account_rejected,
      customer_notification_account_acknowledgment: this.state.customer_notification_account_acknowledgment,
      notification_account_approved_new_customer: this.state.notification_account_approved_new_customer,
      notification_account_approved_old_customer: this.state.notification_account_approved_old_customer,
      owner_notification_new_account: this.state.owner_notification_new_account,
      reply_to: this.state.reply_to.trim() || this.state.reply_to,
    };
    if(this.props.show === "edit" && this.state.edit_id){
      axios.put("/app/v2/ws-form/"+this.state.edit_id, form_data).then(response => {
        if(response && response.data){
          if(response.data.status === 1){
            this.flashMessage(response.data.message, false);
            this.props.history.push(BUILD_VERSION_EXTENSION+'/ws-customers');
          }
          else{
            this.flashMessage(response.data.message, true);
            this.setState({btn_loading: false});
          }
        }
      }).catch(error => {
        this.flashMessage("Server error", true);
        this.setState({btn_loading: false});
      })
    }
    else{
      axios.post("/app/v2/ws-form", form_data).then(response => {
        if(response && response.data){
          if(response.data.status === 1){
            this.flashMessage(response.data.message, false);
            this.props.history.push(BUILD_VERSION_EXTENSION+'/ws-customers');
          }
          else{
            this.flashMessage(response.data.message, true);
            this.setState({btn_loading: false});
          }
        }
      }).catch(error => {
        this.flashMessage("Server error", true);
        this.setState({btn_loading: false});
      })
    }
  }

  delete_rule(){
    if(this.state.type !== "default" && window.confirm("Want to delete this rule?")){
      if(this.props.show === "edit" && this.state.edit_id){
        var q = "/app/v2/ws-form";
        q += "?ws=1&shop="+this.props.shop;
        q += "&id="+this.state.edit_id;
        q += "&uid="+this.state.uid;
        axios.delete(q).then(response => {
          if(response && response.data && response.data.status === 1){
            if(response.data.status === 1){
              this.flashMessage(response.data.message, false);
              this.props.history.push(BUILD_VERSION_EXTENSION+'/ws-customers');
            }
            else{
              this.flashMessage(response.data.message, true);
            }
          }
        }).catch(error => {
          this.flashMessage("Server error", true);
        })
      }
    }
  }

  archive_rule(){
    if(window.confirm("Want to archive this rule?")){
      if(this.props.show === "edit" && this.state.edit_id){
        var form_data = {
          shop: this.props.shop,
          uid: this.state.uid,
          title: this.state.title,
          state: "archived"
        };
        axios.put("/app/v2/ws-form/"+this.state.edit_id, form_data).then(response => {
          if(response && response.data && response.data.status === 1){
            if(response.data.status === 1){
              this.flashMessage(response.data.message, false);
              this.props.history.push(BUILD_VERSION_EXTENSION+'/ws-customers');
            }
            else{
              this.flashMessage(response.data.message, true);
              this.setState({btn_loading: false});
            }
          }
        }).catch(error => {
          this.flashMessage("Server error", true);
          this.setState({btn_loading: false});
        });
      }
    }
  }

  generateOptionsHTML(field){
    var html = '';
    var isRequired = false;
    if(field.type === "radio"){
        if(field && field.options && field.options.length > 0){
            isRequired = field.required ? ' required="required"':"";
            html += '<div>';
            field.options.forEach((opt,index) => {
                var isSelected = opt.selected ?' checked="checked"':'';
                html += '<label class="radio-option-label">';
                html += '<input type="radio" value="'+opt.label+'" name="customer['+this.buildName(field.label)+']" placeholder="'+opt.label+'" '+isRequired+' aria-required="'+(field.required?true:false)+'" '+isSelected+'/><span>'+opt.label+'</span>';
                html += '</label>';
            });
            html += '</div>';
        }
    }
    else if(field.type === "select"){
        if(field && field.options && field.options.length > 0){
            isRequired = field.required ? ' required="required"':"";
            html += '<select name="customer['+this.buildName(field.label)+']" placeholder="'+field.label+'" '+isRequired+' aria-required="'+(field.required?true:false)+'">';
            field.options.forEach((opt,index) => {
                var isSelected = opt.selected ?' selected="selected"':'';
                html += '<option value="'+opt.label+'" '+isSelected+'>'+opt.label+'</option>';
            });
            html += '</select>';
        }
    }
    return html;
  }

  removeFieldLabel(i){
    var form_fields_json = this.state.form_fields_json;
    form_fields_json.splice(i, 1);
    this.setState({ form_fields_json });
  }

  addFieldLabel(type) {
    if(typeof type === 'undefined'){type="text";}
    var form_fields_json = this.state.form_fields_json;
    var options = [];
    if(type === "radio"|| type === "select"){ // if radio button selected
        options = [
            {
                label: "",
                value: "",
                selected: false
            }
        ]
    }
    var position = form_fields_json.length;
    try{
      if(form_fields_json.length > 0){
        position = [...form_fields_json].sort((a, b) => {
            return b.position - a.position
        })[0].position+1;
      }
    } catch(e) {}
    form_fields_json.push({
        label:"",
        position: position,
        required: false,
        read_only: false,
        type: type,
        options: options,
        name: "",
        default_value: "",
        style_width: "ws--whole"
    });
    this.setState({form_fields_json});
  }

  buildName(a){
    try{
        a = a.toLowerCase()
        .replace(/\s/g,"_")
        .replace(/\./g,"")
        .replace(/!/g,"")
        .replace(/,/g,"")
        .replace(/[^\w\s]/gi, "")
    }catch(e){}
    return a;
  }

  addAddressFields(){
    var form_fields_json = this.state.form_fields_json;
    var address = [
        "Address1",
        "Address2",
        "City",
        "Company Name",
        "Country",
        "State/Province",
        "Zip/Postal Code"
    ];
    var added_count = 0;
    function addAddress(label){
        var name = label;
        switch (label) {
            case "Address1":
                name = "address1";
                break;
            case "Address2":
                name = "address2";
                break;
            case "City":
                name = "city";
                break;
            case "Company Name":
                name = "company";
                break;
            case "Country":
                name = "country";
                break;
            case "State/Province":
                name = "province";
                break;
            case "Zip/Postal Code":
                name = "zip";
                break;
            default:
                name = label;
                break;
        }
        form_fields_json.push({
            label: label,
            position: form_fields_json.length,
            required: label === "Address2" || label === "Company Name"?false:true,
            read_only: false,
            type: "address",
            options: [],
            name: name,
            style_width: "ws--whole"
        });
        added_count++;
        if(address[added_count]){
            addAddress(address[added_count]);
        }
    }
    if(address[added_count]){
        addAddress(address[added_count]);
    }
    else{
        this.setState({form_fields_json});
    }   
  }

  removeAddressFields(){
    var form_fields_json = this.state.form_fields_json;
    form_fields_json = form_fields_json.filter(x=>x.type !== "address");
    this.setState({form_fields_json});
    // var form_fields_json = this.state.form_fields_json;
    // var address = [
    //     "address1",
    //     "address2",
    //     "city",
    //     "country",
    //     "province",
    //     "zip"
    // ];
    // var added_count = 0;
    // function removeAddress(label){
    //     var index = form_fields_json.findIndex(x=>x.type !== "address");
    //     if(index > -1){
    //         form_fields_json.splice(index, 1);
    //     }
    //     added_count++;
    //     if(address[added_count]){
    //         removeAddress(address[added_count]);
    //     }
    // }
    // if(address[added_count]){
    //     removeAddress(address[added_count]);
    // }
    // else{
    //     this.setState({form_fields_json});
    // }
  }

  addPasswordFields(){
    var form_fields_json = this.state.form_fields_json;
    var address = [
      "Password",
      "Password Confirmation"
    ];
    var added_count = 0;
    function addPassword(label){
        form_fields_json.push({
          label: label,
          type: label.toLowerCase().replace(/\s/g,"_"),
          position: form_fields_json.length,
          required: true,
          read_only: false
        });
        added_count++;
        if(address[added_count]){
          addPassword(address[added_count]);
        }
    }
    if(address[added_count]){
      addPassword(address[added_count]);
    }
    else{
      this.setState({form_fields_json});
    }
  }

  removePasswordFields(){
    var form_fields_json = this.state.form_fields_json;
    var address = [
        "password",
        "password_confirmation"
    ];
    var added_count = 0;
    function removePassword(type){
        var index = form_fields_json.findIndex(x=>x.type === type);
        if(index > -1){
            form_fields_json.splice(index, 1);
        }
        added_count++;
        if(address[added_count]){
            removePassword(address[added_count]);
        }
    }
    if(address[added_count]){
        removePassword(address[added_count]);
    }
    else{
        this.setState({form_fields_json});
    }
  }

  inputOptions(el, index){
    var optionsHtml = null;
    if(el && el.options && el.options.length > 0){
            optionsHtml = el.options.map((opt,i) => {
            return <div key={"kee"+i}>
                <Stack alignment="fill" distribution="fill">
                    <Stack spacing="loose">
                        <TextField
                            label={opt.label}
                            labelHidden
                            value={opt.label}
                            placeholder={"Option label"}
                            onChange={(v) => {
                                var form_fields_json = this.state.form_fields_json;
                                try{
                                    form_fields_json[index].options[i] = { ...form_fields_json[index].options[i], label: v };
                                    this.setState({ form_fields_json }); 
                                }catch(e){}
                            }}
                        ></TextField>
                        <Checkbox
                            label="Checked by default"
                            checked={opt.selected}
                            onChange={(v,id) => {
                                var form_fields_json = this.state.form_fields_json;
                                try{
                                    if(v){
                                        try{
                                            form_fields_json[index].options.forEach((x,y) => {
                                                form_fields_json[index].options[y].selected = false;
                                            })
                                        }catch(e){}
                                        form_fields_json[index].options[i] = { ...form_fields_json[index].options[i], selected: v };
                                        this.setState({ form_fields_json });
                                    }
                                    else{
                                        form_fields_json[index].options[i] = { ...form_fields_json[index].options[i], selected: v };
                                        this.setState({ form_fields_json });
                                    }
                                }catch(e){}
                            }}
                        />
                        {
                            i+1 === el.options.length?
                            <ButtonGroup>
                                {
                                    (el.options.length > 1) && 
                                    <Button
                                        icon={DeleteMinor}
                                        onClick={() => {
                                            var form_fields_json = this.state.form_fields_json;
                                            try{
                                                if(form_fields_json[index]){
                                                    if(typeof form_fields_json[index].options !== "undefined"){
                                                        form_fields_json[index].options.splice(i,1);
                                                        this.setState({ form_fields_json }); 
                                                    }
                                                }
                                            }catch(e){}
                                        }}
                                    ></Button>
                                }
                                <Button
                                    onClick={() => {
                                        var form_fields_json = this.state.form_fields_json;
                                        try{
                                            if(form_fields_json[index]){
                                                if(typeof form_fields_json[index].options !== "undefined"){
                                                    form_fields_json[index].options.push({
                                                        label: "",
                                                        value: "",
                                                        selected: false
                                                    });
                                                    this.setState({ form_fields_json }); 
                                                }
                                            }
                                        }catch(e){}
                                    }}
                                >ADD</Button>
                            </ButtonGroup>
                            :<Button
                                icon={DeleteMinor}
                                onClick={() => {
                                    var form_fields_json = this.state.form_fields_json;
                                    try{
                                        if(form_fields_json[index]){
                                            if(typeof form_fields_json[index].options !== "undefined"){
                                                form_fields_json[index].options.splice(i,1);
                                                this.setState({ form_fields_json }); 
                                            }
                                        }
                                    }catch(e){}
                                }}
                            ></Button>
                        }
                    </Stack>
                </Stack>
            </div>
        });
        return <div className="input-select-options"><Stack vertical>{optionsHtml}</Stack></div>;
    }
    else{
        return null;
    }
  }

  sortToDown(item,index,el){
    var temp_json = [...this.state.form_fields_json];
    const pos = temp_json[index].position;
    temp_json[index].position = pos+1;
    temp_json[index+1].position = pos;
    temp_json.sort(function (a, b) {
        return a.position - b.position;
    });
    this.setState({
        form_fields_json: temp_json
    });
  }

  sortToUp(item,index,el){
    var temp_json = [...this.state.form_fields_json];
    const pos = temp_json[index].position;
    temp_json[index].position = pos-1;
    temp_json[index-1].position = pos;
    temp_json.sort(function (a, b) {
        return a.position - b.position;
    });
    this.setState({
        form_fields_json: temp_json
    });
  }

  previousState = null;

  renderTags(){
    var customer_tags = this.state.customer_tags;
    return customer_tags.map((tag,i) => {
      return <span key={i}>
            <Tag
                onRemove={this.removeTag.bind(this,tag,i)}
            >{tag}</Tag>
        </span>
    });
  }
  
  removeTag(tag,i,el){
    var customer_tags = this.state.customer_tags;
    customer_tags.splice([i],1);
    this.setState({ customer_tags });
  }

  showToastMessage(msg, error){
    this.setState({
      showToast: true,
      toastMsg: msg,
      toastError: error||false,
    });
  }

  flashMessage(msg,isError) {
    this.showToastMessage(msg, isError);
  }

  dissmissToast = () =>{
    this.setState({
      showToast: false,
    });
  }

  generate_embed_code(){
    var html = `<div id="wsaio-form" data-id="${this.state.uid}" data-shop="${this.props.shop}"></div><script>(function(d, s, id){var js,wjs=d.getElementsByTagName(s)[0];if(d.getElementById(id)) return;js=d.createElement(s);js.id=id;js.src="${Common.getHostName()}/forms/embed.js";wjs.parentNode.insertBefore(js,wjs);}(document,'script','wsaio-customer-form-embed'));</script>`;
    return html;
  }

  getChangeEmailLink(){
    if(typeof this.props.match.params.id !== "undefined"){
      return <span>(<Button plain onClick={() => {
        if(this.state.approval_type === "auto"){
          this.setState({meoaana: true});
        }
        else{
          this.setState({meona: true});
        }
      }}>Change</Button>)</span>
    }
    else{
      return null;
    }
  }

  /* Detect Changes */
  detectChanges(){
    let constData = this.state.const_data;

    /* this will detect if anything changes on page */

    /**
    | const_data: constant data, that should not be change
    | compare_data: data to compare, is not match, data is changed
    */
    const _comparechange = function(const_data, compare_data) {

      /* Function that will compare both objects */
      let deepCompare = () => {
          var i, l, leftChain, rightChain;

          function compare2Objects(x, y) {
              var p;

              // remember that NaN === NaN returns false
              // and isNaN(undefined) returns true
              if (isNaN(x) && isNaN(y) && typeof x === 'number' && typeof y === 'number') {
                  return true;
              }

              // Compare primitives and functions.     
              // Check if both arguments link to the same object.
              // Especially useful on the step where we compare prototypes
              if (x === y) {
                  return true;
              }

              // Works in case when functions are created in constructor.
              // Comparing dates is a common scenario. Another built-ins?
              // We can even handle functions passed across iframes
              if ((typeof x === 'function' && typeof y === 'function') ||
                  (x instanceof Date && y instanceof Date) ||
                  (x instanceof RegExp && y instanceof RegExp) ||
                  (x instanceof String && y instanceof String) ||
                  (x instanceof Number && y instanceof Number)) {
                  return x.toString() === y.toString();
              }

              // At last checking prototypes as good as we can
              if (!(x instanceof Object && y instanceof Object)) {
                  return false;
              }

              if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) {
                  return false;
              }

              if (x.constructor !== y.constructor) {
                  return false;
              }

              if (x.prototype !== y.prototype) {
                  return false;
              }

              // Check for infinitive linking loops
              if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
                  return false;
              }

              // Quick checking of one object being a subset of another.
              // todo: cache the structure of arguments[0] for performance
              for (p in y) {
                  if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
                      return false;
                  } else if (typeof y[p] !== typeof x[p]) {
                      return false;
                  }
              }

              for (p in x) {
                  if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
                      return false;
                  } else if (typeof y[p] !== typeof x[p]) {
                      return false;
                  }

                  switch (typeof (x[p])) {
                      case 'object':
                      case 'function':

                          leftChain.push(x);
                          rightChain.push(y);

                          if (!compare2Objects(x[p], y[p])) {
                              return false;
                          }

                          leftChain.pop();
                          rightChain.pop();
                          break;

                      default:
                          if (x[p] !== y[p]) {
                              return false;
                          }
                          break;
                  }
              }

              return true;
          }

          if (arguments.length <= 1) {
              //return true; //Die silently? Don't know how to handle such case, please help...
              // eslint-disable-next-line
              throw "Need two or more arguments to compare";
          }

          for (i = 1, l = arguments.length; i < l; i++) {

              leftChain = []; //Todo: this can be cached
              rightChain = [];

              if (!compare2Objects(arguments[0], arguments[i])) {
                  return false;
              }
          }

          return true;
      }

      return !deepCompare(const_data, compare_data);
    };
    
    /* Match whole data */
    const compareData = {
      uid: this.state.uid,
      title: this.state.title,
      state: this.state.state,
      email_from: this.state.email_from,
      greeting_message: this.state.greeting_message,
      signin_text: this.state.signin_text,
      approval_type: this.state.approval_type,
      customer_tags: this.state.customer_tags,
      terms_of_service_checkbox: this.state.terms_of_service_checkbox,
      terms_of_service_label: this.state.terms_of_service_label,
      terms_of_service_default: this.state.terms_of_service_default,
      customer_accepts_email_marketing: this.state.customer_accepts_email_marketing,
      accepts_email_marketing_label: this.state.accepts_email_marketing_label,
      accepts_email_marketing_default: this.state.accepts_email_marketing_default,
      fields_label: this.state.fields_label,
      customer_tax_exempt: this.state.customer_tax_exempt,
      placeholders: this.state.placeholders,
      default_country: this.state.default_country_checkbox === true ? this.state.default_country : "",
      default_country_checkbox: this.state.default_country_checkbox,
      specific_countries: this.state.specific_countries,
      specific_countries_checkbox: this.state.specific_countries_checkbox,
      customer_redirect_url: this.state.customer_redirect_url,
      customer_redirect_url_checkbox: this.state.customer_redirect_url_checkbox,
      submit_button_text: this.state.submit_button_text,
      form_fields_json: this.state.form_fields_json,
      form_submited_message: this.state.form_submited_message,
      already_account_message: this.state.already_account_message,
      approval_pending_message: this.state.approval_pending_message,
      auto_approved_message: this.state.auto_approved_message,
      customer_notification_account_rejected: this.state.customer_notification_account_rejected,
      customer_notification_account_acknowledgment: this.state.customer_notification_account_acknowledgment,
      notification_account_approved_new_customer: this.state.notification_account_approved_new_customer,
      notification_account_approved_old_customer: this.state.notification_account_approved_old_customer,
      owner_notification_new_account: this.state.owner_notification_new_account,
      reply_to: this.state.reply_to || "",
    };
    try{
      delete constData["email_to"]; // because we will not be comparing this
    }
    catch(e){}
    return _comparechange(constData, compareData);
  }

  addCustomerTags(tags_key, temp_tags_key) {
    /* Fetch tags from state */
    var temp_customer_tags_value = this.state[temp_tags_key];
    try{
        temp_customer_tags_value = temp_customer_tags_value.replace(/,/gi, "");
    }catch(e){}
    if(temp_customer_tags_value.trim() === "") return;

    /* Check if tags are not already exists */
    var customer_tags = this.state[tags_key];
    if(customer_tags.some(tag=>tag.toLowerCase() === temp_customer_tags_value.toLowerCase())){
        this.showToastMessage("Tag already exists",true);
        return false;
    }

    /* Update tags */
    customer_tags.push(temp_customer_tags_value);
    this.setState({ [tags_key]: customer_tags, [temp_tags_key]: "" });
  }
  

  render(){
    var {
      title, actionListActive, greeting_message, signin_text, form_fields_json, submit_button_text, temp_customer_tags_value, customer_tags,specific_countries,customer_accepts_email_marketing 
    } = this.state;

    var toast = this.state.showToast? (
      <ToastMessage
        onDismiss={this.dissmissToast}
        message={this.state.toastMsg}
        error={this.state.toastError}
      />
    ): (null);

    var additional_info_fields = form_fields_json.map((el, i) => {
      return <div key={i} className="list-bordered-item">
          <Stack alignment="fill" distribution="fill">
          <Stack spacing="tight" alignment="center">
                  <ButtonGroup segmented>
                      <Button
                        icon={ArrowUpMinor}
                        onClick={this.sortToUp.bind(this, el, i)}
                        disabled={0 === i}
                      >
                      </Button>
                      <Button
                        icon={ArrowDownMinor}
                        onClick={this.sortToDown.bind(this, el, i)}
                        disabled={this.state.form_fields_json.length === i+1}
                      >
                      </Button>
                  </ButtonGroup>
                  <TextField
                    disabled={el.read_only}
                    label={el.type === "heading"? "Heading": "Field label"}
                    labelHidden={true}
                    placeholder={el.type === "heading"? "Heading": "Field label"}
                    name="Field label"
                    value={el.label}
                    onChange={(value, id) => {
                        var form_fields_json = this.state.form_fields_json;
                        form_fields_json[i] = { ...form_fields_json[i], label: value };
                        this.setState({ form_fields_json });
                    }}
                  />
                  <Select
                    disabled={true}
                    label="type"
                    labelHidden={true}
                    options={[
                        { label: "Text", value: "text" },
                        { label: "Textarea", value: "textarea" },
                        { label: "Email", value: "email" },
                        { label: "Password", value: "password_confirmation" },
                        { label: "Password", value: "password" },
                        { label: "First name", value: "first-name" },
                        { label: "Last Name", value: "last-name" },
                        { label: "Phone", value: "phone" },
                        { label: "Number", value: "number" },
                        { label: "Checkbox", value: "checkbox" },
                        { label: "Radio", value: "radio" },
                        { label: "Heading", value: "heading" },
                        { label: "Select", value: "select" },
                        { label: "Tel", value: "tel" },
                        { label: "Time", value: "time" },
                        { label: "Url", value: "url" },
                        { label: "File", value: "file" },
                        { label: "Week", value: "week" },
                        { label: "Address", value: "address" }
                    ]}
                    id="type"
                    name="type"
                    value={el.type}
                    onChange={(selected, id)=>{
                        // var form_fields_json = this.state.form_fields_json;
                        // form_fields_json[i] = { ...form_fields_json[i], type: selected };
                        // this.setState({ form_fields_json });
                    }}
                  />
                  <Select
                    disabled={el.label === "Email" || el.type === "first-name" || el.type === "last-name" || el.type === "heading"}
                    label="Required"
                    labelHidden={true}
                    options={[
                      {label: "Optional", value: "optional" },
                      {label: "Required", value: "required" }
                    ]}
                    id="required"
                    name="required"
                    value={el.required ? "required":"optional"}
                    onChange={(selected, id)=>{
                        var form_fields_json = this.state.form_fields_json;
                        form_fields_json[i] = { ...form_fields_json[i], required: selected === "optional"?false:true };
                        this.setState({ form_fields_json });
                    }}
                  />
                  <Select
                    label="Size"
                    labelHidden={true}
                    labelInline
                    options={[
                        {label: "Full", value: "ws--whole" },
                        {label: "Half", value: "ws--half" },
                        {label: "Third", value: "ws--third" },
                        {label: "Quarter", value: "ws--quarter" },
                    ]}
                    id="width"
                    name="width"
                    value={el.style_width}
                    onChange={(selected, id)=>{
                        var form_fields_json = this.state.form_fields_json;
                        form_fields_json[i] = { ...form_fields_json[i], style_width: selected };
                        this.setState({ form_fields_json });
                    }}
                  />
                  {
                      el.type === "checkbox" && <Checkbox
                          label="Checked by default"
                          checked={el.default_value === 'checked="checked"'}
                          onChange={(v,id) => {
                              var form_fields_json = this.state.form_fields_json;
                              var dv = "";
                              if(v){
                                  dv = 'checked="checked"';
                              }
                              form_fields_json[i] = { ...form_fields_json[i], default_value: dv };
                              this.setState({ form_fields_json });
                          }}
                      ></Checkbox>
                  }
                  {
                      ((el.type !== "first-name") && (el.type !== "last-name") && (el.type !== "email") && el.type !== "address" && el.type !== "password" && el.type !== "password_confirmation") ?
                      <Button
                        icon={DeleteMinor}
                        destructive={false}
                        onClick={this.removeFieldLabel.bind(this, i)}
                      ></Button>
                      :null
                  }
                  {
                      ((el.type === "first-name") || (el.type === "last-name") || (el.type === "email") || el.type === "address" || el.type === "password" || el.type === "password_confirmation") ?
                      <div style={{fontSize:'85%'}}><TextStyle variation="subdued">(Required)</TextStyle></div>
                      :null
                  }
                  {
                    (el.type === "password" || el.type === "password_confirmation") && 
                    <Link url="https://support.digitalcoo.com/hc/en-us/articles/4426404354324" external>
                      How does password work
                    </Link>
                  }
              </Stack>
              { this.inputOptions(el,i) }
          </Stack>
      </div>;
    });

    const actionListActivator = (
      <Button
        onClick={() => {this.setState({actionListActive:!actionListActive})}}
        disclosure="select"
      >
        Add field 
      </Button>
    );

 /**
     * Need to check both cases to determine this is phone field
     *    old: label is "phone" or "phone number"
     *    new: type is "phone" 
    */
  const isPhoneAdded = form_fields_json.some(x=>x.type === "phone" || x.label.toLowerCase() === "phone" || x.label.trim().toLowerCase() === "phone number");

  const add_new_field_button = <div>
      <Popover
        active={actionListActive}
        activator={actionListActivator} 
        onClose={() => {this.setState({actionListActive:!actionListActive})}}
      >
        <ActionList
          items={[
              {
                  content: this.state.enable_address? "Remove address fields":"Add address fields",
                  onAction: () => {
                      if(!this.state.enable_address){
                          this.addAddressFields();
                      }
                      else{
                          this.removeAddressFields();
                      }
                      this.setState({enable_address:!this.state.enable_address,actionListActive: !actionListActive})
                  },
              },
              {
                content: this.state.enable_password? "Remove password fields":"Add password fields",
                onAction: () => {
                  if(!this.state.enable_password){
                      this.addPasswordFields();
                  }
                  else{
                      this.removePasswordFields();
                  }
                  this.setState({enable_password:!this.state.enable_password,actionListActive: !actionListActive})
                },
              },
              {
                  content: 'Add heading',
                  onAction: () => {this.addFieldLabel("heading");this.setState({actionListActive: !actionListActive})},
              },
              {
                  content: 'Add text',
                  onAction: () => {this.addFieldLabel("text");this.setState({actionListActive: !actionListActive})},
                },
                ...!isPhoneAdded ? [{
                    content: 'Add phone',
                    onAction: () => {this.addFieldLabel("phone");this.setState({actionListActive: !actionListActive})},
                }] : [],
                {
                  content: 'Add number',
                  onAction: () => {this.addFieldLabel("number");this.setState({actionListActive: !actionListActive})},
              },
              {
                  content: 'Add multiline text',
                  onAction: () => {this.addFieldLabel("textarea");this.setState({actionListActive: !actionListActive})},
              },
              {
                  content: 'Add file',
                  onAction: () => {this.addFieldLabel("file");this.setState({actionListActive: !actionListActive})},
              },
              {
                  content: 'Add radio button',
                  onAction: () => {this.addFieldLabel("radio");this.setState({actionListActive: !actionListActive})},
              },
              {
                  content: 'Add checkbox',
                  onAction: () => {this.addFieldLabel("checkbox");this.setState({actionListActive: !actionListActive})},
              },
              {
                  content: 'Add dropdown',
                  onAction: () => {this.addFieldLabel("select");this.setState({actionListActive: !actionListActive})},
              },
              {
                  content: 'Add tel',
                  onAction: () => {this.addFieldLabel("tel");this.setState({actionListActive: !actionListActive})},
              },
              {
                  content: 'Add URL',
                  onAction: () => {this.addFieldLabel("url");this.setState({actionListActive: !actionListActive})},
              },
              {
                  content: 'Add week',
                  onAction: () => {this.addFieldLabel("week");this.setState({actionListActive: !actionListActive})},
              },
              {
                  content: 'Add time',
                  onAction: () => {this.addFieldLabel("time");this.setState({actionListActive: !actionListActive})},
              }
          ]}
        />
      </Popover>
    </div>;

    const form_builder_section = <div>
      <div className="ws--eq-width">
        <Stack vertical>
          <Stack.Item>
            {additional_info_fields}
          </Stack.Item>
          <Stack.Item>
            {add_new_field_button}
          </Stack.Item>
        </Stack>
      </div>
    </div>;

    const form_settings_section = <div>
      <Card title="Customer Signup Form built-in customization" actions={[{ content: "Learn More", url: "https://support.digitalcoo.com/hc/en-us/articles/360045747772", external: true }]}>
        <Card.Section>
          <Stack vertical>
            <Checkbox
              label="Show labels for wholesale signup form fields"
              checked={this.state.fields_label}
              id="customer-fields_label"
              onChange={(v)=>{
                  this.setState({fields_label:v})
              }}
            />
            <Checkbox
              label="Show placeholders in wholesale signup form fields"
              checked={this.state.placeholders}
              id="customer-placeholders"
              onChange={(v)=>{
                  this.setState({placeholders:v})
              }}
            />
            <Checkbox
              label='Show checkbox "I agree to receive promotional emails"'
              checked={customer_accepts_email_marketing}
              id="customer-accept-marketing"
              onChange={(v)=>{
                this.setState({customer_accepts_email_marketing:v});
              }}
            />
            {
              this.state.customer_accepts_email_marketing && 
              <div className="list-bordered-item" style={{marginLeft: "30px"}}>
                <Stack vertical spacing="tight">
                  <TextField
                    multiline={2}
                    label="Editable Text"
                    helpText="HTML accepted"
                    value={this.state.accepts_email_marketing_label}
                    onChange={(v) => { this.setState({accepts_email_marketing_label: v})}}
                  />
                  <Checkbox
                    label="Checked by default"
                    checked={this.state.accepts_email_marketing_default}
                    onChange={(v) => { this.setState({accepts_email_marketing_default: v})}}
                  />
                </Stack>
              </div>
            }
            <Checkbox
              label='Show checkbox "I accept the Terms and Conditions"'
              checked={this.state.terms_of_service_checkbox}
              onChange={(v) => { this.setState({terms_of_service_checkbox: v})}}
            />
            {
              this.state.terms_of_service_checkbox && 
              <div className="list-bordered-item" style={{marginLeft: "30px"}}>
                <Stack vertical spacing="tight">
                  <TextField
                    multiline={2}
                    label="Editable Text"
                    helpText="HTML accepted"
                    value={this.state.terms_of_service_label}
                    onChange={(v) => { this.setState({terms_of_service_label: v})}}
                  />
                  <Checkbox
                    label="Checked by default"
                    checked={this.state.terms_of_service_default}
                    onChange={(v) => { this.setState({terms_of_service_default: v})}}
                  />
                </Stack>
              </div>
            }
            <Checkbox
              label="Set all customers registered through this form as tax-exempt"
              checked={this.state.customer_tax_exempt}
              id="customer-tax-exempt"
              onChange={(v)=>{
                this.setState({customer_tax_exempt:v})
              }}
              />

              <Checkbox
                label='Show specific countries in the address field'
                checked={this.state.specific_countries_checkbox}
                onChange={(v) => { this.setState({specific_countries_checkbox: v})}}
              />
              {
                this.state.specific_countries_checkbox && 
                <div className="list-bordered-item" style={{marginLeft: "30px"}}>
                  <Stack vertical spacing="extraTight">
                    <FormLayout>
                      <FormLayout.Group>
                        <Select
                          label="Select countries"
                          value={this.state.temp_specific_countries_value}
                          options={all_countries_list}
                          helpText={specific_countries && specific_countries.length === 0 ? "All countries will be shown" : ""}
                          onChange={ (v) => {
                            var specific_countries = this.state.specific_countries;
                            if(specific_countries.indexOf(v) > -1){
                                this.flashMessage("Country already exists",true);
                                return false;
                            }
                            specific_countries.push(v);
                            this.setState({ specific_countries, temp_specific_countries_value: "" });
                          }}
                        />
                        <div></div>
                        <div></div>
                      </FormLayout.Group>
                    </FormLayout>
                  
                    {
                      specific_countries && specific_countries.length > 0 &&
                      <Stack spacing="extraTight">
                      {specific_countries.map((tag,i) => {
                          return <span key={i}>
                              <Tag
                                  onRemove={() => {
                                    var specific_countries = this.state.specific_countries;
                                    specific_countries.splice([i],1);
                                    this.setState({ specific_countries });
                                  }}
                              >
                                {tag}
                              </Tag>
                          </span>
                      })}
                      </Stack>
                    }
                  </Stack>
                </div>
              }
  
              <Checkbox
                label='Set a default country in the address field'
                checked={this.state.default_country_checkbox}
                onChange={(v) => { this.setState({default_country_checkbox: v})}}
              />
              {
                this.state.default_country_checkbox && 
                <div className="list-bordered-item" style={{marginLeft: "30px"}}>
                  <FormLayout>
                    <FormLayout.Group>
                      <Select
                        label="Select country"
                        value={this.state.default_country}
                        options={
                          this.state.specific_countries_checkbox && specific_countries && specific_countries.length > 0 ? 
                            all_countries_list.filter(c => specific_countries.includes(c.value)) 
                          : all_countries_list
                        }
                        onChange={(v) => {
                          this.setState({default_country:v})
                        }}
                      />
                      <div></div>
                      <div></div>
                    </FormLayout.Group>
                  </FormLayout>
                </div>
              }
  
              <Checkbox
                label='Redirect customer after Wholesale Signup Form is submitted'
                checked={this.state.customer_redirect_url_checkbox}
                onChange={(v) => { this.setState({customer_redirect_url_checkbox: v})}}
              />
              {
                this.state.customer_redirect_url_checkbox && 
                <div className="list-bordered-item" style={{marginLeft: "30px"}}>
                  <div style={{maxWidth: '70%'}}>
                      <TextField
                        inputMode="url"
                        type="url"
                        label="Redirect URL"
                        helpText="Customer will redirected to this url after form was submitted"
                        value={this.state.customer_redirect_url}
                        onChange={(v) => { this.setState({customer_redirect_url: v})}}
                      />
                  </div>
                </div>
              }
              
              </Stack>
          </Card.Section>
      </Card>
      <Card title="Customer Approval Process" actions={[{ content: "Learn More", url: "https://support.digitalcoo.com/hc/en-us/articles/360045747772", external: true }]}>
        <Card.Section>
          <Stack vertical spacing="tight">
            <RadioButton
              label="Review customer details before approving wholesale account"
              checked={this.state.approval_type === "manual"}
              id="manual"
              name="approval_type"
              onChange={(v,id)=>{
                this.setState({
                  approval_type:id,
                  email_to: this.state.approval_pending_email_to
                });
              }}
            />

{this.state.approval_type === "manual" && 
              <FormLayout>
                <FormLayout.Group>
                  
                  <div className="list-bordered-item" style={{marginLeft: "30px"}}>
                    <Stack vertical spacing="tight">
                      <Form onSubmit={() => {
                        this.addCustomerTags("customer_tags", "temp_customer_tags_value");
                      }}>
                        <FormLayout>
                          <FormLayout.Group>
                            <TextField
                              autoFocus={(this.state.approval_type === "auto" && this.state.customer_tags.length === 0)}
                              error={(this.state.approval_type === "auto" && this.state.customer_tags.length === 0)}
                              autoComplete={false}
                              label="Add tag when customer is approved"
                              placeholder="e.g wholesale"
                              value={temp_customer_tags_value}
                              onChange={ (v) => {
                                this.setState({temp_customer_tags_value:v});
                              }}
                              connectedRight={
                                <Button
                                    primary
                                    onClick={()=>{
                                        this.addCustomerTags("customer_tags", "temp_customer_tags_value");
                                    }}
                                >Add</Button>
                              }
                            />
                          </FormLayout.Group>
                        </FormLayout>
                        <small><TextStyle variation="subdued">Tags are case-insensitive. "wholesale" and "Wholesale" will have same meaning</TextStyle></small>
                      </Form>

                    
                      {
                        customer_tags && customer_tags.length > 0 &&
                        <Stack spacing="extraTight">
                        {this.renderTags()}
                        </Stack>
                      }
                    </Stack>
                  </div>
                  <div></div>
                </FormLayout.Group>
              </FormLayout>
            }



            <RadioButton
              label="Approve wholesale account without reviewing customer details"
              checked={this.state.approval_type === "auto"}
              id="auto"
              name="approval_type"
              onChange={(v,id)=>{
                this.setState({
                  approval_type:id,
                  email_to: this.state.auto_approved_email_to
                });
              }}
            />
            {this.state.approval_type === "auto" && 
              <FormLayout>
                <FormLayout.Group>
                  <div className="list-bordered-item" style={{marginLeft: "30px"}}>
                    <Stack vertical spacing="tight">
                      <Form onSubmit={() => {
                        this.addCustomerTags("customer_tags", "temp_customer_tags_value");
                      }}>
                        <FormLayout>
                          <FormLayout.Group>
                            <TextField
                              autoFocus={(this.state.approval_type === "auto" && this.state.customer_tags.length === 0)}
                              error={(this.state.approval_type === "auto" && this.state.customer_tags.length === 0)}
                              autoComplete={false}
                              label="Add tag when customer is approved"
                              placeholder="e.g wholesale"
                              value={temp_customer_tags_value}
                              onChange={ (v) => {
                                this.setState({temp_customer_tags_value:v});
                              }}
                              connectedRight={
                                <Button
                                    primary
                                    onClick={()=>{
                                        this.addCustomerTags("customer_tags", "temp_customer_tags_value");
                                    }}
                                >Add</Button>
                              }
                            />
                          </FormLayout.Group>
                        </FormLayout>
                        <small><TextStyle variation="subdued">Tags are case-insensitive. "wholesale" and "Wholesale" will have same meaning</TextStyle></small>
                      </Form>
                      {
                        customer_tags && customer_tags.length > 0 &&
                        <Stack spacing="extraTight">
                        {this.renderTags()}
                        </Stack>
                      }
                    </Stack>
                  </div>
                  <div></div>
                </FormLayout.Group>
              </FormLayout>
            }

          </Stack>
        </Card.Section>
      </Card>
    </div>;

    var opts = [{
        label: "Published",
        value: "published"
      },
      {
        label: "Unpublished",
        value: "unpublished"
      }
    ];

    // if(this.state.type !== "default"){
    //   opts.push({
    //     label: "Archived",
    //     value: "archived",
    //     disabled: this.state.type === "default"
    //   })
    // }

     const title_section = <div>
     <Card title="Title">
       <Card.Section>
         <Stack vertical>
           <TextField
             error={!title || title.trim() === ""}
             autoFocus={!title || title.trim() === ""}
             label="Title"
             labelHidden={true}
             placeholder="Wholesale signup Form"
             name="title"
             id="title"
             value={title}
             onChange={(v) => {this.setState({title:v})} }
             helpText="Write the title of your Customer Signup Form. This will not appear to the customers."
           />
           <TextField
             multiline={2}
             label="Welcome greeting text"
             placeholder="Write short description that will appear above the registration form"
             name="shop_info[greeting_message]"
             id="shop_info[greeting_message]"
             value={greeting_message}
             onChange={(v) => {this.setState({greeting_message:v})} }
             helpText="HTML accepted"
           />
           <Stack vertical spacing='extraTight'>
            <Stack>
              <Stack.Item fill>
                <TextStyle>Sign In text</TextStyle>
              </Stack.Item>
              {
                this.state.signin_text !== `Already have an account? <a href="/account/login">Login here</a>` ?
                <Stack.Item>
                  {
                    // eslint-disable-next-line
                  <a style={{textDecoration: "underline"}} href='' onClick={(e) => {
                    e.preventDefault();
                      this.setState({
                        signin_text: `Already have an account? <a href="/account/login">Login here</a>`
                      })
                  }}>(Reset to default)</a>
                  }
                </Stack.Item>
                : null
              } 
            </Stack>
             
           <TextField
             multiline={2}
             name="shop_info[signin_text]"
             id="shop_info[signin_text]"
             value={signin_text}
              onChange={(v) => {
                this.setState({signin_text:v})
              }}
             helpText="This will show below the greetings."
           />
           </Stack>
           <FormLayout>
              <FormLayout.Group>
                <TextField
                  label="Submit button label"
                  maxLength={150}
                  value={submit_button_text}
                  onChange={(v) => {this.setState({submit_button_text:v})} }
                />
                <div></div>
                <div></div>
              </FormLayout.Group>
            </FormLayout>

            {
             Common.getPlanID() === 3 && 
             <div>
               <FormLayout>
                 <FormLayout.Group>
                   <Select
                     label="Form status"
                     name="form-status"
                     id="form-status"
                     options={opts}
                     value={this.state.state}
                     onChange={(v) => {this.setState({state:v})} }
                   />
                   <div></div>
                   <div></div>
                 </FormLayout.Group>
               </FormLayout>
             </div>
            }
         </Stack>
       </Card.Section>
     </Card>
   </div>;

    const embed_code_section = <div>
      <Card
        title="Embed Customers Registration form to your website"
        actions={[
        { content: "How do I add this Wholesale Signup Form to my website?", url: "https://support.digitalcoo.com/hc/en-us/articles/360059825071", external: true }
      ]}
      >
        <Card.Section>
          <Stack vertical spacing="extraTight">
            <Stack.Item>
              <TextField
                multiline={2}
                label="Embed code"
                labelHidden={true}
                placeholder="Copy and paste this code into your website as html"
                name="embed_code"
                id="embed_code"
                value={this.generate_embed_code()}
                readOnly={true}
                onFocus={() => {
                  var doc = document.getElementById("embed_code");
                  try {
                    doc.select();
                  } catch (e) { }
                }}
                onChange={() => {
                }}
              />
            </Stack.Item>
            <Stack.Item>
              <Button
                primary
                onClick={() => {
                  var doc = document.getElementById("embed_code");
                  try {
                    doc.select();
                    document.execCommand("copy");
                    this.flashMessage("Copied to clipboard");
                  } catch (e) { }
                }}
              >Copy embed code</Button>
            </Stack.Item>
          </Stack>
        </Card.Section>
      </Card>
    </div>;

    const notification_section = <div>
      <Card title="Confirmation messages (visible after the form is submitted)">
        <Card.Section>
          <Stack vertical>
            <TextField
              multiline={2}
              label="Wholesale Signup Form confirmation message for Review & Approve Method"
              value={this.state.form_submited_message}
              id="form_submited_message"
              onChange={(v)=>{
                this.setState({form_submited_message:v})
              }}
            />
            <TextField
              multiline={2}
              label="Wholesale Signup Form confirmation message when already submitted account request"
              value={this.state.approval_pending_message}
              id="approval_pending_message"
              onChange={(v)=>{
                this.setState({approval_pending_message:v})
              }}
            />
            <TextField
              multiline={2}
              label="Wholesale Signup Form confirmation message when already have an active account"
              value={this.state.already_account_message}
              id="already_account_message"
              onChange={(v)=>{
                this.setState({already_account_message:v})
              }}
            />
            <TextField
              multiline={2}
              label="Wholesale Signup Form confirmation message for Auto Approve method"
              value={this.state.auto_approved_message}
              id="auto_approved_message"
              onChange={(v)=>{
                this.setState({auto_approved_message:v})
              }}
            />
          </Stack>
        </Card.Section>
      </Card>
    </div>;

    const email_notification_section = <div>
      <Card
        title="Email Notifications" actions={[{ content: "Emails are not being delivered? Learn More", url: "https://support.digitalcoo.com/hc/en-us/articles/360059997031", external: true }]}>
        <Card.Section>
          <Stack vertical>
          <Stack.Item>
            <FormLayout>
              <FormLayout.Group>
                <TextField
                  label="Sender Email Address"
                  helpText="We'll use this address to send email notifications to Merchant and customers"
                  value={this.state.email_from}
                  error = {(this.state.email_from && typeof this.state.email_from !== "undefined") ? this.state.email_from.trim().match(/^([A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,})$/g) === null ? "Please enter valid email address" : false : "Please enter valid email address" }
                  onChange={(email_from) => { 
                    this.setState({email_from});
                   }}
                />
                {
                  this.state.email_from.trim() === 'noreply@digitalcoo.com' && 
                  <TextField 
                    label="Reply To Email Address"
                    helpText="Customers will reply to this email address"
                    value={this.state.reply_to}
                    error = {(this.state.reply_to && typeof this.state.reply_to !== "undefined") ? this.state.reply_to.trim().match(/^([A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,})$/g) === null ? "Please enter valid email address" : false : "Please enter valid email address" }
                    onChange={(reply_to) => {
                      this.setState({reply_to});
                    }}
                  />
                }
                <TextField
                    label={<span>Email To {this.getChangeEmailLink()}</span>}
                    helpText="We'll send email notification to this address when someone registers."
                    value={this.state.email_to}
                    onChange={(email_to) => { this.setState({email_to}) }}
                    disabled={typeof this.props.match.params.id !== "undefined"}
                  />
              </FormLayout.Group>
            </FormLayout>
            </Stack.Item>
          </Stack>
        </Card.Section>
        <Card.Section>
          {this.state.approval_type === "manual" && 
            <div className="list-bordered-item">
              <Stack alignment="center">
                <Stack.Item fill>
                  <Checkbox
                    label="Send a confirmation email to the customer regarding their pending account approval"
                    helpText=""
                    checked={this.state.customer_notification_account_acknowledgment}
                    onChange={(v) => {
                      this.setState({customer_notification_account_acknowledgment: v})
                    }}
                  />
                </Stack.Item>
                <Stack.Item>
                  {
                    this.state.customer_notification_account_acknowledgment ?
                      this.props.show === "edit"?
                        <Button
                          size="slim"
                          primary
                          onClick={() => {
                            this.setState({showmodal_notification_account_acknowledgment_new_customer: true});
                          }}
                        >
                          Edit email template
                        </Button>
                      :<div></div>
                    : <Badge>Email notification off</Badge>
                  }
                </Stack.Item>
              </Stack>
            </div>
          }
          <div className="list-bordered-item">
            <Stack alignment="center">
              <Stack.Item fill>
                <Checkbox  
                  label="Send an email to Merchant when someone registers"
                  helpText=""
                  checked={this.state.owner_notification_new_account}
                  onChange={(v) => {
                    this.setState({owner_notification_new_account: v})
                  }}
                />
              </Stack.Item>
              <Stack.Item>
                {
                  this.state.owner_notification_new_account ?
                  this.props.show === "edit"?
                  <div>
                    {
                      this.state.approval_type === "auto" ? 
                      <Button
                        size="slim"
                        primary
                        onClick={() => {
                          this.setState({meoaana: true});
                        }}
                      >
                        Edit email template
                      </Button>
                      :
                      <Button
                        size="slim"
                        primary
                        onClick={() => {
                          this.setState({meona: true});
                        }}
                      >
                        Edit email template
                      </Button>
                    }
                  </div>
                  :<div></div>
                  : <Badge>Email notification off</Badge>
                }
              </Stack.Item>
            </Stack>
          </div>
          <div className="list-bordered-item">
            <Stack alignment="center">
              <Stack.Item fill>
                <Checkbox
                  label="Send a welcome email to the customer when they are approved"
                  helpText=""
                  checked={this.state.notification_account_approved_old_customer}
                  onChange={(v) => {
                    this.setState({notification_account_approved_old_customer: v})
                  }}
                />
              </Stack.Item>
              <Stack.Item>
                {
                  this.state.notification_account_approved_old_customer ?
                    this.props.show === "edit"?
                      <Button
                        size="slim"
                        primary
                        onClick={() => {
                          this.setState({mnaaoc: true});
                        }}
                      >
                        Edit email template
                      </Button>
                    :<div></div>
                  : <Badge>Email notification off</Badge>
                }
              </Stack.Item>
            </Stack>
          </div>
          {/* <div className="list-bordered-item">
            <Stack alignment="center">
              <Stack.Item fill>
                <Checkbox
                  label="Notify requester when wholesale account opening request is approved"
                  helpText="Email template for those customers who don't have an account previously."
                  checked={this.state.notification_account_approved_new_customer}
                  onChange={(v) => {
                    this.setState({notification_account_approved_new_customer: v})
                  }}
                />
              </Stack.Item>
              <Stack.Item>
                {
                  this.state.notification_account_approved_new_customer ?
                    this.props.show === "edit"?
                      <Button
                        size="slim"
                        primary
                        onClick={() => {
                          this.setState({mnaanc: true});
                        }}
                      >
                        Edit email template
                      </Button>
                    :<div></div>
                  : <Badge>Email notification off</Badge>
                }
              </Stack.Item>
            </Stack>
          </div> */}
          
          <div className="list-bordered-item">
            <Stack alignment="center">
              <Stack.Item fill>
                <Checkbox
                  label="Send a rejection email to customer if you choose to reject the customer"
                  helpText=""
                  checked={this.state.customer_notification_account_rejected}
                  onChange={(v) => {
                    this.setState({customer_notification_account_rejected: v})
                  }}
                />
              </Stack.Item>
              <Stack.Item>
                {
                  this.state.customer_notification_account_rejected ?
                    this.props.show === "edit"?
                      <Button
                        size="slim"
                        primary
                        onClick={() => {
                          this.setState({mcnar: true});
                        }}
                      >
                        Edit email template
                      </Button>
                    :<div></div>
                  : <Badge>Email notification off</Badge>
                }
              </Stack.Item>
            </Stack>
            </div>
        </Card.Section>
      </Card>
    </div>;

    var page_action_sec_btns = [
      {
        content: 'Cancel',
        url: BUILD_VERSION_EXTENSION+"/ws-customers"
      }
    ];

    if(this.state.type !== "default" && this.state.edit_id && Number(this.state.usage) < 1){
      page_action_sec_btns.push({
        destructive: true,
        content: 'Delete',
        onAction: () => { this.delete_rule() }
      });
    }

    const modals = <div>
      <Modal
        title="Email template to send an email to Merchant when someone registers"
        src={BUILD_VERSION_EXTENSION+"/ws-forms/email-template/1/"+this.state.uid}
        size="Large"
        open={this.state.meona}
        onClose={() => { this.setState({meona: false })}}
      ></Modal>
      <Modal
        title="Email template to send an email to Merchant when someone registers"
        src={BUILD_VERSION_EXTENSION+"/ws-forms/email-template/5/"+this.state.uid}
        size="Large"
        open={this.state.meoaana}
        onClose={() => { this.setState({meoaana: false })}}
      ></Modal>
      <Modal
        title="Email template to send a welcome email to the customer when they are approved"
        src={BUILD_VERSION_EXTENSION+"/ws-forms/email-template/2/"+this.state.uid}
        size="Large"
        open={this.state.mnaaoc}
        onClose={() => { this.setState({mnaaoc: false })}}
      ></Modal>
      <Modal
        title="Email Template for those customers who don't have an account previously."
        src={BUILD_VERSION_EXTENSION+"/ws-forms/email-template/3/"+this.state.uid}
        size="Large"
        open={this.state.mnaanc}
        onClose={() => { this.setState({mnaanc: false })}}
      ></Modal>
      <Modal
        title="Email template to send a rejection email to customer if you choose to reject the customer"
        src={BUILD_VERSION_EXTENSION+"/ws-forms/email-template/4/"+this.state.uid}
        size="Large"
        open={this.state.mcnar}
        onClose={() => { this.setState({mcnar: false })}}
      ></Modal>
      <Modal
        title="Email template to send an acknowledgment email to customer"
        src={BUILD_VERSION_EXTENSION+"/ws-forms/email-template/6/"+this.state.uid}
        size="Large"
        open={this.state.showmodal_notification_account_acknowledgment_new_customer}
        onClose={() => { this.setState({showmodal_notification_account_acknowledgment_new_customer: false })}}
      ></Modal>
    </div>;
    if(this.state.page_loaded){
      const changes_detected = this.detectChanges();
      return(
        <div>
          {modals}{toast}
          <TitleBar
            title="Create Wholesale Form"
            primaryAction={changes_detected ? {
              content: "Save",
              onAction: this.save,
              disabled: this.state.btn_loading
            } : null}
            secondaryActions={[{
              content: "Cancel",
              onAction: ()=>{
                if(this.detectChanges()) {
                  const result = window.confirm("You have unsaved changes that will be lost if you decide to continue.");
                  if (result) {
                    window.location.pathname = BUILD_VERSION_EXTENSION+"/ws-customers";
                  }
                } else window.location.pathname = BUILD_VERSION_EXTENSION+"/ws-customers";
              },
              target: "APP"
            }]}
          />
            <Layout>
              {
                this.props.show === "edit" &&
                <Layout.Section>
                  {embed_code_section}
                </Layout.Section>
              }
              <Layout.Section>
                {title_section}
              </Layout.Section>
              <Layout.Section>
                <Card title="Wholesale Signup Form Builder" sectioned>
                  {form_builder_section}
                </Card>
              </Layout.Section>
              <Layout.Section>
                {form_settings_section}
              </Layout.Section>
              <Layout.Section>
                {notification_section}
              </Layout.Section>
              <Layout.Section>
                {email_notification_section}
              </Layout.Section>
              <Layout.Section>
              <PageActions
                  primaryAction={changes_detected ? {
                    content: "Save",
                    onAction: this.save,
                    disabled: this.state.btn_loading
                  } : null}
                  secondaryActions={page_action_sec_btns}
                />
              </Layout.Section>
            </Layout>
            {
              this.state.btn_loading &&
              <LoadingOverlay
                message="Saving Wholesale Signup form. Please wait..."
              />
            }
        </div>
      )
    }
    else{
      return(
        <div>
          <SkeletonPageComp></SkeletonPageComp>
        </div>
      );
    }
  }

}

export default CreateWSForm;