import React from 'react';
import {Toast} from '@shopify/app-bridge-react';
class ToastMessage extends React.Component {
  render() {
    return (
      <Toast
        error={this.props.error||false}
        content={this.props.message||"NA"}
        onDismiss={this.props.onDismiss}
      />
    );
  }
}
export default ToastMessage;