import React, { Component } from 'react';
import { ResourceList,EmptyState,Layout,Avatar,ResourceItem,Card,TextStyle,Badge, Link, FooterHelp } from '@shopify/polaris';
import { TitleBar, ToastMessage } from '@shopify/app-bridge-react';
import SkeletonPageComp from './SkeletonPageComp';
import ManageCustomerButtons from './ManageCustomerButtons';
import moment from "moment";
import Common from '../Helpers/Common';
import axios from '../Axios';
const BUILD_VERSION_EXTENSION =  process.env.REACT_APP_BUILD_VERSION_EXTENSION;

class ManageCustomerList extends Component{
  constructor(props){
    super(props);
    this.state = {
      page_loaded: false,
      rules: [],
      showToast: false,
      toastMsg: "",
      toastError: false
    };
  }

  componentDidMount() {
    this.getAllRules();
  }

  getAllRules(){
    if(this.props.archived){
      axios.get("/app/v2/ws-forms?status=archived&shop="+this.props.shop).then(response => {
        if(response && response.data && response.data.length > 0){
          this.setState({ rules: response.data });
        }
        this.setState({ page_loaded: true });
      }).catch( error => {
        this.setState({ page_loaded: true });
      });
    }
    else{
      axios.get("/app/v2/ws-forms?shop="+this.props.shop).then(response => {
        if(response && response.data && response.data.length > 0){
          this.setState({ rules: response.data });
        }
        this.setState({ page_loaded: true });
      }).catch( error => {
        this.setState({ page_loaded: true });
      });
    }
  }

  showToastMessage(msg, error){
    this.setState({
      showToast: true,
      toastMsg: msg,
      toastError: error||false,
    });
  }

  dissmissToast = () =>{
    this.setState({
      showToast: false,
    });
  }

  getStatus(state){
    if(state === "published"){
      return <Badge status="success">Published</Badge>;
    }
    else if(state === "archived"){
      return <Badge status="info">Archived</Badge>;
    }
    else if(state === "unpublished"){
      return <Badge>Unpublished</Badge>;
    }
    else{
      return null;
    }
  }

  render(){

    var toast = this.state.showToast? (
      <ToastMessage
        onDismiss={this.dissmissToast}
        message={this.state.toastMsg}
        error={this.state.toastError}
      />
    ): (null);

    var create_btns = {
      content: "Create Wholesale Signup Form",
      url: BUILD_VERSION_EXTENSION+"/ws-customers/ws-form/create"
    };

    if(Common.getPlanID() === 1 && this.state.rules.length >= 1){
      create_btns = null;
    }
    if(Common.getPlanID() === 2 && this.state.rules.length >= 1){
      create_btns = null;
    }
    if(Common.getPlanID() === 3 && this.state.rules.length >= 10){
      create_btns = null;
    }

    if(this.state.page_loaded){
      return(
        <div>
          <TitleBar
            title="Manage Customers"
            breadcrumbs={[
              { content: 'Dashboard', url: BUILD_VERSION_EXTENSION+'/dashboard', target: 'APP' }
            ]}
            primaryAction={create_btns}
          />
          {toast}
          <Layout>
            <Layout.Section>
              <ManageCustomerButtons
                archived={this.props.archived}
                shop={this.props.shop}
              />
            </Layout.Section>
            <Layout.Section>
              <Card>
                <ResourceList
                  showHeader={true}
                  emptyState={<EmptyState
                    heading="Create a Wholesale Signup Form to get started"
                    action={create_btns}
                    image="https://cdn.shopifycloud.com/web/assets/6abcecfa5637ef0dced77481e9c7069e.svg"
                  >
                    <p>
                      You can create Wholesale Signup Form and embed it to your website.
                    </p>
                  </EmptyState>}
                  items={this.state.rules}
                  renderItem={(item) => {
                    const { _id, uid, usage, state, updated_at, created_at, __v, type } = item;
                    var title = item.title || "No title";
                    const url = BUILD_VERSION_EXTENSION+"/ws-customers/ws-form/"+_id;
                    var init = usage;
                    try {
                      init = init.toString();
                    } catch (e) { }
                    var updated = updated_at;
                    var created = created_at;
                    try {
                      updated = moment(updated_at).fromNow();
                    } catch (error) { }
                    try {
                      created = moment(created_at).fromNow();
                    } catch (error) { }
                    var media = <Avatar initials={init} name={title} accessibilityLabel={init+" times used"}></Avatar>
                    return (
                      <ResourceItem
                        id={_id}
                        url={url}
                        accessibilityLabel={`View details for ${title}`}
                        media={media}
                        shortcutActions={[
                          {
                            content: 'Customize',
                            accessibilityLabel: `Customize rule ${title}`,
                            url: url
                          }
                        ]}
                      >
                        <h3>
                          <TextStyle variation="strong">{title} {type === "default" && <Badge>Default</Badge>}</TextStyle>
                        </h3>
                        <div>
                          Form ID: {uid} {this.getStatus(state)}
                        </div>
                        <div>
                          {
                            Number(__v) > 0 ?
                            <span>
                              Last updated: {updated}
                              <span accessibilityLabel={"This rule was edited "+__v+" times"} style={{display: "none"}}>This rule was edited {__v} times</span>
                            </span>
                            :<span>
                              Created: {created}
                            </span>
                          }
                        </div>
                      </ResourceItem>
                    );
                  }}
                  resourceName={{singular: 'form', plural: 'forms'}}
                />
              </Card>
            </Layout.Section>
            <Layout.Section>
              <FooterHelp>
                <Link url="https://support.digitalcoo.com/hc/en-us/articles/7284251648788" external>
                  Frequently asked question
                </Link>
              </FooterHelp>
            </Layout.Section>
          </Layout>
        </div>
      )
    }
    else{
      return(
        <div>
          {toast}
          <SkeletonPageComp></SkeletonPageComp>
        </div>
      );
    }
  }
}

export default ManageCustomerList;