import React, { Component } from 'react';
import { Layout, Card, TextStyle, Link, RadioButton, Stack, Banner, Button } from '@shopify/polaris';
import axios from 'axios';
import ToastMessage from '../Common/ToastMessage';

const HOST_NAME = "https://wsaioheavyload.digitalcoo.com";
export default class ImportRegularDiscount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      shop: this.props.shop,
      upload_file_btn_disable: true,
      upload_file_btn_loading: false,
      showToast: false,
      toastMsg: '',
      toastError: false,
      selection_mode: "individual_products",
      import_option_for_collection: importCollection(this.props.shopData),
      success_banner: false,
      error_banner: false,
      import_errors_list: []
    }
  }
  showToastMessage(msg, error){
    this.setState({
      showToast: true,
      toastMsg: msg,
      toastError: error||false,
    });
  }

  dissmissToast = () =>{
    this.setState({
      showToast: false,
    });
  }

  handleFileSelect = (e) => {
    this.setState({upload_file_btn_disable: false});
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    // HTML file input, chosen by user
    this.setState({upload_file_btn_disable: true, upload_file_btn_loading: true});
    var $form = document.getElementById("form-upload-files");
    var data = new FormData($form);
    if(this.state.selection_mode === "individual_products"){
      axios.post(HOST_NAME+'/ie/import/regular-discount?shop='+this.state.shop, data)
      .then( response => {
        if(response.data.status === 1){
          this.showToastMessage(response.data.message);
          this.setState({success_banner: true, error_banner: false, upload_file_btn_loading: false });
        }
        else{
          this.showToastMessage("CSV file import failed", true);
          this.setState({upload_file_btn_disable: false, upload_file_btn_loading: false, success_banner: false, error_banner: true});

          if(response.data.import_errors){
            if(response.data.import_errors.length > 0){
              this.setState({import_errors_list: response.data.import_errors});
            }
          }
        }
      })
      .catch(error => {
        this.setState({upload_file_btn_disable: false, upload_file_btn_loading: false, success_banner: false, error_banner: true});
      });
    }
    else{
      axios.post(HOST_NAME+'/ie/import/regular-discount/collections?shop='+this.state.shop, data)
      .then( response => {
        if(response.data.status === 1){
          this.showToastMessage(response.data.message);
          this.setState({success_banner: true, error_banner: false, upload_file_btn_loading: false });
        }
        else{
          this.showToastMessage(response.data.message, true);
          this.setState({upload_file_btn_disable: false, upload_file_btn_loading: false, success_banner: false, error_banner: true});
        }
      })
      .catch(error => {
        this.setState({upload_file_btn_disable: false, upload_file_btn_loading: false, success_banner: false, error_banner: true});
      });
    }
  }

  render() {
    const {import_option_for_collection} = this.state;
    var toast = this.state.showToast? (
      <ToastMessage
      onDismiss={this.dissmissToast}
      message={this.state.toastMsg}
      error={this.state.toastError}
      />
    ): (null);
    return (
      <Layout>
        <Layout.Section>
          {toast}
          {
            this.state.import_errors_list.length > 0 &&
            <Card>
              <Card.Section>
                <Stack>
                  <Stack.Item fill>
                  </Stack.Item>
                  <Stack.Item>
                <Button 
                primary
                onClick={() => 
                  { 
                    this.setState({
                      import_errors_list: []
                    })
                  }}
                >
                        Retry Import
                </Button>
                  </Stack.Item>
                  <Stack.Item fill>
                  </Stack.Item>
                </Stack>
              </Card.Section>
              {
                this.state.error_banner && <Card.Section>
                  <Banner status="critical" title="Import Errors">
                    {
                      this.state.import_errors_list.length > 0 &&
                      <p>
                        Unable to upload CSV File, please follow the CSV format from the article <Link external url = "https://support.digitalcoo.com/hc/en-us/articles/360054559691-Bulk-Import-export-Discount-Rules">(Link)</Link> and retry import, or contact us at support@digitalcoo.com<br/>Errors:
                        {this.state.import_errors_list.map((import_error, index) => (
                          <li key={import_error}>{import_error}</li>
                        ))}
                      </p>
                    }
                  </Banner>
                </Card.Section>
              }
            </Card>
            
          }
          {
            this.state.import_errors_list.length < 1 &&
          <Card
            primaryFooterAction={
              {
                content: 'Upload and import',
                onAction: this.handleFormSubmit,
                disabled: this.state.upload_file_btn_disable,
                loading: this.state.upload_file_btn_loading
              }
            }
          >
            {
              import_option_for_collection && 
              <Card.Section>
                <Stack vertical spacing="extraTight">
                  <RadioButton
                    label="Import indivial products level discount rules"
                    onChange={(v) => {
                      this.setState({selection_mode: "individual_products"})
                    }}
                    checked={this.state.selection_mode === "individual_products"}
                  />
                  <RadioButton
                    label="Import collections level discount rules"
                    onChange={(v) => {
                      this.setState({selection_mode: "collection"})
                    }}
                    checked={this.state.selection_mode === "collection"}
                  />
                </Stack>
              </Card.Section>
            }
            {
              import_option_for_collection ?
              this.state.selection_mode === "collection"?
              <Card.Section>
                <div>
                  <form id="form-upload-files" encType="multipart/form-data" action={HOST_NAME+"/ie/import/volume-discount?shop="+this.props.shop} method="post" onSubmit={this.handleFormSubmit}>
                  <input type="file" name="file" accept=".csv" onChange={this.handleFileSelect} className="choose--csv-file" multiple aria-multiline="true"></input>
                  </form>
                </div>
                <div style={{marginTop:'2rem'}}>
                  <TextStyle variation="subdued">
                    Download a <Link url="https://videolink.click/tutorialvideos/regular-discount-link1.csv" external={true}>sample CSV template</Link> to see an example of the format required.
                  </TextStyle>
                </div>
              </Card.Section>
              :
              <Card.Section>
                <div>
                  <form id="form-upload-files" encType="multipart/form-data" action={HOST_NAME+"/ie/import/volume-discount?shop="+this.props.shop} method="post" onSubmit={this.handleFormSubmit}>
                  <input type="file" name="file" accept=".csv" onChange={this.handleFileSelect} className="choose--csv-file" multiple aria-multiline="true"></input>
                  </form>
                </div>
                <div style={{marginTop:'2rem'}}>
                  <TextStyle variation="subdued">
                    Download a <Link url="https://videolink.click/tutorialvideos/regular-discount-link2.csv" external={true}>sample CSV template</Link> to see an example of the format required.
                  </TextStyle>
                </div>
              </Card.Section>
              : 
              <Card.Section>
                <div>
                  <form id="form-upload-files" encType="multipart/form-data" action={HOST_NAME+"/ie/import/volume-discount?shop="+this.props.shop} method="post" onSubmit={this.handleFormSubmit}>
                  <input type="file" name="file" accept=".csv" onChange={this.handleFileSelect} className="choose--csv-file" multiple aria-multiline="true"></input>
                  </form>
                </div>
                <div style={{marginTop:'2rem'}}>
                  <TextStyle variation="subdued">
                    Download a <Link url="https://videolink.click/tutorialvideos/regular-discount-link3.csv" external={true}>sample CSV template</Link> to see an example of the format required.
                  </TextStyle>
                </div>
              </Card.Section>
            }
            {
              this.state.success_banner && <Card.Section>
                <Banner status="success">
                  <p>Rule imported successfully.</p>
                </Banner>
              </Card.Section>
            }
          </Card>
          }
        </Layout.Section>
      </Layout>
    );
  }
}
function importCollection(data) {
  var im = false;
  if(data && data.shop_features){
    try {
      im = data.shop_features.findIndex(x => x === "import_export_collection") > -1;
    } catch (e) {}
  }
  return im;
}