import store from 'store2';
function isPositiveInteger(r){return/^\d+$/.test(r)}
var waio = {
    compareVersionNumbers: function(r,t){var e=r.split("."),n=t.split(".");function i(r){for(var t=0;t<r.length;++t)if(!isPositiveInteger(r[t]))return!1;return!0}if(!i(e)||!i(n))return NaN;for(var u=0;u<e.length;++u){if(n.length===u)return 1;if(e[u]!==n[u])return e[u]>n[u]?1:-1}return e.length!==n.length?-1:0}
}

function striptHTML(html) {
    var txt = html;
    try {
        var div = document.createElement("div");
        div.innerHTML = html;
        txt = div.textContent || div.innerText || "";
    } catch (e) { }
    return txt;
}

function getMF() {
    var mf = store("ws_money_format") || '$ {{amount}}';
    if(!mf){
        mf =  '$ {{amount}}';
    }
    return striptHTML(mf);
}

waio.Currency = (function () {
    var moneyFormat = getMF();
    function format_money(cents, format) {
        if (typeof cents === 'string') {
            cents = cents.replace('.', '');
        }
        var value = '';
        var placeholderRegex = /\{\{\s*(\w+)\s*\}\}/;
        var formatString = format || moneyFormat;
        function formatWithDelimiters(number, precision, thousands, decimal) {
            thousands = thousands || ',';
            decimal = decimal || '.';
            if (isNaN(number) || number === null) {
                return 0;
            }
            number = (number / 100.0).toFixed(precision);
            var parts = number.split('.');
            var dollarsAmount = parts[0].replace(/(d)(?=(ddd)+(?!d))/g, '$1' + thousands);
            var centsAmount = parts[1] ? decimal + parts[1] : '';
            return dollarsAmount + centsAmount;
        }
        switch(formatString.match(placeholderRegex)[1]){case "amount":value=formatWithDelimiters(cents,2);break;case "amount_no_decimals":value=formatWithDelimiters(cents,0);break;case "amount_with_comma_separator":value=formatWithDelimiters(cents,2,".",",");break;case "amount_no_decimals_with_comma_separator":value=formatWithDelimiters(cents,0,".",",");break;case "amount_no_decimals_with_space_separator":value=formatWithDelimiters(cents,0," ");break;case "amount_with_apostrophe_separator":value=formatWithDelimiters(cents,2,"'");break;default:value=formatWithDelimiters(cents,2);break};
        return formatString.replace(placeholderRegex, value);
    }
    return {
        format_money: format_money
    };
})();

export default waio;