import React from 'react';
import {TitleBar} from '@shopify/app-bridge-react';
class PageTitleBar extends React.Component {  
  render() {
    return (
        <TitleBar
          title={this.props.title||""}
          primaryAction={this.props.primaryAction||null}
          breadcrumbs={this.props.breadcrumbs||[]}
          secondaryActions={this.props.secondaryActions||[]}
          actionGroups={this.props.actionGroups||[]}
        />
    );
  }
}

export default PageTitleBar;