import React, { Component } from 'react';
import {
  Card,ResourceList,TextStyle,Button,Layout,Badge,ButtonGroup,Pagination,ResourceItem,Link,Icon,FormLayout,TextField,Banner
} from '@shopify/polaris';
import FooterHelpLinks from './FooterHelpLinks';
import ToastMessage from '../Common/ToastMessage';
import PageTitleBar from '../Common/TitleBar';
import SkeletonPageComp from './SkeletonPageComp';
import {Modal} from '@shopify/app-bridge-react';
import Common from '../Helpers/Common';
import { DeleteMinor, SearchMinor } from '@shopify/polaris-icons';
import axios from '../Axios';
const BUILD_VERSION_EXTENSION =  process.env.REACT_APP_BUILD_VERSION_EXTENSION;

var interval = 0;
var request_cancel = null;
export default class VolumeDiscountList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: [],
      qb_data: [],
      filter_qb_data: [],
      searchValue: '',
      appliedFilters: [],
      shop: this.props.shop,
      page_loaded: false,
      est_time: undefined,
      selected_quantity_breaks: [],
      selected_quantity_breaks_keys: [],
      enable_btn_loading: false,
      disable_btn_loading: false,
      delete_btn_loading: false,
      loading: false,
      import_qb_modal: false,
      sort_by: 'DATE_CREATED_DESC',
      pagination_has_next: false,
      pagination_has_previous: false,
      totalPages: 1,
      pageNumber: 1,
      showToast: false,
      toastError: false,
      toastMsg: "",
      query: "",
      export_error: false,
      export_errors_list: []
    };
    axios.defaults.headers.common['X-Ws-Shop-Domain'] = "https://"+this.props.shop;
    this.handleSortChange = this.handleSortChange.bind(this);
  }
  showToastMessage(msg, error){
    this.setState({
      showToast: true,
      toastMsg: msg,
      toastError: error||false,
    });
  }
  
  dissmissToast = () =>{
    this.setState({
      showToast: false,
    });
  }
  handleSortChange(sort_by) {
    this.setState({ sort_by });
    this.fetch_all_qb(sort_by);
  }
  handleSelectionChange = (selectedItems) => {
    var temp_items = [];
    var temp_keys = [];
    if(selectedItems.length > 0){
      selectedItems.forEach(x=>{
        temp_items.push(this.state.qb_data[x]._id);
        let keys = this.state.qb_data[x].meta_keys;
        try{
          keys = keys.split(",");
        }catch(e){}
        temp_keys = temp_keys.concat(keys);
      })
    }
    this.setState({
      selectedItems:selectedItems,
      selected_quantity_breaks: temp_items,
      selected_quantity_breaks_keys: temp_keys
    });
    this.fetch_est_time();
  };
  renderItem = (item,id) => {
    const{_id,title,tags,state}=item;
    var url = BUILD_VERSION_EXTENSION+'/volume-discount/edit/'+_id;
    var actionButtons = [{
      content: 'Edit', target:'APP' ,url: BUILD_VERSION_EXTENSION+'/volume-discount/edit/'+_id
    }];
    if(state === 'published'){
      if(Common.getPlanID() !== 1){
        actionButtons.push({
          content: 'Unpublish', disabled:Common.getPlanID() === 1, onAction: () =>{ this.change_quantity_break(item._id,"unpublished");}
        });
      }
    }
    else{
      if(Common.getPlanID() !== 1){
        actionButtons.push({
          content: 'Publish', disabled:Common.getPlanID() === 1, onAction: () =>{ this.change_quantity_break(item._id,"published"); }
        });
      }
    }

    if(item.individual_product === true &&  item.applies_to === "products"){
      actionButtons.push({
        content: "Export",
        onAction: () => {
          axios.get("https://wsaioheavyload.digitalcoo.com/ie/export/csv/volume-discount?shop="+this.props.shop+"&type=bulk&id="+item._id)
          .then(response => {
            if(response.data.error && typeof response.data.error !== "undefined"){
              this.setState({
                export_error: true,
                export_errors_list: response.data.error
              })
            }
            else {
              this.setState({
                export_error: false,
                export_errors_list: []
              })
              const link = document.createElement('a');
              link.href = window.URL.createObjectURL(new Blob([response.data.csv]));
              link.setAttribute('download', response.data.file_name);
              document.body.appendChild(link);
              link.click();
            }
            
          })
          .catch(error => {
            console.log(error);
          });
      }
      })
    }

    if(item.applies_to === "collections"){
      actionButtons.push({
        content: "Export",
        onAction: () => {
          window.top.location.href = "https://wsaioheavyload.digitalcoo.com/ie/export/csv/volume-discount/export-collections?shop="+this.props.shop+"&type=bulk&id="+item._id; 
        }
      })
    }
    actionButtons.push({
      icon:  DeleteMinor, onAction: ()=>{ this.delete_qb(item)}
    })

    const mainTitleSection = (
      <div className="CustomerListItem__Profile ">
        <h3 className="CustomerListItem__Title">
        <TextStyle variation="strong">{
            <span>
              { title || "No title" } { item.channel === "import" && <Badge>via Import</Badge> }
            </span>
          }</TextStyle>
        </h3>
        <div className="CustomerListItem__Location">
            <TextStyle variation="subdued">
              { this.getAppliesToSection(item) }
            </TextStyle>
        </div>
        <div className="CustomerListItem__Location">
          <span>
            <TextStyle variation="subdued">
              {this.individual_product_tags(tags,item)}
            </TextStyle>
          </span>
        </div>
      </div>
    );

    const contentSection = (
      <div className="CustomerListItem__Status">
        <div className="CustomerListItem__StatusBadge">
          {
            this.require_upgrade_plan()?
            <Badge status='warning'>
            <Link className="no_link_style" url={BUILD_VERSION_EXTENSION+'/pricing-plans'}>Upgrade plan</Link>
            </Badge>
            :
            item.schedule_active ?
            this.schedule_ends_in(item,id)
            :item.state === 'published'?
            <Badge status="success"> Published </Badge>:
            item.state==='unpublished'?<Badge> Unpublished </Badge>
            :null
          }
        </div>
      </div>
    );
    return (
      <ResourceItem 
        id={id}
        url={url}
        shortcutActions={actionButtons}
        verticalAlignment="center"
      >
        <div className="CustomerListItem__Main">
          {mainTitleSection}
          {contentSection}
        </div>
      </ResourceItem>
    );
  };

  getLength(items){
    var a = [];
    if(items && items.length){
      items.forEach(function(item){
        if(a.findIndex(x => x === item.handle) === -1){
          a.push(item.handle);
        }
      })
    }
    return a.length;
  }

  getAppliesToSection(item){
    var _html = '';
    if(item){
      if(item.applies_to === "products"){
        var _leng = this.getLength(item.products);
        if(item.individual_product === true){
          _html += (_leng > 1?"Individual products":"Individual product");
        }
        else{
          _html += (_leng > 1?"Products":"Product");
        }
        _html += " ("+(_leng)+")";
      }
      else if(item.applies_to === "collections"){
        _html += (item.collections.length > 1?"Collections":"Collection");
        _html += " ("+(item.collections.length)+")";
      }
      else if(item.applies_to === "entire_store"){
        _html = "Entire store";
      }
      // waio.Currency.format_money
      //price_discount
      //fixed_price
    }
    return _html;
  }

  componentDidMount() {
    this.clearAllInterval(interval);
    this.fetch_all_qb(this.state.sort_by);
  }

  componentWillUnmount() {
    this.clearAllInterval(interval);
  }

  componentDidCatch(){
    this.clearAllInterval(interval);
  }

  clearAllInterval = (interval) =>{
    for (var i = 1; i <= interval; i++){
      clearInterval(i);
    }
  };

  fetch_all_qb(sort_by, page_request, search_query){
    
    if(request_cancel){
      // Cancel request
      try {
        request_cancel.cancel();
      } catch (e) {}
    }
    
    const cancelTokenSource = axios.CancelToken.source();
    request_cancel = cancelTokenSource;

    this.clearAllInterval(interval);
    let pageNumber = this.state.pageNumber || 1;
    if(page_request === "prev"){
      pageNumber = Number(pageNumber)-1;
      this.setState({loading: true});
    }
    else if(page_request === "next"){
      pageNumber = Number(pageNumber)+1;
      this.setState({loading: true});
    }
    var url = '/app/v238/volume-discounts?shop='+this.props.shop;
    if(sort_by){
      url += "&sort_by="+sort_by;
    }
    if(pageNumber){
      url += "&page="+pageNumber;
    }
    if(search_query){
      url += "&search_query="+search_query;
      this.setState({loading: true});
    }
    axios.get(url, {
      cancelToken: cancelTokenSource.token
    }).then(response => {
      var data = response.data.vd || [];
      var count = response.data.count || 0;
      var totalPages = Math.ceil(Number(count)/30);
      var pagination_has_next = false;
      var pagination_has_previous = false;
      if( Number(Number(pageNumber)*30) < Number(count)){
        pagination_has_next = true;
      }
      if(Number(pageNumber) > 1){
        pagination_has_previous = true;
      }
      this.setState({
          pageNumber: pageNumber,
          totalPages: totalPages,
          pagination_has_next: pagination_has_next,
          pagination_has_previous: pagination_has_previous,
          page_loaded: true,
          qb_data: data,
          loading: false,
          filter_qb_data: data,
          selectedItems: []
      });
      this.fetch_est_time();
    })
    .catch(error => {});
  }

  fetch_est_time(){
    this.clearAllInterval(interval);
    axios.get('/est-time-now/v2')
    .then(time=>{
      this.clearAllInterval(interval);
      this.setState({est_time:time.data.current_est_time});
    }).catch(err=>{
    });
  }

  delete_qb(item){
    if(window.confirm("Delete Volume Pricing?")){
      const newData = this.state.qb_data.filter(i => i._id !== item._id);
      this.setState({qb_data:newData,loading:false});
      this.setState({loading:true});
      this.fetch_est_time();
      if(item.metafield_ids){
        axios.delete('/app/v238/volume-discount?id='+item._id+'&shop='+this.props.shop+'&metafield_ids='+item.metafield_ids)
        .then( response => {
            if(response.data.status === 1){
              try{ 
                // Trigger event to reload auto installation banner
                document.dispatchEvent(new CustomEvent("wsaio-reload-auto-installation-banner"));
              } catch(e){ }
              
              this.showToastMessage(response.data.message);
              this.fetch_all_qb(this.state.sort_by);
            }
            else{
              this.setState({loading:false});
              this.showToastMessage(response.data.message, true);
              this.fetch_est_time();
            }
            this.setState({selectedItems: []});
          }
        ).catch(err => {
          this.setState({loading:true});
          this.fetch_est_time();
        });
      }
      else{
        axios.delete('/app/v238/volume-discount?id='+item._id+'&shop='+this.props.shop+'&keys='+item.meta_keys)
        .then( response => {
            if(response.data.status === 1){
              this.showToastMessage(response.data.message);
              this.fetch_all_qb(this.state.sort_by);
            }
            else{
              this.setState({loading:false});
              this.showToastMessage(response.data.message, true);
              this.fetch_est_time();
            }
            this.setState({selectedItems: []});
          }
        ).catch(err => {
          this.setState({loading:true});
          this.fetch_est_time();
        });
      }
    }
  }

  change_quantity_break(_id, state){
      this.setState({loading:true});
      this.fetch_est_time();
      var status = state || "draft";
      var body = {
        shop: this.props.shop,
        status: status
      };
      axios.put('/app/v238/volume-discount/status?id='+_id+"&status="+status, body).then( response => {
          if(response.data.status === 1){
            try{ 
              // Trigger event to reload auto installation banner
              document.dispatchEvent(new CustomEvent("wsaio-reload-auto-installation-banner"));
            } catch(e){ }

            this.showToastMessage(response.data.message);
            this.fetch_all_qb(this.state.sort_by);
          }else{
            this.setState({loading:false});
            this.fetch_est_time();
            this.showToastMessage(response.data.message, true);
          }
          this.setState({selectedItems: []});
        }
      ).catch(err => {
        this.setState({loading:false});
        this.fetch_est_time();
        this.showToastMessage("Error", true);
      });
  }

  getTimezoneOffset() {
    try{
      function z(n){return (n<10? '0' : '') + n}
      var offset = new Date().getTimezoneOffset();
      var sign = offset < 0? '+' : '-';
      offset = Math.abs(offset);
      return sign + z(offset/60 | 0) + z(offset%60);
    }catch(err){}
  }

  get_est_dateTime(date,time){
    try{
      let dateTimeValue = new Date(date);
      if(typeof dateTimeValue === 'object'){
        dateTimeValue = dateTimeValue.toString();
        if(typeof dateTimeValue === 'string'){
          dateTimeValue = dateTimeValue.replace(this.getTimezoneOffset(), '+0000');
        }
      }
      dateTimeValue = new Date(dateTimeValue).toISOString();
      dateTimeValue = dateTimeValue.split('T')[0] + " " +time;
      return dateTimeValue;
    }catch(err){}
  }

  schedule_ends_in(item){
    //let clockId = 'id'+counter;
    let _html = null;
    // <span id={'id'+id} className="custom-badge"></span>
    if(item.schedule){
      var _schedule = Common.getSchedule(item.schedule);
      if(
        Number(_schedule.ends_in.years) <= 0 && 
        Number(_schedule.ends_in.months) <= 0 && 
        Number(_schedule.ends_in.days) <= 0 && 
        Number(_schedule.ends_in.hours) <= 0 && 
        Number(_schedule.ends_in.minutes) <= 0 && 
        Number(_schedule.ends_in.seconds) <= 0 
      ){
        _html = <Badge> Expired </Badge>
      }
      else if(
        Number(_schedule.started.years) >= 0 && 
        Number(_schedule.started.months) >= 0 && 
        Number(_schedule.started.days) >= 0 && 
        Number(_schedule.started.hours) >= 0 && 
        Number(_schedule.started.minutes) >= 0 && 
        Number(_schedule.started.seconds) >= 0 
      ){
        _html = <Badge status="info"> Scheduled </Badge>
      }
      else{
        if(item.state === 'published'){
          _html = <Badge status="success"> Published </Badge>;
        }
        else if(item.state==='unpublished'){
          _html = <Badge> Unpublished </Badge>;
        }
      }
    }
    return _html;
  }

  countDown(end,clockId){
    let result;
    let est_time = this.state.est_time;
    let start = new Date(est_time.year,est_time.month,est_time.date,est_time.hours,est_time.minutes,est_time.seconds,est_time.milliseconds);
    try{
      if(typeof start !== 'object' || typeof end !== 'object'){return undefined; }
      start = start.getTime();
      end = end.getTime();
      var timeSpan = end - start;
      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(timeSpan / (1000 * 60 * 60 * 24));
      var hours = Math.floor((timeSpan % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((timeSpan % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((timeSpan % (1000 * 60)) / 1000);
      result = {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds
      };
      if(result.seconds < 0){
        result.minutes--;
        result.seconds += 60;
      }
      if(result.minutes < 0){
        result.hours--;
        result.minutes += 60;
      }
      if(result.hours < 0){
        result.days--;
        result.hours += 24;
      }
      if(result.days > 0 || result.hours > 0 || result.minutes > 0 || result.seconds > 0){
        interval = setInterval(startCountDown,1000);
      }
      function startCountDown(){
        result.seconds--;
        if(result.seconds < 0){
          result.minutes--;
          result.seconds += 60;
        }
        if(result.minutes < 0){
          result.hours--;
          result.minutes += 60;
        }
        if(result.hours < 0){
          result.days--;
          result.hours += 24;
        }
        if(result.days < 0 || result.hours < 0 || result.minutes < 0 || result.seconds < -1){
          clearInterval(interval);
          if(clockId !== null){
            try{
              let CLOCKID = document.getElementById(clockId);
              if(CLOCKID !== null){
                CLOCKID.innerHTML = '';
              }
            }catch(e){}
          }
     
        }
        else{
          let ends_message = '';
          if(result.days > 0){
            ends_message += result.days+'d ';
          }
          if(result.hours > 0){
            ends_message += result.hours+'h ';
          }
          if(result.minutes > 0){
            let minutes = result.minutes;
            if(result.minutes < 10){
              minutes = '0'+minutes;
            }
            ends_message += minutes+'m ';
          }
          if(result.seconds >= 0){
            let seconds = result.seconds;
            if(result.seconds < 10){
              seconds = '0'+seconds;
            }
            ends_message += seconds+'s ';
          }
          if(ends_message !== ''){
            if(clockId !== null){
              try{
                let CLOCKID = document.getElementById(clockId);
                if(CLOCKID !== null){
                  CLOCKID.innerHTML = ends_message;
                }
              }catch(e){
                clearInterval(interval);
              }
            }
          }
        }
        window.onbeforeunload = function(){
          clearInterval(interval);
        };
      }
      return result;
    }
    catch(e){
      clearInterval(interval);
    }
  }

  doNotUpdate = (e) => {
    e.preventDefault();
  }

  upgrade = () => {
    this.props.history.push(BUILD_VERSION_EXTENSION+'/pricing-plans');
  }

  require_upgrade_plan(){
    if(Common.getPlanID() === 1){
      return true;
    }
    else{
      return false;
    }
  };

  bulk_delete = (selected_items) => {
    if(window.confirm("Delete all selected rules permanently?")){
      this.setState({loading:true});
      this.fetch_est_time();
      axios.put('/app/v238/bulk-delete-volume-discount?shop='+this.props.shop,{
        selected_items: selected_items,
        shop: this.props.shop,
        keys: (this.state.selected_quantity_breaks_keys).toString()
      })
      .then( response => {
          if(response.data.status === 1){
            this.showToastMessage(response.data.message);
            this.fetch_all_qb(this.state.sort_by);
          }
          else{
            this.showToastMessage(response.data.message, true);
            this.setState({loading:false});
            this.fetch_est_time();
          }
          this.setState({selectedItems: []});
        }
      ).catch(err => {
        this.showToastMessage("Error", true);
        this.setState({loading:false});
        this.fetch_est_time();
      });
    }
  }

  handleSearchChange = (searchValue) => {
    // console.log(this.state.qb_data);
    var _data = this.state.qb_data;
    _data = _data.filter((x)=>x.title.toLowerCase().indexOf(searchValue.toLowerCase())!==-1);
    // console.log(_data);
    this.setState({
      filter_qb_data: _data,
      searchValue:searchValue
    });
  };

  handleFiltersChange = (appliedFilters) => {
    // this.setState({appliedFilters});
    var _data = this.state.qb_data;
    
    _data = _data.filter((x)=> appliedFilters.findIndex((y)=>y.key==="tagBased" && y.value.toLowerCase().indexOf(x.customer_tag.toLowerCase()!==-1))!==-1);
    // console.log(_data);
    this.setState({
      filter_qb_data: _data,
      appliedFilters:appliedFilters
    });
  };

  individual_product_tags(tags,item){
    if(item.applies_to === "products" && item.individual_product && tags && tags.length > 0){
      var _html = [];
      if(tags.findIndex(x => x === "all") > -1){
        _html.push("All customers");
        tags = tags.filter( x => x !== "all");
      }
      if(tags.findIndex(x => x === "no_account") > -1){
        _html.push("Guest customers");
        tags = tags.filter( x => x !== "no_account");
      }
      if(tags.findIndex(x => x === "has_account") > -1){
        _html.push("Logged in customers");
        tags = tags.filter( x => x !== "has_account");
      }
      var ntag = [];
      tags.forEach(tag => {
        if(tag !== "all" && tag !== "no_account" && tag !== "has_account"){
          ntag.push(tag);
        }
      });
      if(ntag && ntag.length > 0){
        var txt = "Customer Tag: ("+ntag.toString()+")";
        _html.push(txt);
      }
      // eslint-disable-next-line
      return _html.toString().replace(/\,/g,", ");
    }
    else if(item.customer_group === "all"){
      return "All customers";
    }
    else if(item.customer_group === "has_account"){
      return "Logged in customers";
    }
    else if(item.customer_group === "no_account"){
      return "Guest customers";
    }
    else if(item.customer_group === "has_tag" && tags && tags.length > 0){
      // eslint-disable-next-line
      return "Customer tag: ("+tags.toString().replace(/\,/g,", ")+")";
    }
  }

  importExportEnabled(){
    var enb = false;
    if(this.props.shopData && this.props.shopData.shop_features){
      enb = this.props.shopData.shop_features.findIndex(x => x === "import_export") > -1;
    }
    return enb;
  }

  render() {
    this.clearAllInterval(interval);
    // var emptySecondaryActions = null;
    // if (this.importExportEnabled() && Common.getPlanID() !== 1) {
    //   emptySecondaryActions = {
    //     content: 'Import Volume Pricing',
    //     onClick: () => {
    //       this.setState({
    //         import_qb_modal: true
    //       });
    //       this.fetch_est_time();
    //     }
    //   }
    // }

    const add_qb_buttons = <div>
      <ButtonGroup>
        <Button primary size="slim" url={BUILD_VERSION_EXTENSION+"/volume-discount/create"} target="APP">Create Volume Pricing</Button>
        {
          Common.getPlanID() !== 1 && this.importExportEnabled() &&
          <Button outline size="slim" onClick={()=>{
            this.setState({import_qb_modal:true});
            this.fetch_est_time();
          }}
          >
          Import
          </Button>
        }
      </ButtonGroup>
    </div>
    const resourceName = {
      singular: 'Volume Pricing Rule',
      plural: 'Volume Pricing Rules',
    };
    // const sortOptions = [
    //   { label: 'Newest', value: 'DATE_CREATED_DESC' },
    //   { label: 'Oldest', value: 'DATE_CREATED_ASC' },
    //   { label: 'Most used', value: 'MOST_USED_DESC' },
    //   { label: 'Least used', value: 'MOST_USED_ASC' }
    // ];
    // const promotedBulkActions = [
    //   {
    //     content: 'Delete selected',
    //     onAction: () => {
    //      this.bulk_delete(this.state.selected_quantity_breaks);
    //     }
    //   }
    // ];
    const importModalSection = <div>
      <Modal
        size="Medium"
        src={BUILD_VERSION_EXTENSION+'/import-volume-discount'}
        open={this.state.import_qb_modal}
        onClose={() => {
          this.setState({import_qb_modal: false, loading: true});
          this.clearAllInterval(interval);
          this.fetch_all_qb(this.state.sort_by);
          this.fetch_est_time();
        }}
        secondaryActions={[
          {
            content: "Close",
            onAction: () => {
              this.setState({import_qb_modal: false, loading: true});
              this.clearAllInterval(interval);
              this.fetch_all_qb(this.state.sort_by);
              this.fetch_est_time();
            }
          }
        ]}
        title={'Import Volume Pricing by CSV file'}
      >
      </Modal>
    </div>;
    // if(Common.getPlanID() !== 1 && this.importExportEnabled()){
    //   emptySecondaryActions = {
    //     content: 'Import Volume Pricing',
    //     onClick: () => {
    //       this.setState({
    //         import_qb_modal: true
    //       });
    //       this.fetch_est_time();
    //     }
    //   };
    // }

    var toast = this.state.showToast? (
      <ToastMessage
      onDismiss={this.dissmissToast}
      message={this.state.toastMsg}
      error={this.state.toastError}
      />
    ): (null);

    if(this.state.page_loaded === false){ 
      return(
        <div>
          <PageTitleBar
              title="Volume Pricing"
              breadcrumbs={[
                { content: 'Dashboard', url: BUILD_VERSION_EXTENSION+'/dashboard', target: 'APP' }
              ]}
            />
          <SkeletonPageComp />
        </div>
      );
    }
    else{
      const { export_errors_list } = this.state;
      const export_error_banner_title = <div>{export_errors_list.length > 1 ? "Export Errors " : "Export Error "}<span style={{fontSize:"85%"}}><Link external url={'https://support.digitalcoo.com/hc/en-us/articles/360054559691-Bulk-Import-export-Discount-Rules'}>(How to fix the errors?)</Link></span></div>
      return (
        <div>
          {
            this.state.export_error && 
            <div style={{marginBottom: "10px"}}>
              <Banner
              title={export_error_banner_title}
              status="critical"
              onDismiss = {()=>{this.setState({export_error: false})}}
              >
                {
                  export_errors_list.length > 0 && (
                  <div>
                      {export_errors_list.map((export_error, index) => (
                        export_error.includes("Cannot export rule") ? <span>{export_error}</span> : <li key={index}>{export_error}</li>
                      ))}
                  </div>
                )}
              </Banner> 
            </div>
          }
          {importModalSection}
          {toast}
          <PageTitleBar
            title="Volume Pricing"
            breadcrumbs={[
              { content: 'Dashboard', url: BUILD_VERSION_EXTENSION+'/dashboard', target: 'APP' }
            ]}
          />
          <Layout>
            <Layout.Section>
              <FormLayout>
                <FormLayout.Group>
                  <div>{add_qb_buttons}</div>
                  <div>
                    <TextField
                      autoComplete={false}
                      placeholder="Type to search rules"
                      label="Search"
                      labelHidden={true}
                      clearButton
                      value={this.state.query}
                      onChange={(query) => {
                        this.setState({query});
                        this.fetch_all_qb(this.state.sort_by, null, query);
                      }}
                      onClearButtonClick={() => {
                        this.setState({query: ""});
                        this.fetch_all_qb(this.state.sort_by);
                      }}
                      prefix={<Icon source={SearchMinor} color="base" />}
                    />
                  </div>
                </FormLayout.Group>
              </FormLayout>
            </Layout.Section>
            <Layout.Section>
              <Card>
                {
                  this.state.filter_qb_data.length > 0?
                  <ResourceList
                    showHeader={true}
                    resourceName={resourceName}
                    items={this.state.filter_qb_data}
                    renderItem={this.renderItem}
                    persistActions
                    loading={this.state.loading}
                  />
                  :<Card.Section>
                    <p>No Volume Pricing Found</p>
                  </Card.Section>
                }
              </Card>
            </Layout.Section>
            {
              (this.state.pagination_has_previous || this.state.pagination_has_next) && 
              <Layout.Section>
                <Pagination
                  hasPrevious={this.state.pagination_has_previous}
                    onPrevious={() => {
                      this.fetch_all_qb(this.state.sort_by, "prev");
                    }}
                    hasNext={this.state.pagination_has_next}
                    onNext={() => {
                      this.fetch_all_qb(this.state.sort_by,"next");
                    }}
                />
              </Layout.Section>
            }
            <Layout.Section><FooterHelpLinks help={this.props.help}/></Layout.Section>
          </Layout>
        </div>
      );
    }
  }

}