import React from 'react';
import { ButtonGroup, Button } from '@shopify/polaris';
import axios from '../Axios';
const BUILD_VERSION_EXTENSION =  process.env.REACT_APP_BUILD_VERSION_EXTENSION;

export default class ManageCustomerButtons extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            pending_customer_count: "(0)",
            rejected_customer_count: "(0)",
            page_loaded: false
        }
        axios.defaults.headers.common['X-Ws-Shop-Domain'] = "https://" + this.props.shop;
    }

    componentDidMount() {
        this.getCount("pending");
        this.getCount("rejected");
    }

    getCount(status) {
        axios.get('/customer/api/335/admin/count?shop=' + this.props.shop + "&status=" + status).then(response => {
            if (response && response.data && typeof response.data.count !== "undefined") {
                if (status === "pending") {
                    this.setState({
                        pending_customer_count: "("+response.data.count+")",
                        page_loaded: true
                    });
                } else if (status === "rejected") {
                    this.setState({
                        rejected_customer_count: "("+response.data.count+")",
                        page_loaded: true
                    });
                }
            }
        }).catch(e => {
        });
    }
    render(){
        return(
            <div className="ws-buttons">
                <ButtonGroup>
                    <Button size="slim" url={BUILD_VERSION_EXTENSION+"/customers/wholesale_approval"} target="APP">Pending Approvals {this.state.pending_customer_count}</Button>
                    <Button size="slim" url={BUILD_VERSION_EXTENSION+"/customers/rejected_customers"} target="APP">Rejected Customers {this.state.rejected_customer_count}</Button>
                    <Button size="slim" url={BUILD_VERSION_EXTENSION+"/customers/approved_customer"} target="APP">Approved Customers</Button>
                </ButtonGroup>
            </div>
        );
    }
}