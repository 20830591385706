import React from 'react';
import ReactDOM from 'react-dom';
import 'dotenv';
// import enTranslations from '@shopify/polaris/locales/en.json';
import { Page} from '@shopify/polaris';
// import { Provider, TitleBar } from '@shopify/app-bridge-react';
import Install from './Components/Install';
import CommonHelper from './Helpers/Common';

// routing proble solved by these modules
import { BrowserRouter } from 'react-router-dom';
// import RoutePropagator from './RoutePropagator';
// import MyRouter from './MyRouter';
import App238 from './App238';
import {
  AppBridgeProvider,
  PolarisProvider,
} from "./Components/Providers";

// const BUILD_VERSION_EXTENSION =  process.env.REACT_APP_BUILD_VERSION_EXTENSION;

// const config = {
//   forceRedirect: false,
//   apiKey: process.env.REACT_APP_SHOPIFY_API_KEY,
//   shopOrigin: CommonHelper.getShop(),
//   host: CommonHelper.getHost()
// };


if(CommonHelper.getShop()){
  ReactDOM.render(
    <PolarisProvider>
      <BrowserRouter>
        <AppBridgeProvider>
        {/* <MyRouter /> */}
          <Page fullWidth>
            <App238 />
          </Page>
        </AppBridgeProvider>
      </BrowserRouter>
    </PolarisProvider>,
    document.getElementById('root')
  );
}
else{
  ReactDOM.render(
    <PolarisProvider>
      <Install />
    </PolarisProvider>,
    document.getElementById('root')
  );
}