import React from 'react';
import { Layout, RadioButton, Modal, Stack, Button, TextStyle, Heading, Link, Banner, Icon, Badge } from '@shopify/polaris';
import { InfoMinor, MobileAcceptMajor, MobileCancelMajor, ExternalSmallMinor } from "@shopify/polaris-icons";
import axios from '../Axios';
import store from 'store2';
import ToastMessage from '../Common/ToastMessage';
import CommonHelper from '../Helpers/Common';
const BUILD_VERSION_EXTENSION =  process.env.REACT_APP_BUILD_VERSION_EXTENSION;

export default class AppStatusBanner extends React.Component{

    constructor(props){
        super(props);
        this.state = {
          app_mode: this.props.app_mode || "test",
          expanded: this.props.app_mode === "live"? false: true,
          modal: false,

           // Theme installation states
           theme_status_fetched:false, // if ajax is finished, render component based on that
           no_themes_installed:false, // show "Action Required" alert based on this
           contact_banner:false, // show "Contact Us" alert based on this
           active_plan_exists:true, // show "Active Latest Plan" alert based on this
        free_theme:null, // show "Manual configuration" alert based on this
        after_installation_confirmation:false, // show "Contact Us" alert based on this
 
          // app installed banner
          hide_installation_banner: false,
          shopData: null,


           /* Toast specefic */
           showToast: false,
           toastError: false,
           toastMsg: ""
        }
    }

        /* Lifecycle: Begin component */  
        componentDidMount() {
          var currentPathName = window.location.pathname;
  
          /* Hide theme configuration banners of these pages */
          var allowed = currentPathName.indexOf('a/theme-installations/edit') === -1 && 
          currentPathName.indexOf('a/theme-installations/list') === -1 && 
          currentPathName.indexOf('a/theme-installations/create') === -1 && 
        currentPathName.indexOf('a/theme-installations/manual') === -1 &&
        currentPathName.indexOf('update-plans') === -1;
  
          if(allowed)this.fetchThemeStatus();
  
        if(currentPathName.indexOf('update-plans') !== -1){
          // Always collapse test/live mode banner on plan page [to save space]
          this.setState({
            expanded: false
          });
        }

          
      }
  
      checkOnLiveTheme(){
        axios.get('/app/v238/global-installation-status?shop='+this.props.shop)
        .then(x=>x.data)
        .then(response => {
  
            if(typeof response.error !== "undefined" && response.error){
                return;
            }
  
            let state = {
              after_installation_confirmation: response.installation_status === "required"
            }
  
            this.setState(state);
            
        })
        .catch(error => {
            
        });
    }
    
    fetchThemeStatus(internalCall = false){
          axios.get('/app/v238/global-theme-status?shop='+this.props.shop)
          .then(x=>x.data)
          .then(response => {
  
              if(typeof response.error !== "undefined" && response.error){
                  return;
              }

              try{ 
                // Trigger event to make this response global accessible
                // Create the event
                var event = new CustomEvent("wsaio-global", { "detail": response });
                // Dispatch/Trigger/Fire the event
                document.dispatchEvent(event);
              } catch(e){ }
  
              let state = {
                theme_status_fetched: true,
              // no_themes_installed:response.no_themes_installed, // DISABLE APP INTEGRATION BANNER - this is handled by our team
                contact_banner:response.contact_banner,
                active_plan_exists:response.activePlanExists,
                free_theme: response.free_theme
              }
              
            // Update shopData
            if(response.shopData){
              state.shopData = response.shopData;

              if(response.shopData.app_configured === true ){
                state.hide_installation_banner = true;
              }
            }

            if(response.free_theme && response.free_theme.free === true && !internalCall){
              // register an event listner to reload free theme banner
              const currentComponent = this;
              try{
                document.addEventListener("wsaio-reload-auto-installation-banner", function(e) {
                    try{
                      currentComponent.fetchThemeStatus(true);
                    } catch(e) { }
                });

              } catch(e) { }

            }

            // Check if app is fully configured, need to check if app is still integrate on live theme
            if(response.shopData && ( response.shopData.install_status === "completed" || response.shopData.install_status === "auto_completed" ) && response.shopData.app_configured === true){
              this.checkOnLiveTheme();
            }

            this.setState(state);

          })
          .catch(error => {
              
          });
      }
  
    showToastMessage(msg, error){
      this.setState({
        showToast: true,
        toastMsg: msg,
        toastError: error||false,
      });
    }

    dissmissToast = () =>{
      this.setState({
        showToast: false,
      });
    }

    handleEnableApp = (id, value) => {
      var app_mode = value;
      this.setState({app_mode: value});
      const settingsData = {
        app_mode: app_mode,
        shop: this.props.shop
      };
      axios.put('/app/app-mode', settingsData)
      .then( response => {
          if(response.data.status === 1){
            this.updateData(app_mode);
            this.showToastMessage(response.data.message);
          }
          else{
            this.showToastMessage(response.data.message, true);
          }
      })
      .catch(error => {
        this.showToastMessage("Server error", true);
      });
    };

    handleHideInstallation = (auto = false) => {
      this.setState({
        hide_installation_banner: true
      });

      let settingsData = {        
        shop: this.props.shop
      };

      if(auto) settingsData.auto=true;

      axios.put('/app/v238/update-installation-status', settingsData)
      .then( response => { })
      .catch(error => { });
    };

    updateData(app_mode) {
      if (store("_wsinit")) {
        var shopData = store("_wsinit");
        try {
          shopData = JSON.parse(shopData);
        } catch (e) {}
        try {
          shopData["app_mode"] = app_mode;
        } catch (e) {}
        if (shopData.name) {
          store("_wsinit", JSON.stringify(shopData));
        }
      }

      try{ 
        // Trigger event to reload auto installation banner
        document.dispatchEvent(new CustomEvent("wsaio-reload-auto-installation-banner"));
      } catch(e){ }
    }

    render(){
        const {app_mode } = this.state;
        const shopData = this.state.shopData;

        var toast = this.state.showToast? (
          <ToastMessage
            onDismiss={this.dissmissToast}
            message={this.state.toastMsg}
            error={this.state.toastError}
          />
        ): (null);

        const infoModal = <Modal
        open={this.state.modal}
        onClose={()=>{this.setState({modal:false})}}
        title="Test Mode & Live Mode options"
      >
        <Modal.Section>
          <Stack distribution="fillEvenly">
              <Stack.Item>
                <Heading>Test Mode</Heading>
                <p>
                    Use to test the wholesale app working before making it live to the customers.
                </p>
              </Stack.Item>
              <Stack.Item>
                <Heading>Live Mode</Heading>
                <p>
                    The wholesale app features will appear direclty to the live customers.
                    <br></br>
                    <Link external={true} url="https://support.digitalcoo.com/hc/en-us/articles/360042800512">Learn More</Link>
                </p>
              </Stack.Item>
          </Stack>
        </Modal.Section>
      </Modal>;

/**
       * Install Status => 
       *    in_development && reinstalled_on !== null => App is just installed and required installation - need to show contact us form and guide through creating staff account
       *    in_development && reinstalled_on !== null => App is reinstalled and required reinstallation - Change template content
       *    requested => merchant submits the contact us form - show staff account instructions
       *    in_progress => Staff account is created and we are working on it
       *    completed && !app_configured => installation is completed - Show success banner
       *    completed && app_configured => Hide all the banners 
      */
 let installation_banner = null;
 if(shopData && !shopData.app_configured && !CommonHelper.isAdmin() && !this.state.hide_installation_banner){

        let auto_installation_check_list = null;
        let auto_installation_completed = false;
        if(this.state.free_theme && this.state.free_theme.free === true){
          
          auto_installation_completed = this.state.free_theme.is_app_live && this.state.free_theme.appblock_enabled && this.state.free_theme.rules_found && this.state.free_theme.matching_customers;

          auto_installation_check_list = <div style={{marginTop:"1rem"}}>
            <Stack vertical spacing="tight">

              {/* App enabled from theme customization? */}
              <Stack spacing="tight" alignment="center">
                <Icon source={ this.state.free_theme.appblock_enabled ? MobileAcceptMajor : MobileCancelMajor } />
                <Stack spacing="extraTight">

                  <TextStyle >App activation</TextStyle>
                  { !this.state.free_theme.appblock_enabled && 
                    <>
                      [<Link url={this.state.free_theme.links.appblock}>Click to activate</Link>]
                    </>
                  }
                  <Link external={true} url="https://support.digitalcoo.com/hc/en-us/articles/14773784387604" monochrome>
                    <Badge>
                      <Stack spacing="extraTight" alignment="center">
                        <Icon source={InfoMinor} color="subdued" />
                        <TextStyle variation="subdued">Learn more</TextStyle>
                        <Icon source={ExternalSmallMinor}></Icon>
                      </Stack>
                    </Badge>
                  </Link>

                </Stack>

              </Stack>
              
              {/* Any published regular or volume pricing rule found */}
              <Stack vertical spacing="extraTight">

                <Stack spacing="tight">
                  <Icon source={ this.state.free_theme.rules_found ? MobileAcceptMajor : MobileCancelMajor } />
                  <TextStyle >Create a pricing rule</TextStyle>
                </Stack>
                <div style={{marginLeft:"5rem"}}>
                  - Wholesale Pricing rule [<Link url={BUILD_VERSION_EXTENSION+'/regular-discount/list'}>Create rule</Link> | <Link url="https://support.digitalcoo.com/hc/en-us/sections/360007029532">Read article</Link>] <TextStyle variation="strong">OR</TextStyle> Volume Pricing rule [<Link url={BUILD_VERSION_EXTENSION+'/volume-discount/list'}>Create rule</Link> | <Link url="https://support.digitalcoo.com/hc/en-us/sections/360007122931">Read article</Link>]
                </div>

              </Stack>
              
              {/* Any matching customer found from some tag based pricing rule */}
              <Stack spacing="tight">
                <Icon source={ this.state.free_theme.matching_customers ? MobileAcceptMajor : MobileCancelMajor } />
                <Stack spacing="extraTight">

                  <TextStyle >Check matching customers</TextStyle>
                  <Link external={true} url="https://support.digitalcoo.com/hc/en-us/articles/14774227485716">
                    <Badge>
                      <Stack spacing="extraTight" alignment="center">
                        <Icon source={InfoMinor} color="subdued" />
                        <TextStyle variation="subdued">Learn more</TextStyle>
                        <Icon source={ExternalSmallMinor}></Icon>
                      </Stack>
                    </Badge>

                  </Link>
                  
                </Stack>
              </Stack>

              {/* If app is in live mode */}
              <Stack spacing="tight">
                <Icon source={ this.state.free_theme.is_app_live ? MobileAcceptMajor : MobileCancelMajor } />
                <Stack spacing="extraTight">

                  <TextStyle >Publish app status to 'Live Mode'</TextStyle>
                  <Link external={true} url="https://support.digitalcoo.com/hc/en-us/articles/360042800512">

                    <Badge>
                      <Stack spacing="extraTight" alignment="center">
                        <Icon source={InfoMinor} color="subdued" />
                        <TextStyle variation="subdued">Learn more</TextStyle>
                        <Icon source={ExternalSmallMinor}></Icon>
                      </Stack>
                    </Badge>
                  </Link>
                  
                </Stack>
              </Stack>


              {/* Action Buttons */}
              {this.state.free_theme.appblock_enabled && this.state.free_theme.rules_found && this.state.free_theme.matching_customers &&
                <div style={{marginTop:"1rem"}}>
                  <Stack spacing="tight">

                    { this.state.free_theme.is_app_live && 
                      <Button 
                        outline
                        onClick={() => { this.handleHideInstallation(true); }}
                      >
                        App working, thanks
                      </Button>
                    }

                    <Button 
                      outline
                      url="https://support.digitalcoo.com/hc/en-us/requests/new"
                    >
                      App not working, please help
                    </Button>

                  </Stack>
                </div>
              }
              

            </Stack>
          </div>;

        }

   installation_banner =  shopData.install_status === "in_development" && shopData.status === "active" && shopData.reinstalled_on !== null ?
          <>
            {this.state.free_theme && this.state.free_theme.free === true ?
              <Banner
                title="App Activation Checklist"
                status={ auto_installation_completed ? "success" : "warning" }
                onDismiss={ auto_installation_completed ? () => {
                  this.handleHideInstallation(true);
                } : null }
              >
                <>
                  {auto_installation_check_list}
                </>
              </Banner>
            :
     <Banner
       title="Action Required"
       status="critical"
       action={{ content: "Submit verify request", url: BUILD_VERSION_EXTENSION+'/contact-us' }}
         
     >
       <p>Thanks for re-installing Wholesale All In One app. Please submit a request to verify wholesale app is working on your website, and our team will make sure that all the wholesale app features are working properly. </p>
     </Banner>
            }
          </>
         
   : shopData.install_status === "in_development" ?
          <>
            {this.state.free_theme && this.state.free_theme.free === true ?
              <Banner
                title="App Activation Checklist"
                status={ auto_installation_completed ? "success" : "warning" }
                onDismiss={ auto_installation_completed ? () => {
                  this.handleHideInstallation(true);
                } : null }
              >
                <>
                  {auto_installation_check_list}
                </>
              </Banner>
            :
     <Banner
       title="Action Required"
       status="critical"
       action={{ content: "Submit integration request", url: BUILD_VERSION_EXTENSION+'/contact-us' }}
         
     >
       <p>Thanks for installing Wholesale All In One application. In order to use the wholesale app, it's required to integrate wholesale app script into your website. Please submit script integration request and our team will integrate the app script into the theme.</p>
     </Banner>
            }
          </>
   : shopData.install_status === "requested" ?
     <Banner
       title="Action Required"
       status="warning"
       action={{ content: "Create staff account", url: BUILD_VERSION_EXTENSION+'/contact-us' }}
     >
       <p>
         We received your request to integrate wholesale app script onto your website. Please create a staff account for our team. We'll access the dashboard and integrate the app script. 
         <br />You can ignore this message if you have already created a staff account for our team.
         </p>
     </Banner>
   : shopData.install_status === "in_progress" ?
     <Banner
       title="In progress (please wait)"
       status="info"
     >
       <p>The team is working to integrate the wholesale app script into your website and normally it takes 1 to 3 business days. We'll send an email notification to you once it's ready for review.</p>
     </Banner>
   : shopData.install_status === "completed" ?
     <Banner
       title="Done! Ready for review"
       status="success"
       action={{ content: "Dismiss", onAction: () => { this.handleHideInstallation(); } }}
       onDismiss={() => {
         this.handleHideInstallation();
       }}
     >
       <p>We have successfully integrated wholesale app to your website. You can set up discounts according to your requirements.</p>
     </Banner>
   : null;
 }
 
        return(
            <div>
              {toast}
                <Layout>
                    {infoModal}
                    <Layout.Section>
                        <Stack>
                        <Stack.Item fill>
                              {/* Show dashboard button on all pages except landing pages */}
                              {
                                ( window.location.pathname.indexOf('dashboard') === -1 &&
                                window.location.pathname.indexOf('a/dashboard') === -1 ) &&
                                <Button url={BUILD_VERSION_EXTENSION+'/dashboard'} target="APP">Dashboard</Button>
                              }
                            </Stack.Item>
                            <Stack.Item>
                                <Button
                                    destructive={this.state.app_mode === "test"}
                                    primary={this.state.app_mode === "live"}
                                    plain={true}
                                    disclosure={this.state.expanded ? "up" : "down"}
                                    onClick={() => {
                                        this.setState({ expanded: !this.state.expanded});
                                    }}
                                    removeUnderline={true}
                                >
                                  <div className={this.state.app_mode === "live"?"ws-appmode_live":"ws-appmode_test"} style={{display: "flex", alignItems: "center"}}>
                                    <div class="ws-circle"></div>
                                    <div class="ws-mode-text">
                                      {
                                        this.state.app_mode === "live"?
                                        "Live Mode Enabled"
                                        :"Test Mode Enabled"
                                      }
                                    </div>
                                  </div>
                                </Button>
                            </Stack.Item>
                        </Stack>
                    </Layout.Section>
                    {this.state.expanded &&
                    <Layout.Section>
                        <div className={"Polaris-Banner Polaris-Banner--withinPage " + (this.state.app_mode === "live" ? " Polaris-Banner--statusSuccess ":" Polaris-Banner--statusWarning " ) } tabindex="0" role="status" aria-live="polite" aria-describedby="PolarisBanner1Content">
                            <div className="Polaris-Banner__ContentWrapper">
                                <div className="Polaris-Banner__Content" id="PolarisBanner1Content">
                                    <Stack spacing="tight" alignment="center">
                                        <Stack.Item>
                                            <TextStyle variation="strong">Select the application mode:</TextStyle>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <RadioButton
                                                label="Test Mode"
                                                id="test"
                                                name="mode"
                                                checked={app_mode === 'test'}
                                                onChange={this.handleEnableApp}
                                            />
                                        </Stack.Item>
                                        <Stack.Item fill>
                                            <RadioButton
                                                label="Live Mode"
                                                checked={app_mode === 'live'}
                                                id="live"
                                                name="mode"
                                                onChange={this.handleEnableApp}
                                            />
                                        </Stack.Item>
                                        <Stack.Item>
                                            <Button plain icon={InfoMinor} onClick={()=> {
                                                this.setState({modal:true})
                                            }}>
                                                Quick info
                                            </Button>
                                        </Stack.Item>
                                    </Stack>
                                </div>
                            </div>
                        </div>
                    </Layout.Section>
                    }                    

                    {
                      !this.state.active_plan_exists &&
                        <Layout.Section>
                          <Banner
                              title="Action Required"
                              status="warning"
                              action={{content: 'Click here', url: BUILD_VERSION_EXTENSION+'/pricing-plans'}}
                          >
                              <p>Please activate a plan to use latest version of Wholesale All In One.</p>
                          </Banner>
                        </Layout.Section>
                    }

                    { installation_banner ? 
                      <Layout.Section>{installation_banner}</Layout.Section>
                    : !(this.state.free_theme && this.state.free_theme.free === true) ?
                    <Layout.Section>                      
                        {this.state.contact_banner ?
                        <Banner
                            title="Action Required"
                            status="warning"
                        >
                            <p>In order to use the Wholesale All In One app, please <Link external={true} url="https://support.digitalcoo.com/hc/en-us/requests/new">submit a request</Link> to integrate the wholesale app to your website. We'll make some necessary adjustments in the theme code to ensure your theme will run smoothly with our app.</p>
                        </Banner>
                        : this.state.no_themes_installed ?
                        <Banner
                            title="App integration alert - (Action Required)"
                            status="warning"
                            action={{content: 'Integrate app', url: BUILD_VERSION_EXTENSION+'/theme-installations/list'}}
                        >
                            <p>Wholesale All In One app integration is required to use the application.</p>
                        </Banner>
                        : null}

                      </Layout.Section>
                    : null}

                    {this.state.after_installation_confirmation && 
                      <Layout.Section>
                        <Banner
                          title="Action Required"
                          status="warning"
                        >
                          <p>In order to use the Wholesale All In One app, please <Link external={true} url="https://support.digitalcoo.com/hc/en-us/requests/new">submit a request</Link> to integrate the wholesale app to your website. We'll make some necessary adjustments in the theme code to ensure your theme will run smoothly with our app.</p>
                        </Banner>
                    </Layout.Section>
                     }
                </Layout>
            </div>
        );
    }

}