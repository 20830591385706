import React, { Component } from 'react';
import {Banner, Layout, FormLayout, TextField, Card, Button, List, Form, Select, DropZone, Thumbnail, TextStyle, Stack } from '@shopify/polaris';
import {NoteMinor, DeleteMinor, PlayCircleMajor } from '@shopify/polaris-icons';
import SkeletonPageComp from './SkeletonPageComp';
import PageTitleBar from '../Common/TitleBar';
import ToastMessage from '../Common/ToastMessage';
import axios from '../Axios';
const BUILD_VERSION_EXTENSION =  process.env.REACT_APP_BUILD_VERSION_EXTENSION;

export default class GetSupport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Email data 
            email_subject: "",
            email_replyto: "",
            email_name: "",
            email_body: "",
            totalFileSize: 0,
            files: [],

            /* If ajax has some errors, will render the array */
            errorList: [],

            /* Skeleton page until data loaded */
            page_loaded: false,

            sending_request: false,

            /* Toast specefic */
            showToast: false,
            toastError: false,
            toastMsg: "",
            ticket_id: null,
            role: ""
        }
        axios.defaults.headers.common['X-Ws-Shop-Domain'] = "https://"+this.props.shop;
    }

    componentDidMount() {
        this.setState({page_loaded: true});
    }

    scrollToTop(){
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    handleSubmit = () => {
        this.setState({
            sending_request: true,
            errorList: [],
            ticket_id: null
        });

        let errors = [];

        if(this.state.email_name.length < 1) {
            errors.push("Please enter your name");
        }
        if(this.validateEmail()) {
            errors.push("Please enter valid email address");
        }
        if(this.state.role.length < 1) {
            errors.push("Please select your role");
        }
        if(this.state.email_subject.length < 1) {
            errors.push("Please enter subject");
        }
        if(this.state.email_body.length < 1 || this.state.email_body?.trim().length < 1) {
            errors.push("Description cannot be empty");
        }
        if(errors.length > 0) {
            this.setState({
                errorList: errors,
                sending_request: false,
                toastError: true,
                showToast: true,
                toastMsg: "Please resolve the errors to proceed"
            });
            this.scrollToTop();
            return;
        }
        else {
            let form_data = new FormData();
            form_data.append("shop", this.props.shop);
            form_data.append("email", this.state.email_replyto);
            form_data.append("name", this.state.email_name);
            form_data.append("subject", this.state.email_subject);
            form_data.append("description", this.state.email_body);
            form_data.append("role", this.state.role);
            this.state.files.forEach((file) => {
                form_data.append("attachments", file);
            });

            axios.post("/app/238/get-support", form_data, 
                {
                    headers: {
                    "Content-Type": "multipart/form-data",
                    },
                })
                .then(x=>x.data)
                .then((response) => {
                    if(response.error){
                        this.setState({
                            showToast: true,
                            toastError: true,
                            toastMsg: response.message,
                            sending_request: false,
                        });
                    }
                    else {
                        this.scrollToTop();
                        this.setState({
                            ticket_id: response.ticket_id,
                            sending_request: false,
                            email_body: "",
                            email_name: "",
                            email_subject: "",
                            email_replyto: "",
                            showToast: true,
                            toastError: false,
                            toastMsg: "Email sent successfully",
                            role: "",
                            files: [],
                            totalFileSize: 0,
                            errorList: [],
                        })
                    }
                })
                .catch((error) => {
                    this.setState({
                        showToast: true,
                        toastError: true,
                        toastMsg: "An error occurred while sending email",
                        sending_request: false
                    });
                });
        }
    }

    validateEmail = () => {
        const email = this.state.email_replyto;
        // eslint-disable-next-line
        const email_regex = new RegExp(/^(\s*[A-Za-z0-9._%+\-]+@[A-Za-z0-9.\-]+\.[A-Za-z]{2,}\s*)$/g);
        return !email_regex.test(email);
    }

    

    formatBytes = (bytes) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    }

    handleRemoveFile = (index) => {
        const newFiles = this.state.files.filter((_, i) => i !== index);
        // Calculate total file size
        let totalFileSize = 0;
        newFiles.forEach((file) => {
            totalFileSize += (file.size / 1024 / 1024);
        });
        this.setState({ totalFileSize: totalFileSize });
        this.setState({ files: newFiles });
    };

    /* Lifecycle: Main Event */
    render() {
        const { files } = this.state;

        /* If we have errors to display */
        let errors_section = <div>
            <Banner status="critical" title={`There ${this.state.errorList.length > 1?"are " + this.state.errorList.length:"is an"}${this.state.errorList.length > 1?" errors":" error"}`}>
                <List type="number">
                {
                    this.state.errorList.map((error,iN) => {
                        return <List.Item key={iN}>{error}</List.Item>
                    })
                }
                </List>
            </Banner>
        </div>

        let ticket_section = <div>
            <Banner title="Email sent successfully" status="success">
                <p>Your email has been sent successfully. Your ticket id is <b>#{this.state.ticket_id}</b>. We will get back to you shortly.</p>
            </Banner>
        </div>

        
        /* Will show toast based on state */
        var toast = this.state.showToast? (<ToastMessage message={this.state.toastMsg} error={this.state.toastError} onDismiss={()=>{this.setState({showToast: false})}} />):(null);
        
        const imageThumbnails = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'];
        const videoThumbnails = ['video/mp4', 'video/avi', 'video/mov', 'video/mkv'];
        const allowdTypes = ['text/csv', 'text/xlsx', 'image/gif', 'image/jpeg', 'image/jpg','image/png', 'video/mp4', 'video/avi', 'video/mov', 'video/mkv', 'application/pdf', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword'];

        const fileUpload = !files.length && <DropZone.FileUpload actionHint="Add files or drag and drop" />;
        const uploadedFiles = files.length > 0 && (
            <div style={{padding: '0'}}>
            <Stack vertical>
                {files.map((file, index) => (
                <Stack alignment="center" key={index}>
                    <Thumbnail
                    size="small"
                    alt={file.name}
                    source={
                        imageThumbnails.includes(file.type)
                        ? window.URL.createObjectURL(file)
                        : videoThumbnails.includes(file.type) 
                        ? PlayCircleMajor
                        : NoteMinor
                    }
                    />
                    <div>
                    {file.name}{' '}
                    <TextStyle variant="bodySm" as="p">
                        {'(' + this.formatBytes(file.size) + ')' || '(' + file.size + ' bytes )'}
                    </TextStyle>
                    </div>
                    <Button 
                        size='slim' 
                        icon={DeleteMinor} 
                        onClick={(e) => {
                            e.stopPropagation(); 
                            this.handleRemoveFile(index); 
                        }} 
                    />
                </Stack>
                ))}
            </Stack>
            </div>
        );

        const your_role_label = <div>
            Your Role <span style={{color:'red'}}>* </span><TextStyle variation="subdued">(Select the option that best describes you)</TextStyle>
        </div>
        return(
            
            <div>
                <PageTitleBar
                    title="Get Support"
                    breadcrumbs={[
                        { content: 'Dashboard', url: BUILD_VERSION_EXTENSION+'/dashboard' }
                    ]}
                    secondaryActions={[{content: "Back", url: BUILD_VERSION_EXTENSION+'/dashboard', target: "APP"}]}
                />
                {this.state.page_loaded ?

                    <Layout>
                        { this.state.errorList.length > 0 && <Layout.Section>{errors_section}</Layout.Section> }
                        { this.state.ticket_id && <Layout.Section>{ticket_section}</Layout.Section> }
                        <Layout.Section>
                            <Card sectioned>
                                <div style={{fontSize: '20px', marginBottom: '30px'}}><TextStyle variation="strong">Submit a request</TextStyle></div>

                                <Form onSubmit={() => {
                                    this.handleSubmit();
                                }}>
                                    <FormLayout>

                                        <Stack distribution="fillEvenly">
                                            <Stack.Item>
                                                <TextField 
                                                    label={<span><span>Your Name </span><span style={{color:'red'}}>*</span></span>}
                                                    value={this.state.email_name}
                                                    onChange={(value) => {
                                                        this.setState({
                                                            email_name: value
                                                        });
                                                    }}
                                                    autoComplete="off" 
                                                />
                                            </Stack.Item>

                                            <Stack.Item>
                                                <TextField 
                                                    label={<span><span>Your Email </span><span style={{color:'red'}}>*</span></span>}
                                                    value={this.state.email_replyto}
                                                    onChange={(value) => {
                                                        this.setState({
                                                            email_replyto: value
                                                        });
                                                    }}
                                                    autoComplete="off" 
                                                />
                                            </Stack.Item>

                                            <Stack.Item>
                                                <Select
                                                    label={your_role_label}
                                                    placeholder="Select Your Role"
                                                    options={[
                                                        {label: 'Store Owner', value: 'Store Owner'},
                                                        {label: 'Developer', value: 'Developer'},
                                                        {label: 'Team Member', value: 'Team Member'}
                                                    ]}
                                                    value={this.state.role}
                                                    onChange={(value) => {
                                                        this.setState({
                                                            role: value
                                                        });
                                                    }}
                                                />
                                            </Stack.Item>
                                        </Stack>

                                        <TextField 
                                            label={<span><span>Subject </span><span style={{color:'red'}}>*</span></span>}
                                            value={this.state.email_subject}
                                            onChange={(value) => {
                                                this.setState({
                                                    email_subject: value
                                                });
                                            }} 
                                            autoComplete="off" 
                                        />

                                        <Stack vertical spacing='extraTight'>
                                            <Stack.Item>
                                                <TextField
                                                    label={<span><span>Description </span><span style={{color:'red'}}>*</span></span>}
                                                    value={this.state.email_body}
                                                    onChange={(value) => {
                                                        this.setState({
                                                            email_body: value
                                                        });
                                                    }}
                                                    autoComplete="off"
                                                    multiline={4}
                                                />
                                            </Stack.Item>
                                            <Stack.Item>
                                                <div style={{fontStyle: 'italic', fontSize: '85%'}}><TextStyle variation="subdued">Please provide detailed information to help us understand the scenario accurately</TextStyle></div>
                                            </Stack.Item>
                                        </Stack>
                                        
                                        <div id="getsupport-dropzone">
                                            <Stack vertical spacing='extraTight'>
                                                <Stack.Item>
                                                    <DropZone
                                                        label="Attachments (optional)"
                                                        allowMultiple
                                                        dropOnPage
                                                        onDrop={(files) => {
                                                            if (files.length < 1) return;
                                                            this.setState({ errorList: [] }, () => {
                                                                let errors = [];
                                                                let validFiles = [];
                                                                let totalFileSize = this.state.totalFileSize;
                                                                files.forEach(file => {
                                                                    if(file instanceof File) {
                                                                        if (!allowdTypes.includes(file.type)) {
                                                                            errors.push("File type not supported for (" + file.name + ")");
                                                                        } 
                                                                        else if (file.size > 50 * 1024 * 1024) {
                                                                            errors.push("File size exceeds 50MB limit for (" + file.name + ")");
                                                                        }
                                                                        else if (file.size < 1) {
                                                                            errors.push("Cannot upload an empty file (" + file.name + ")");
                                                                        }
                                                                        else if(totalFileSize + (file.size / 1024 / 1024) > 300) {
                                                                            errors.push("Cannot upload more than 300MB in total");
                                                                        }
                                                                        else {
                                                                            validFiles.push(file);
                                                                            totalFileSize += (file.size / 1024 / 1024);
                                                                        }
                                                                    }
                                                                });

                                                                if (validFiles.length > 0) {
                                                                    this.setState({ files: [...this.state.files, ...validFiles] });
                                                                }

                                                                this.setState({ totalFileSize: totalFileSize });

                                                                if (errors.length > 0) {
                                                                    this.setState({
                                                                        errorList: errors,
                                                                        showToast: true,
                                                                        toastError: true,
                                                                        toastMsg: "Please review the errors"
                                                                    });
                                                                    this.scrollToTop();
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        {uploadedFiles}
                                                        { fileUpload }
                                                    </DropZone>
                                                </Stack.Item>
                                                <Stack.Item>
                                                <div style={{fontStyle: 'italic', fontSize: '85%'}}><TextStyle variation="subdued">Add relevant images or videos to support your scenario (if applicable)</TextStyle></div>
                                                </Stack.Item>
                                            </Stack>

                                        </div>

                                        <Button 
                                            submit 
                                            primary
                                            size="large"
                                            loading={this.state.sending_request}
                                            disabled={this.state.sending_request || this.state.email_replyto.length < 1}
                                        >
                                            Send email
                                        </Button>
                                    </FormLayout>
                                </Form>
                            </Card>
                        </Layout.Section>
                    </Layout>
                :
                    <SkeletonPageComp />
                }
                {toast}
            </div>
        );
    }

}