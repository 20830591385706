
import CommonHelper from '../../../Helpers/Common';

export const LinkOverride = ({children, ...props}) => {
  
    if(props.onClick) delete props.onClick;
    if(props.href && (!props.target)) {
        props.href = CommonHelper.mergeQueryParams(props.href);
    }

    return <a {...props}>
        {children}
    </a>;

};

export const useLinkOverride = (url) => {
    url = CommonHelper.mergeQueryParams(url);
    return window.location.href = url;
};

export default LinkOverride;