import React, { Component } from 'react';
import { Heading, TextContainer, Form, Card, Stack, FormLayout, TextField, Checkbox, Tag, Select, Button, RadioButton, TextStyle, Badge, Banner, Link, Icon} from '@shopify/polaris';
import store from 'store2';
import { DeleteMinor, LockMinor } from '@shopify/polaris-icons';
import _Modules from '../Json/modules.json';
import waio from '..//Helpers/functions';
import ToastMessage from '../Common/ToastMessage';
import TitleBar from '../Common/TitleBar';
import Common from '../Helpers/Common';
import LoadingOverlay from '../Components/LoadingOverlay';
import axios from '../Axios';
const BUILD_VERSION_EXTENSION =  process.env.REACT_APP_BUILD_VERSION_EXTENSION;

var snippet_version = store('snippet_version') || "2.3.8";
// var store_currency = store('store_currency') || null;
const money_format = store('ws_money_format') || null;

// eslint-disable-next-line
Number.prototype.countDecimals = function () {
    if(isNaN(this))return 0;
    if(Math.floor(this.valueOf()) === this.valueOf()) return 0;
    return this.toString().split(".")[1].length || 0; 
}

export default class CreateMinOrderControl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: this.props.show,
            shop: this.props.shop,
            edit_rule_id: this.props.match.params.id,
            rules: [{ "column": "cart_original_total_price", "relation": "greater_than_equal_to", "condition": "" }],
            rules_operator: "and",
            opening_rules: [{ "column": "cart_original_total_price", "relation": "greater_than_equal_to", "condition": "" }],
            opening_rules_operator: "and",
            condition_scope: "all",
            opening_message: "",
            message: "",
            internal_name: "Order limit",
            status: "active",
            customer_group: "tag_based",
            except_logged_in: false,
            except_tags: false,
            excepted_tags: [],
            temp_tag_value: "",
            customer_tags: [],
            temp_customer_tags_value: "",
            saveButtonLoading: false,
            allOrderControls: [],
            page_loaded: false,
            showToast: false,
            toastMsg: '',
            toastError: false,

            // Don't hide new options when its old customer, to maintain backward compatibility
            old_customer: false,

            // Default value of weight, older rule will not be effected
            weight_unit: "g",
            weight_unit_label: "Grams"
        };
    }


    relation_options = [
        { label: "is greater than", value: "greater_than", disabled: false },
        { label: "is less than", value: "less_than", disabled: false },
        { label: "is minimum", value: "greater_than_equal_to", disabled: false },
        { label: "is maximum", value: "less_than_equal_to", disabled: false },
        { label: "is equal to", value: "equal", disabled: false },
        { label: "is not equal to", value: "not_equal", disabled: false }
    ];

    needToUpgrade = false;
    
    componentDidMount(){
        this.getAllOrderControls();
        if(this.state.show === "edit"){
            this.getARuleForEdit();
        }
        else{
            // This is create page, get the weight dynamically
            let weight_unit = store('weight_unit') || "g";
            
            const weight_unit_label = weight_unit === "g" ? "Grams" :
            weight_unit === "lb" ? "Pounds" :
            weight_unit === "kg" ? "Kilograms" :
            weight_unit === "oz" ? "Ounce" :
            "";

            this.setState({
                weight_unit: weight_unit,
                weight_unit_label: weight_unit_label
            });
        }
        
        if (snippet_version && waio.compareVersionNumbers(snippet_version, _Modules.find(x => x._id === 209).snippet_version) === -1) {
            this.needToUpgrade = true;
        }

        const currentComponent = this;
        // Add an event listener
        document.addEventListener("wsaio-global", function(e) {
            try{
                if(e.detail && e.detail.shopData){
                    if(e.detail.shopData.install_status === "completed"){
                    const shop_themes_count = e.detail.shopData.shop_themes_count;
                    if(shop_themes_count === 0){
                        // no installation found, means this is old customer
                        currentComponent.setState({
                            old_customer: true
                        })
                    }
                }
            }
            } catch(e) { }
        });
    }

    showToastMessage(msg, error){
        this.setState({
          showToast: true,
          toastMsg: msg,
          toastError: error||false,
        });
    }
    
    dissmissToast = () =>{
        this.setState({
          showToast: false,
        });
    }    

    getARuleForEdit(){
        axios.get('/app/get-order-control/'+this.state.edit_rule_id)
        .then(r => {
            if (r && r.data) {
                var {
                    rules,
                    rules_operator,
                    message,
                    internal_name,
                    status,
                    customer_group,
                    except_logged_in,
                    except_tags,
                    excepted_tags,
                    customer_tags,
                } = r.data;

                // Based on data fetched, generate the weight unit + label
                let weight_unit = this.state.weight_unit;
                if(r.data && typeof r.data.weight_unit !== "undefined"){
                    weight_unit = r.data.weight_unit;
                }

                const weight_unit_label = weight_unit === "g" ? "Grams" :
                weight_unit === "lb" ? "Pounds" :
                weight_unit === "kg" ? "Kilograms" :
                weight_unit === "oz" ? "Ounce" :
                "";

                let state = {
                    weight_unit,
                    weight_unit_label,
                    rules,
                    rules_operator,
                    message,
                    internal_name,
                    status,
                    customer_group,
                    except_logged_in,
                    except_tags,
                    excepted_tags,
                    customer_tags,
                };

                // Appending values for opening orders 
                if(r.data && typeof r.data.opening_rules !== "undefined" && r.data.opening_rules.length > 0) state.opening_rules = r.data.opening_rules;
                if(r.data && typeof r.data.opening_rules_operator !== "undefined") state.opening_rules_operator = r.data.opening_rules_operator;
                if(r.data && typeof r.data.condition_scope !== "undefined") state.condition_scope = r.data.condition_scope;
                if(r.data && typeof r.data.opening_message !== "undefined") state.opening_message = r.data.opening_message;



                this.setState(state);
            }
            else {
                this.showToastMessage("Server error", true);
            }
        })
        .catch(error => {
            this.showToastMessage(error, true);
        });
    }

    getAllOrderControls(){
        axios.get('/app/all-order-controls/'+this.props.shop )
        .then(r => {
            if (r && r.data) {
                this.setState({allOrderControls:r.data,page_loaded:true});
            }
            else {
                this.showToastMessage("Server error", true);
            }
        })
        .catch(error => {
            this.showToastMessage("Server error", true);
        });
    }

    addCondition(isOpening = false) {
        if(isOpening){
            
            let rules = this.state.opening_rules;
            rules.push(
                { "column": "cart_original_total_price", "relation": "greater_than_equal_to", "condition": "" }
            );
            this.setState({opening_rules: rules});
            
        }
        else{
        let rules = this.state.rules;
        rules.push(
            { "column": "cart_original_total_price", "relation": "greater_than_equal_to", "condition": "" }
        );
        this.setState({rules});
    }
}

removeCondition(i, isOpening = false) {
    if(isOpening){

        let rules = this.state.opening_rules;
        rules.splice(i, 1);
        this.setState({ opening_rules: rules });
    }
    else{

        let rules = this.state.rules;
        rules.splice(i, 1);
        this.setState({ rules });
    }
}

changeConditionValue(value, id, i, isOpening = false) {
    if(isOpening){

        let rules = this.state.opening_rules;
        rules[i] = { ...rules[i], [id]: value };
        this.setState({ opening_rules: rules });
    }
    else{
        let rules = this.state.rules;
        rules[i] = { ...rules[i], [id]: value };
        this.setState({ rules });
    }
}

    renderExceptedTags(){
        var excepted_tags = this.state.excepted_tags;
        // console.log("excepted_tags", excepted_tags);
        return excepted_tags.map((tag,i) => {
            return <span key={i}>
                <Tag
                    onRemove={this.removeTag.bind(this,tag,i)}
                >{tag}</Tag>
            </span>
        });
    }

    removeTag(tag,i,el){
        var excepted_tags = this.state.excepted_tags;
        excepted_tags.splice([i],1);
        this.setState({ excepted_tags });
    }

    saveOrderRule(){
        if(Common.getPlanID() !== 1){
            if(this.validation() === true){
                this.setState({saveButtonLoading: true});
                const data = {
                    shop: this.props.shop,
                    rules: this.state.rules,
                    rules_operator: this.state.rules_operator,
                    message: this.state.message,
                    internal_name: this.state.internal_name,
                    status: this.state.status,
                    customer_group: this.state.customer_group,
                    customer_tags: this.state.customer_tags,
                    except_logged_in: this.state.except_logged_in,
                    except_tags: this.state.except_tags,
                    excepted_tags: this.state.excepted_tags,
                    opening_rules: this.state.opening_rules,
                    opening_rules_operator: this.state.opening_rules_operator,
                    condition_scope: this.state.condition_scope,
                    opening_message: this.state.opening_message,
                    weight_unit: this.state.weight_unit, // current weight unit values are stored in 
                };

                // Reset data based on condition
                if(this.state.customer_group === "all"){ // if all customer is selected, we dont need order scope
                    data.condition_scope = "all";
                    data.opening_rules_operator = "and";
                    data.opening_rules = [{ "column": "cart_original_total_price", "relation": "greater_than_equal_to", "condition": "" }];
                    data.opening_message = "";
                }
                else if (this.state.condition_scope === "all"){ // on all scope, reset opening order data
                    data.opening_rules_operator = "and";
                    data.opening_rules = [{ "column": "cart_original_total_price", "relation": "greater_than_equal_to", "condition": "" }];
                    data.opening_message = "";
                }
                else if (this.state.condition_scope === "opening_only"){ // on opening only scope, reset susequent order data
                    data.rules_operator = "and";
                    data.rules = [{ "column": "cart_original_total_price", "relation": "greater_than_equal_to", "condition": "" }];
                    data.message = "";
                }

                if(this.state.show === "edit"){
                    // /update-order-control
                    axios.put('/app/update-order-control/'+this.state.edit_rule_id, data)
                    .then(r => {
                        if (r && r.data && r.data.message) {
                            if(r.data.status === 1){
                                this.showToastMessage(r.data.message);
                                this.props.history.push(BUILD_VERSION_EXTENSION+'/min-order-control');
                                this.getAllOrderControls();
                                this.setState({show:"list"});
                            }
                            else{
                                this.showToastMessage(r.data.message, true);
                            }
                        }
                        else {
                            this.showToastMessage("Server error", true);
                        }
                        this.setState({saveButtonLoading: false})
                    })
                    .catch(error => {
                        this.showToastMessage("Server error", true);
                        this.setState({saveButtonLoading: false});
                    });
                }
                else{
                    if(Common.getPlanID() !== 1){
                        axios.post('/app/add-order-control', data)
                        .then(r => {
                            if (r && r.data && r.data.message) {
                                if(r.data.status === 1){
                                    this.showToastMessage(r.data.message);
                                    this.props.history.push(BUILD_VERSION_EXTENSION+'/min-order-control');
                                    this.getAllOrderControls();
                                    this.setState({show:"list"});
                                }
                                else{
                                    this.showToastMessage(r.data.message, true);
                                }
                            }
                            else {
                                this.showToastMessage("Server error", true);
                            }
                            this.setState({saveButtonLoading: false})
                        })
                        .catch(error => {
                            this.showToastMessage("Server error", true);
                            this.setState({saveButtonLoading: false});
                        });
                    }
                    else{
                        this.showToastMessage("Please upgrade plan to continue with Order Limit feature", true);
                    }
                }
            }
        }
        else{
            this.showToastMessage("Please upgrade plan to continue with Order Limit feature", true); 
        }
    }

    disableSaveButton(){
        return !this.validation();
    }

    validation(){
        var valid = true;
        var {excepted_tags,except_tags,rules,customer_group,customer_tags,opening_rules,condition_scope,rules_operator,opening_rules_operator} = this.state;
        if(except_tags && excepted_tags.length < 1) valid = false;
        if(customer_group === "tag_based" && customer_tags.length < 1) valid = false;
        
        if(customer_group === "all" || condition_scope !== "opening_only"){
            rules.forEach((rule, i) => {
                if(!rule.condition){ valid = false; }
                else if(rule.condition.trim() === "") { valid = false; }

                const sameConditionFound = rules_operator === "and" && rules.some((el, ruleIndex)=>{

                    // ensure it check only previous rules so error statement not repeats
                    if(ruleIndex < i){
                        if(rule.relation === el.relation && rule.column === el.column) return true;
                    }

                    return false;
                });

                if(sameConditionFound) {valid = false;}
            });
        }

        if(customer_group !== "all" && condition_scope !== "all"){
            opening_rules.forEach((rule, i) => {
                if(!rule.condition){ valid = false;  }
                else if(rule.condition.trim() === "") { valid = false;  }

                const sameConditionFound = opening_rules_operator === "and" && opening_rules.some((el, ruleIndex)=>{

                    // ensure it check only previous rules so error statement not repeats
                    if(ruleIndex < i){
                        if(rule.relation === el.relation && rule.column === el.column) return true;
                    }

                    return false;
                });

                if(sameConditionFound) {valid = false;}
            });
        }

        

        return valid;
    }

    renderCustomerTags(){
        var customer_tags = this.state.customer_tags;
        // console.log("customer_tags", customer_tags);
        return customer_tags.map((tag,i) => {
            return <span key={i}>
                <Tag
                    onRemove={this.removeCustomerTag.bind(this,tag,i)}
                >{tag}</Tag>
            </span>
        });
    }

    removeCustomerTag(tag,i,el){
        var customer_tags = this.state.customer_tags;
        customer_tags.splice([i],1);
        this.setState({ customer_tags });
    }

    deleteRule(item){
        if(window.confirm(`Delete ${item.internal_name}?`)){
            axios.delete('/app/delete-order-control/'+item._id+'/'+ this.props.shop)
            .then(r => {
                if (r && r.data && r.data.message) {
                    if(r.data.status === 1){
                        this.showToastMessage(r.data.message);
                        this.getAllOrderControls();
                    }
                    else{
                        this.showToastMessage(r.data.message, true);
                    }
                }
                else {
                    this.showToastMessage("Server error", true);
                }
            })
            .catch(error => {
                this.showToastMessage("Server error", true);
            });
        }
    }

    showTag(item){
        return item.customer_tags.map((el,i)=>{
            return i < 1 ? <span key={i}><Badge>{el}</Badge></span>:null
        });
    }

    disableRule(item){
        if(window.confirm(`Disable ${item.internal_name}?`)){
            var data = {
                shop: this.props.shop,
                _id: item._id,
                status: "disabled"
            };
            axios.put('/app/update-status-moc/'+item._id+'/', data)
            .then(r => {
                if (r && r.data && r.data.message) {
                    if(r.data.status === 1){
                        this.showToastMessage(r.data.message);
                        this.getAllOrderControls();
                    }
                    else{
                        this.showToastMessage(r.data.message, true);
                    }
                }
                else {
                    this.showToastMessage("Server error", true);
                }
            })
            .catch(error => {
                this.showToastMessage("Server error", true);
            });
        }
    }

    enableRule(item){
        var data = {
            shop: this.props.shop,
            _id: item._id,
            status: "active"
        };
        axios.put('/app/update-status-moc/'+item._id+'/', data)
        .then(r => {
            if (r && r.data && r.data.message) {
                if(r.data.status === 1){
                    this.showToastMessage(r.data.message);
                    this.getAllOrderControls();
                }
                else{
                    this.showToastMessage(r.data.message, true);
                }
            }
            else {
                this.showToastMessage("Server error", true);
            }
        })
        .catch(error => {
            this.showToastMessage("Server error", true);
        });
    }

    addCustomerTags(tags_key, temp_tags_key) {
        /* Fetch tags from state */
        var temp_customer_tags_value = this.state[temp_tags_key];
        try{
            temp_customer_tags_value = temp_customer_tags_value.replace(/,/gi, "");
        }catch(e){}
        if(temp_customer_tags_value.trim() === "") return;
    
        /* Check if tags are not already exists */
        var customer_tags = this.state[tags_key];
        if(customer_tags.some(tag=>tag.toLowerCase() === temp_customer_tags_value.toLowerCase())){
            this.showToastMessage("Tag already exists",true);
            return false;
        }
    
        /* Update tags */
        customer_tags.push(temp_customer_tags_value);
        this.setState({ [tags_key]: customer_tags, [temp_tags_key]: "" });
    }

    render() {
        const {
            rules,rules_operator,message,internal_name,customer_group,except_logged_in,except_tags,excepted_tags, temp_tag_value, temp_customer_tags_value, condition_scope, opening_rules, opening_rules_operator
        } = this.state;

        var UpgradeBanner = null;
        if(this.needToUpgrade){
            UpgradeBanner = <div style={{margin:"1rem 0rem"}}>
                <Banner status="warning" title="Upgrade your script!">
                Order Limit feature requires script upgrade. Please email us at <TextStyle variation="strong">support@digitalcoo.com</TextStyle> We will update the relevant script.
                </Banner>
            </div>;
        }

        var planUpgradeBanner = <div style={{margin:"1rem 0rem"}}><Banner status="critical" title="Action Required">
            <p>
            Minimum Order Limit feature is not available in Basic plan. Please <Link url={BUILD_VERSION_EXTENSION+"/pricing-plans"} target="APP" monochrome={true}>Upgrade plan</Link> to Professional to continue with Minimum Order Limit feature.
            </p>
        </Banner></div>;

        const customerTagsInputField = <Form onSubmit={() => {
            this.addCustomerTags("customer_tags", "temp_customer_tags_value");
        }}>
            <FormLayout>
                <FormLayout.Group>
                <TextField
                    label="Customer tags"
                    labelHidden
                    autoFocus
                    value={temp_customer_tags_value}
                    placeholder="Enter tags"
                    error={this.state.customer_tags.length === 0 ? "Enter a customer tag" : false}
                    onChange={(v)=>{
                        this.setState({temp_customer_tags_value: v});
                    }}
                    connectedRight={
                        <Button
                            primary
                            onClick={()=>{
                                this.addCustomerTags("customer_tags", "temp_customer_tags_value");
                            }}
                        >Add</Button>
                    }
                />
                <div></div>
                <div></div>
                </FormLayout.Group>
            </FormLayout>
            <small><TextStyle variation="subdued">Tags are case-insensitive. "wholesale" and "Wholesale" will have same meaning</TextStyle></small>
        </Form>;

        const tags_input = <Form onSubmit={() => {
            this.addCustomerTags("excepted_tags", "temp_tag_value");
        }}>
            <FormLayout>
                <FormLayout.Group>
                <TextField
                    label="Customer Tags"
                    labelHidden
                    autoFocus
                    placeholder="Enter tags"
                    value={temp_tag_value}
                    onChange={(v)=>{
                        this.setState({temp_tag_value: v});
                    }}
                    connectedRight={
                        <Button
                            primary
                            onClick={()=>{
                                this.addCustomerTags("excepted_tags", "temp_tag_value");
                            }}
                        >Add</Button>
                    }
                />
                <div></div>
                <div></div>
                </FormLayout.Group>
            </FormLayout>
            <small><TextStyle variation="subdued">Tags are case-insensitive. "wholesale" and "Wholesale" will have same meaning</TextStyle></small>
        </Form>;

        const except_logged_in_section = <div>
            <Checkbox
                label="Except logged in"
                id="except_logged_in"
                value={except_logged_in}
                checked={except_logged_in}
                onChange={(checked, value) => {
                    this.setState({ except_logged_in: checked });
                }}
            />
        </div>;

        const except_taged_section = <Stack vertical spacing="tight">
            <Checkbox
                label={<span>Except tags</span>}
                id="except_tags"
                value={except_tags}
                checked={except_tags}
                onChange={(checked, value) => {
                    this.setState({ except_tags: checked });
                }}
            />
            {except_tags?tags_input: null}
            {
                except_tags && excepted_tags.length > 0?
                <Stack>
                    {this.renderExceptedTags()}
                </Stack>
                : null
            }
        </Stack>;

        const Customer_section = <div>
            <Stack vertical spacing="extraTight">
                <RadioButton
                    label={<span>All Customers {Common.getPlanID() !== 3 && <span>(<Link url={BUILD_VERSION_EXTENSION+"/pricing-plans"} target="APP" monochrome={true}>Available in Business plan</Link>)</span>} </span>}
                    id="all"
                    name="customers_group"
                    checked={customer_group === "all"}
                    onChange={(checked, value) => {
                        this.setState({ customer_group: value, except_tags: false, except_logged_in: false  });
                    }}
                    disabled={Common.getPlanID() !== 3}
                />
                {
                    customer_group === "all" ?
                        <div style={{marginLeft:"20px"}}>
                            {except_logged_in_section}
                            {except_taged_section}
                        </div>
                    :null
                }
                <RadioButton
                    label="Only Customers logged in"
                    id="logged_in"
                    name="customers_group"
                    checked={customer_group === "logged_in"}
                    onChange={(checked, value) => {
                        this.setState({ customer_group: value,except_tags: false, except_logged_in: false });
                    }}
                />
                {
                    customer_group === "logged_in" ?
                        <div style={{marginLeft:"20px"}}>
                            {except_taged_section}
                        </div>
                    :null
                }
                <RadioButton
                    label={<span>Only Customers tagged and logged in</span>}
                    id="tag_based"
                    name="customers_group"
                    checked={customer_group === "tag_based"}
                    onChange={(checked, value) => {
                        this.setState({ customer_group: value, except_tags: false, except_logged_in: false  });
                    }}
                />
                {customer_group === "tag_based"?customerTagsInputField:null}
                {
                    customer_group === "tag_based" ?
                        <div style={{marginLeft:"20px"}}>
                            <Stack spacing="tight">
                                {this.renderCustomerTags()}
                            </Stack>
                        </div>
                    :null
                }
            </Stack>
        </div>;

       // For generating prefixes and placeholders
       const zeroCurrency = Common.getCurrency(0, money_format);
       const old_customer = this.state.old_customer;

       const conditions_section = rules.map((el, i) => {
           let relation_options = this.relation_options.filter(function(item){
               // Hide equal & not_equal only if not already selected
               if( !old_customer && item.value !== el.relation && ( item.value === "equal" || item.value === "not_equal" || item.value === "greater_than" || item.value === "less_than") )return false;

               return true;
           });

           // look for same conditions on preview ranges
           const sameConditionFound = rules_operator === "and" && rules.some((rule, ruleIndex)=>{

            // ensure it check only previous rules so error statement not repeats
            if(ruleIndex < i){
                if(rule.relation === el.relation && rule.column === el.column) return true;
            }

            return false;
        });
           
           return <FormLayout.Group condensed key={i} fullWidth>
                <Select
                    label="Column"
                    labelHidden={true}
                    id="column"
                    name="column"
                    value={el.column}
                    error={sameConditionFound ? "Conditions cannot be repeated" : false}
                    options={[
                        { label: "Cart total amount (subtotal)", value: "cart_original_total_price", disabled: false },
                        { label: "Cart total items (quantity)", value: "cart_item_count", disabled: false },
                        { label: `Cart total weight (${this.state.weight_unit_label})`, value: "cart_total_weight", disabled: false }
                    ]}
                    onChange={(selected, id) => { this.changeConditionValue(selected, id, i) }}
                ></Select>
                <Stack vertical spacing="extraTight">

<Select
    label="Relation"
    labelHidden={true}
    id="relation"
    name="relation"
    value={el.relation}
    options={relation_options}
    onChange={(selected, id) => { this.changeConditionValue(selected, id, i) }}
></Select>
{this.state.old_customer && ["greater_than_equal_to", "less_than_equal_to"].includes( el.relation ) && 

    <TextStyle variation="subdued">
        Script upgrade is required in order to use '<TextStyle variation="strong">Is Minimum</TextStyle>' or '<TextStyle variation="strong">Is Maximum</TextStyle>' option, please <Link external url="https://support.digitalcoo.com/hc/en-us/requests/new">contact us</Link>
    </TextStyle>
}
</Stack>
                <TextField
                    prefix={
                        el.column==="cart_original_total_price" ? zeroCurrency.symbol :
                        el.column==="cart_item_count" ? "Qty" :
                        ""
                    }
                    suffix={
                        el.column === "cart_total_weight"?(this.state.weight_unit):(null)
                    }
                    type="text"
                    label="Condition"
                    labelHidden={true}
                    id="condition"
                    name="condition"
                    value={el.condition || ""}
                    onChange={(selected, id) => { this.changeConditionValue(selected, id, i);}}
                    error={el.condition === "" ? "This field can't be blank" : false}
                    onBlur={() => {
                        if(el.condition && el.condition !== ""){
                            let currency = null;

                            // If based on total_price, we need to format it
                            if(el.column==="cart_original_total_price"){
                                currency = Common.getCurrency(el.condition, money_format).amount;
                            }
                            else if(el.column==="cart_item_count"){
                                // Make it a round number since this is Qty
                                currency = Number(el.condition).toFixed(0);
                            }
                            else{
                                currency = Number(el.condition);
                                
                                // Convert to 3 decimal points since this is weight
                                if(currency.countDecimals() > 3){
                                    currency = currency.toFixed(3);
                                }
                                else{
                                    // Just convert to srting
                                    currency = currency.toString();
                                }
                            }

                            // Checking if wrong number (Skipping for formay_money case)
                            if(isNaN(currency) && el.column!=="cart_original_total_price")currency="0";

                            // Cannot be negative
                            if(Number(currency) < 0) currency="0";

                            if(currency !== null)this.changeConditionValue(currency, "condition", i);
                        }
                    }}
                ></TextField>
                <Button
                    onClick={() => {
                        this.removeCondition(i);
                    }}
                    disabled={rules.length === 1}
                ><Icon source={DeleteMinor}/></Button>
            </FormLayout.Group>;
        });

        const opening_conditions_section = opening_rules.map((el, i) => {
            let relation_options = this.relation_options.filter(function(item){ 
                // Hide equal & not_equal only if not already selected
                if( !old_customer && item.value !== el.relation && ( item.value === "equal" || item.value === "not_equal" || item.value === "greater_than" || item.value === "less_than") )return false;

                return true;
            });

            // look for same conditions on preview ranges
            const sameConditionFound = opening_rules_operator === "and" && opening_rules.some((rule, ruleIndex)=>{

                // ensure it check only previous rules so error statement not repeats
                if(ruleIndex < i){
                    if(rule.relation === el.relation && rule.column === el.column) return true;
                }

                return false;
            });
            
            return <FormLayout.Group condensed key={i} fullWidth>
                <Select
                    label="Column"
                    labelHidden={true}
                    id="column"
                    name="column"
                    value={el.column}
                    error={sameConditionFound ? "Conditions cannot be repeated" : false}
                    options={[
                        { label: "Cart total amount (subtotal)", value: "cart_original_total_price", disabled: false },
                        { label: "Cart total items (quantity)", value: "cart_item_count", disabled: false },
                        { label: `Cart total weight (${this.state.weight_unit_label})`, value: "cart_total_weight", disabled: false }
                    ]}
                    onChange={(selected, id) => { this.changeConditionValue(selected, id, i, true) }}
                ></Select>
                <Stack vertical spacing="extraTight">

                    <Select
                        label="Relation"
                        labelHidden={true}
                        id="relation"
                        name="relation"
                        value={el.relation}
                        options={relation_options}
                        onChange={(selected, id) => { this.changeConditionValue(selected, id, i, true) }}
                    ></Select>
                    {this.state.old_customer && ["greater_than_equal_to", "less_than_equal_to"].includes( el.relation ) && 
                    
                        <TextStyle variation="subdued">
                            Script upgrade is required in order to use '<TextStyle variation="strong">Is Minimum</TextStyle>' or '<TextStyle variation="strong">Is Maximum</TextStyle>' option, please <Link external url="https://support.digitalcoo.com/hc/en-us/requests/new">contact us</Link>
                        </TextStyle>
                    }
                </Stack>
                <TextField
                    prefix={
                        el.column==="cart_original_total_price" ? zeroCurrency.symbol :
                        el.column==="cart_item_count" ? "Qty" :
                        ""
                    }
                    suffix={
                        el.column === "cart_total_weight"?(this.state.weight_unit):(null)
                    }
                    type="text"
                    label="Condition"
                    labelHidden={true}
                    id="condition"
                    name="condition"
                    value={el.condition || ""}
                    onChange={(selected, id) => { this.changeConditionValue(selected, id, i, true);}}
                    error={el.condition === "" ? "This field can't be blank" : false}
                    onBlur={() => {
                        if(el.condition && el.condition !== ""){
                            let currency = null;

                            // If based on total_price, we need to format it
                            if(el.column==="cart_original_total_price"){
                                currency = Common.getCurrency(el.condition, money_format).amount;
                            }
                            else if(el.column==="cart_item_count"){
                                // Make it a round number since this is Qty
                                currency = Number(el.condition).toFixed(0);
                            }
                            else{
                                currency = Number(el.condition);
                                
                                // Convert to 3 decimal points since this is weight
                                if(currency.countDecimals() > 3){
                                    currency = currency.toFixed(3);
                                }
                                else{
                                    // Just convert to srting
                                    currency = currency.toString();
                                }
                            }

                            // Checking if wrong number (Skipping for formay_money case)
                            if(isNaN(currency) && el.column!=="cart_original_total_price")currency="0";

                            // Cannot be negative
                            if(Number(currency) < 0) currency="0";

                            if(currency !== null)this.changeConditionValue(currency, "condition", i, true);
                        }
                    }}
                ></TextField>
                <Button
                    onClick={() => {
                        this.removeCondition(i, true);
                    }}
                    disabled={opening_rules.length === 1}
                ><Icon source={DeleteMinor}/></Button>
            </FormLayout.Group>;
        });

        const add_or_edit_section = <div>
            <Card title="Order Limit Rule" actions={[{content:"Read tutorial", url:"https://support.digitalcoo.com/hc/en-us/articles/360041737692", external: true}]}>
                <Card.Section>
                    <FormLayout fullWidth>                        
                            <TextField
                                label="Title"
                                value={internal_name || ""}
                                onChange={(value) => {
                                    this.setState({ internal_name: value });
                                }}
                                helpText="This will not appear to the customers."
                                maxLength={255}
                                showCharacterCount={true}
                                clearButton={true}
                                autoFocus={true}
                                onClearButtonClick={() => { 
                                    this.setState({ internal_name: "" });
                                }}
                                error={internal_name === "" ? "This field can't be blank" : false}
                            />
                        
                    </FormLayout>
                </Card.Section>                
                </Card>
            <Card title="Customer Selection" sectioned>
                <FormLayout fullWidth>
                    {Customer_section}
                </FormLayout>
            </Card>


            {customer_group !== "all" &&
                <Card title="Order Limit Scope" sectioned>
                    <FormLayout fullWidth>
                        <Stack vertical spacing="extraTight">
                            <RadioButton
                                label="All orders"
                                helpText="Apply standard order limit for first orders and subsequent orders (Popular)."
                                name="condition_scope"
                                checked={condition_scope === "all"}
                                onChange={(checked, value) => {
                                    this.setState({ condition_scope: "all" });
                                }}
                            />
                            <RadioButton
                                label={<span>Separate order limit for first and subsequent orders {this.state.old_customer && condition_scope === "separate" ? <TextStyle variation="negative">(Script update is required to use this option, <Link external url="https://support.digitalcoo.com/hc/en-us/requests/new">contact us</Link>)</TextStyle> : null}</span>}
                                helpText={<span>Apply a separate order limit for first order and subsequent orders.</span>}
                                name="condition_scope"
                                checked={condition_scope === "separate"}
                                onChange={(checked, value) => {
                                    this.setState({ condition_scope: "separate"  });
                                }}
                            />
                            <RadioButton
                                label={<span>First Orders Only {this.state.old_customer && condition_scope === "opening_only" ? <TextStyle variation="negative">(Script update is required to use this option, <Link external url="https://support.digitalcoo.com/hc/en-us/requests/new">contact us</Link>)</TextStyle> : null}</span>}
                                helpText={<span>Apply an order limit for first orders but not subsequent orders. </span>}
                                name="condition_scope"
                                checked={condition_scope === "opening_only"}
                                onChange={(checked, value) => {
                                    this.setState({ condition_scope: "opening_only"  });
                                }}
                            />
                        </Stack>
                    </FormLayout>
                </Card>
            }            


            {(customer_group !== "all" && condition_scope !== "all") &&
                <Card 
                    title={
                        <Stack vertical spacing="extraTight">
                            <Heading>First order conditions</Heading>
                        </Stack>
                    }
                >
                    <Card.Section>
                        <FormLayout fullWidth>
                            <div style={{width: "75%", marginBottom: "3rem"}}>
                                <TextField
                                    label="Minimum Purchase Order Requirement (Optional)"
                                    multiline
                                    value={this.state.opening_message}
                                    onChange={(v) => {
                                        this.setState({ opening_message: v });
                                    }}
                                    helpText="This message will appear to customers on the cart page and cart drawer next to the checkout button."
                                />
                            </div>
                            <FormLayout.Group>
                                <div>
                                    Must match to place order:&nbsp;&nbsp;&nbsp;
                                    <RadioButton
                                        name="opening_condition_operator"
                                        checked={opening_rules_operator === "and"}
                                        label="All conditions"
                                        onChange={(checked, value) => {
                                            this.setState({ opening_rules_operator: "and" });
                                        }}
                                    />
                                    &nbsp;&nbsp;&nbsp;
                                    <RadioButton
                                        name="opening_condition_operator"
                                        checked={opening_rules_operator === "or"}
                                        label="Any condition"
                                        onChange={(checked, value) => {
                                            this.setState({ opening_rules_operator: "or" });
                                        }}
                                    />
                                </div>
                            </FormLayout.Group>
                            <TextStyle variation="subdued">Customers cannot proceed to the checkout unless conditions and order requirements are met.</TextStyle>
                            {opening_conditions_section}
                            <FormLayout.Group fullWidth>
                                <Button
                                    disabled={opening_rules.length >= 3 ? true : false}
                                    onClick={() => {
                                        this.addCondition(true);
                                    }}
                                >
                                    Add another condition
                                </Button>
                            </FormLayout.Group>
                        </FormLayout>
                    </Card.Section>
                    <Card.Section title="Checkout will be allowed only when">
                        <TextContainer>
                            
                            <TextStyle variation="code">
                                <Stack vertical spacing="extraTight">
                                {opening_rules.map((rule, i) => {
                                    if(!rule.condition || rule.condition === "")return null; // condition is empty, a validation was added to tell customer to fill it

                                    const AndOr_text = i > 0 ? opening_rules_operator === "and" ? "AND" : "OR" : null;

                                    const column_text = rule.column === "cart_original_total_price" ? "Subtotal" :
                                    rule.column === "cart_item_count" ? "Total Quantity" :
                                    rule.column === "cart_total_weight" ? "Total Weight" :
                                    null;

                                    const relation_text = rule.relation === "greater_than_equal_to" ? "is minimum" :
                                    rule.relation === "less_than_equal_to" ? "is maximum" :
                                    rule.relation === "greater_than" ? "is greater than" :
                                    rule.relation === "less_than" ? "is less than" :
                                    rule.relation === "equal" ? "is exactly" :
                                    rule.relation === "not_equal" ? "is not equal to" :
                                    null;

                                    let condition_text = null;

                                    // If based on total_price, we need to format it
                                    if(rule.column==="cart_original_total_price"){
                                        condition_text = Common.getCurrency(rule.condition, money_format).amount_with_symbol;
                                    }
                                    else if(rule.column==="cart_item_count"){
                                        // Make it a round number since this is Qty
                                        condition_text = Number(rule.condition).toFixed(0);
                                    }
                                    else{
                                        condition_text = Number(rule.condition);
                                        
                                        // Convert to 3 decimal points since this is weight
                                        if(condition_text.countDecimals() > 3){
                                            condition_text = condition_text.toFixed(3);
                                        }
                                        else{
                                            // Just convert to srting
                                            condition_text = condition_text.toString();
                                        }

                                    
                                    }

                                    // Checking if wrong number (Skipping for formay_money case)
                                    if(isNaN(condition_text) && rule.column!=="cart_original_total_price")condition_text="0";

                                    // appending weight unit (lb,kg etc)
                                    if(rule.column==="cart_total_weight")condition_text += " " + this.state.weight_unit;


                                    return (
                                        <Stack.Item>
                                            {AndOr_text && <TextStyle variation="strong">{AndOr_text} </TextStyle>}
                                            {column_text} {relation_text} {condition_text}
                                        </Stack.Item>
                                    );
                                })}
                                </Stack>
                            </TextStyle>
                        </TextContainer>
                    </Card.Section>
                </Card>
            }


{ (customer_group === "all" || condition_scope !== "opening_only") &&
            <Card 
                title={
                    <Stack vertical spacing="extraTight">
                            <Heading>{condition_scope === "all" ? "All orders conditons" : "Subsequent orders conditions"}</Heading>
                        </Stack>
                }
            >
                <Card.Section>
                    <FormLayout fullWidth>
                            <div style={{width: "75%", marginBottom: "3rem"}}>
                                <TextField
                                    label="Minimum Purchase Order Requirement (Optional)"
                                    multiline
                                    value={message}
                                    onChange={(v) => {
                                        this.setState({ message: v });
                                    }}
                                    helpText="This message will appear to customers on the cart page and cart drawer next to the checkout button."
                                />
                          </div>
                        <FormLayout.Group>
                            <div>
                                Must match to place order:&nbsp;&nbsp;&nbsp;
                                <RadioButton
                                    name="condition_operator"
                                    checked={rules_operator === "and"}
                                    id="and"
                                    label="All conditions"
                                    onChange={(checked, value) => {
                                        this.setState({ rules_operator: value });
                                    }}
                                />
                                &nbsp;&nbsp;&nbsp;
                                <RadioButton
                                    name="condition_operator"
                                    checked={rules_operator === "or"}
                                    id="or"
                                    label="Any condition"
                                    onChange={(checked, value) => {
                                        this.setState({ rules_operator: value });
                                    }}
                                />
                            </div>
                        </FormLayout.Group>
                        <TextStyle variation="subdued">Customers cannot proceed to the checkout unless conditions and order requirements are met.</TextStyle>
                        {conditions_section}
                        <FormLayout.Group fullWidth>
                            <Button
                                disabled={rules.length >= 3 ? true : false}
                                onClick={() => {
                                    this.addCondition();
                                }}
                            >
                                Add another condition
                            </Button>
                        </FormLayout.Group>
                    </FormLayout>
                </Card.Section>
                <Card.Section title="Allow customers to place orders only when ">
                    <TextContainer>
                        
                        <TextStyle variation="code">
                            <Stack vertical spacing="extraTight">
                            {rules.map((rule, i) => {
                                if(!rule.condition || rule.condition === "")return null; // condition is empty, a validation was added to tell customer to fill it

                                const AndOr_text = i > 0 ? rules_operator === "and" ? "AND" : "OR" : null;

                                const column_text = rule.column === "cart_original_total_price" ? "Subtotal" :
                                rule.column === "cart_item_count" ? "Total Quantity" :
                                rule.column === "cart_total_weight" ? "Total Weight" :
                                null;

                                const relation_text = rule.relation === "greater_than_equal_to" ? "is minimum" :
                                rule.relation === "less_than_equal_to" ? "is maximum" :
                                rule.relation === "greater_than" ? "is greater than" :
                                rule.relation === "less_than" ? "is less than" :
                                rule.relation === "equal" ? "is exactly" :
                                rule.relation === "not_equal" ? "is not equal to" :
                                null;

                                let condition_text = null;

                                // If based on total_price, we need to format it
                                if(rule.column==="cart_original_total_price"){
                                    condition_text = Common.getCurrency(rule.condition, money_format).amount_with_symbol;
                                }
                                else if(rule.column==="cart_item_count"){
                                    // Make it a round number since this is Qty
                                    condition_text = Number(rule.condition).toFixed(0);
                                }
                                else{
                                    condition_text = Number(rule.condition);
                                    
                                    // Convert to 3 decimal points since this is weight
                                    if(condition_text.countDecimals() > 3){
                                        condition_text = condition_text.toFixed(3);
                                    }
                                    else{
                                        // Just convert to srting
                                        condition_text = condition_text.toString();
                                    }

                                   
                                }

                                // Checking if wrong number (Skipping for formay_money case)
                                if(isNaN(condition_text) && rule.column!=="cart_original_total_price")condition_text="0";

                                // appending weight unit (lb,kg etc)
                                if(rule.column==="cart_total_weight")condition_text += " " + this.state.weight_unit;


                                return (
                                    <Stack.Item>
                                        {AndOr_text && <TextStyle variation="strong">{AndOr_text} </TextStyle>}
                                        {column_text} {relation_text} {condition_text}
                                    </Stack.Item>
                                );
                            })}
                            </Stack>
                        </TextStyle>
                    </TextContainer>
                </Card.Section>
            </Card>
    }

            <div className="bottom-line"></div>
            <div className="bottom-save-buttons">
                <Button primary
                    icon={Common.getPlanID() === 1?LockMinor:false}
                    onClick = { () => this.saveOrderRule() }
                    disabled = { Common.getPlanID() === 1?true:this.needToUpgrade?true:this.disableSaveButton()}
                    loading = { this.state.saveButtonLoading }
                >Save</Button>
            </div>
            <div className="clearfix"></div>
        </div>;

        var toast = this.state.showToast? (
            <ToastMessage
              onDismiss={this.dissmissToast}
              message={this.state.toastMsg}
              error={this.state.toastError}
            />
        ): (null);

        return (
            <div>
                <TitleBar
                    title={this.state.show === "add"?"Create rule":"Edit rule"}
                    breadcrumbs={[{content: 'Order Limit', url: BUILD_VERSION_EXTENSION+'/min-order-control', target: 'APP'}]}
                    primaryAction={{content:"Save", onAction: () => this.saveOrderRule(), disabled: Common.getPlanID() === 1?true:this.needToUpgrade? true :this.disableSaveButton()? true: this.state.saveButtonLoading }}
                    secondaryActions={[{content: 'Back', url: BUILD_VERSION_EXTENSION+"/min-order-control" , target: 'APP'}]}
                />
                {toast}
                {
                    Common.getPlanID() === 1 ? planUpgradeBanner
                    : this.needToUpgrade ? UpgradeBanner : null
                }
                {add_or_edit_section}
                {
                    this.state.saveButtonLoading &&
                    <LoadingOverlay
                        message="Saving Order Limit rule. Please wait..."
                    />
                }
            </div>
        );
    }
} 
