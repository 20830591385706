var template = {};
var ws_account = {};
var net_terms = {};

// This email template will be used to deliver an email to store owner upon successfull registration
ws_account["account_request_owner"] = {
    to: ``,//this is dynamic from the app
    from: ``,// this is dynamic from the app,
    subject: `New Wholesale Customer Notification`,
    html: `<div class=""><div class="aHl"></div><div id=":16m" tabindex="-1"></div><div id=":16b" class="ii gt"><div id=":16a" class="a3s aXjCH msg7353753856231098037"><u></u>
    <div style="margin:0">
    <table style="height:100%!important;width:100%!important;border-spacing:0;border-collapse:collapse">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <table class="m_7353753856231098037header" style="width:100%;border-spacing:0;border-collapse:collapse;margin:40px 0 20px">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <center>
    <table class="m_7353753856231098037container" style="width:560px;text-align:left;border-spacing:0;border-collapse:collapse;margin:0 auto">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <table style="width:100%;border-spacing:0;border-collapse:collapse">
    <tbody><tr>
    <td class="m_7353753856231098037shop-name__cell" style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <h1 style="font-weight:normal;font-size:30px;color:#333;margin:0">
    [[store_name]]
    </h1>
    </td>
    </tr>
    </tbody></table>
    </td>
    </tr>
    </tbody></table></center>
    </td>
    </tr>
    </tbody></table>
    <table style="width:100%;border-spacing:0;border-collapse:collapse">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif;padding-bottom:40px">
    <center>
    <table class="m_7353753856231098037container" style="width:560px;text-align:left;border-spacing:0;border-collapse:collapse;margin:0 auto">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <h2 style="font-weight:normal;font-size:24px;margin:0 0 10px">Approve customer</h2>
    <p style="color:#777;line-height:150%;font-size:16px;margin:0">A new customer has submitted the wholesale account opening request and waiting for wholesale account approval. This link can be used to approve customer access to wholesale account.</p>
    <table  style="width:100%;border-spacing:0;border-collapse:collapse;margin-top:20px">			
    [[customer_details]]		
    </table>
    <table style="width:100%;border-spacing:0;border-collapse:collapse;margin-top:20px">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif;line-height:0.5em">&nbsp;</td>
    </tr>
    <tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <table class="m_7353753856231098037button m_7353753856231098037main-action-cell" style="border-spacing:0;border-collapse:collapse;float:left;margin-right:15px">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif;border-radius:4px" align="center" bgcolor="#1990C6"><a href="[[approve_customer_link]]" class="m_7353753856231098037button__text" style="font-size:16px;text-decoration:none;display:block;color:#fff;padding:20px 25px" target="_blank" >View and Approve customer</a></td>
    </tr>
    </tbody></table>
    </td>
    </tr>
    </tbody></table>
    </td>
    </tr>
    </tbody></table>
    </center>
    </td>
    </tr>
    </tbody></table>
    </td>
    </tr>
    </tbody></table><div class="yj6qo"></div><div class="adL">
    </div></div><div class="adL">
    </div></div></div><div id=":16r" class="ii gt" style="display:none"><div id=":16q" class="a3s aXjCH undefined"></div></div><div class="hi"></div></div>`
};

// This email template will be used to deliver an email to customer upon successfull registration
ws_account["account_request_customer"] = {
    to: ``,//this is dynamic from the app
    from: ``,// this is dynamic from the app,
    subject: ``,
    html: `<div class=""><div class="aHl"></div><div id=":16m" tabindex="-1"></div><div id=":16b" class="ii gt"><div id=":16a" class="a3s aXjCH msg7353753856231098037"><u></u>
    <div style="margin:0">
    <table style="height:100%!important;width:100%!important;border-spacing:0;border-collapse:collapse">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <table class="m_7353753856231098037header" style="width:100%;border-spacing:0;border-collapse:collapse;margin:40px 0 20px">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <center>
    <table class="m_7353753856231098037container" style="width:560px;text-align:left;border-spacing:0;border-collapse:collapse;margin:0 auto">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <table style="width:100%;border-spacing:0;border-collapse:collapse">
    <tbody><tr>
    <td class="m_7353753856231098037shop-name__cell" style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <h1 style="font-weight:normal;font-size:30px;color:#333;margin:0">
    [[store_name]]
    </h1>
    </td>
    </tr>
    </tbody></table>
    </td>
    </tr>
    </tbody></table>
    </center>
    </td>
    </tr>
    </tbody></table>
    <table style="width:100%;border-spacing:0;border-collapse:collapse">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif;padding-bottom:40px">
    <center>
    <table class="m_7353753856231098037container" style="width:560px;text-align:left;border-spacing:0;border-collapse:collapse;margin:0 auto">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <h2 style="font-weight:normal;font-size:24px;margin:0 0 10px">Activate your account</h2>
    <p style="color:#777;line-height:150%;font-size:16px;margin:0">Hi [[customer_first_name]], you've created a new customer account at [[store_name]]. All you have to do is activate it.</p>
    <table style="width:100%;border-spacing:0;border-collapse:collapse;margin-top:20px">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif;line-height:0.5em">&nbsp;</td>
    </tr>
    <tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <table class="m_7353753856231098037button m_7353753856231098037main-action-cell" style="border-spacing:0;border-collapse:collapse;float:left;margin-right:15px">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif;border-radius:4px" align="center" bgcolor="#1990C6"><a href="[[activate_account_link]]" class="m_7353753856231098037button__text" style="font-size:16px;text-decoration:none;display:block;color:#fff;padding:20px 25px" target="_blank" >Activate your account</a></td>
    </tr>
    </tbody></table>
    <table class="m_7353753856231098037secondary-action-cell" style="border-spacing:0;border-collapse:collapse;margin-top:19px">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">or <a href="[[store_link]]" style="font-size:16px;text-decoration:none;color:#1990c6" target="_blank" >Visit our store</a>
    </td>
    </tr>
    </tbody></table>
    </td>
    </tr>
    </tbody></table>
    </td>
    </tr>
    </tbody></table>
    </center>
    </td>
    </tr>
    </tbody></table>
    <table style="width:100%;border-spacing:0;border-collapse:collapse;border-top-width:1px;border-top-color:#e5e5e5;border-top-style:solid">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif;padding:35px 0">
    <center>
    <table class="m_7353753856231098037container" style="width:560px;text-align:left;border-spacing:0;border-collapse:collapse;margin:0 auto">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <p style="color:#999;line-height:150%;font-size:14px;margin:0">If you have any questions, reply to this email.</p>
    </td>
    </tr>
    </tbody></table>
    </center>
    </td>
    </tr>
    </tbody></table>
    </td>
    </tr>
    </tbody></table><div class="yj6qo"></div><div class="adL">
    </div></div><div class="adL">
    </div></div></div><div id=":16r" class="ii gt" style="display:none"><div id=":16q" class="a3s aXjCH undefined"></div></div><div class="hi"></div></div>`
};


// When customer's wholesale account approved [case: customer has an account, but not wholesale tag]
ws_account["account_approved"] = {
    to: ``,//this is dynamic from the app
    from: ``,// this is dynamic from the app,
    subject: `Wholesale Account Request Approved`,
    html: `<div class=""><div class="aHl"></div><div id=":16m" tabindex="-1"></div><div id=":16b" class="ii gt"><div id=":16a" class="a3s aXjCH msg7353753856231098037"><u></u>
    <div style="margin:0">
    <table style="height:100%!important;width:100%!important;border-spacing:0;border-collapse:collapse">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <table class="m_7353753856231098037header" style="width:100%;border-spacing:0;border-collapse:collapse;margin:40px 0 20px">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <center>
    <table class="m_7353753856231098037container" style="width:560px;text-align:left;border-spacing:0;border-collapse:collapse;margin:0 auto">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <table style="width:100%;border-spacing:0;border-collapse:collapse">
    <tbody><tr>
    <td class="m_7353753856231098037shop-name__cell" style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <h1 style="font-weight:normal;font-size:30px;color:#333;margin:0">
    [[store_name]]
    </h1>
    </td>
    </tr>
    </tbody></table>
    </td>
    </tr>
    </tbody></table>
    </center>
    </td>
    </tr>
    </tbody></table>
    <table style="width:100%;border-spacing:0;border-collapse:collapse">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif;padding-bottom:40px">
    <center>
    <table class="m_7353753856231098037container" style="width:560px;text-align:left;border-spacing:0;border-collapse:collapse;margin:0 auto">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <h2 style="font-weight:normal;font-size:24px;margin:0 0 10px">Login</h2>
    <p style="color:#777;line-height:150%;font-size:16px;margin:0">Hi [[customer_first_name]], you've created a new customer account at [[store_name]]. All you have to do is login.</p>
    <table style="width:100%;border-spacing:0;border-collapse:collapse;margin-top:20px">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif;line-height:0.5em">&nbsp;</td>
    </tr>
    <tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <table class="m_7353753856231098037button m_7353753856231098037main-action-cell" style="border-spacing:0;border-collapse:collapse;float:left;margin-right:15px">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif;border-radius:4px" align="center" bgcolor="#1990C6"><a href="[[activate_account_link]]" class="m_7353753856231098037button__text" style="font-size:16px;text-decoration:none;display:block;color:#fff;padding:20px 25px" target="_blank" >Login</a></td>
    </tr>
    </tbody></table>
    </td>
    </tr>
    </tbody></table>
    </td>
    </tr>
    </tbody></table>
    </center>
    </td>
    </tr>
    </tbody></table>
    <table style="width:100%;border-spacing:0;border-collapse:collapse;border-top-width:1px;border-top-color:#e5e5e5;border-top-style:solid">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif;padding:35px 0">
    <center>
    <table class="m_7353753856231098037container" style="width:560px;text-align:left;border-spacing:0;border-collapse:collapse;margin:0 auto">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <p style="color:#999;line-height:150%;font-size:14px;margin:0">If you have any questions, reply to this email.</p>
    </td>
    </tr>
    </tbody></table>
    </center>
    </td>
    </tr>
    </tbody></table>
    </td>
    </tr>
    </tbody></table><div class="yj6qo"></div><div class="adL">
    </div></div><div class="adL">
    </div></div></div><div id=":16r" class="ii gt" style="display:none"><div id=":16q" class="a3s aXjCH undefined"></div></div><div class="hi"></div></div>
    `
};

// When customer's wholesale account approved [case: customer never has an account]
ws_account["account_approved1"] = {
    to: ``,//this is dynamic from the app
    from: ``,// this is dynamic from the app,
    subject: `Wholesale Account Request Approved`,
    html: `<div class=""><div class="aHl"></div><div id=":16m" tabindex="-1"></div><div id=":16b" class="ii gt"><div id=":16a" class="a3s aXjCH msg7353753856231098037"><u></u>
    <div style="margin:0">
    <table style="height:100%!important;width:100%!important;border-spacing:0;border-collapse:collapse">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <table class="m_7353753856231098037header" style="width:100%;border-spacing:0;border-collapse:collapse;margin:40px 0 20px">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <center>
    <table class="m_7353753856231098037container" style="width:560px;text-align:left;border-spacing:0;border-collapse:collapse;margin:0 auto">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <table style="width:100%;border-spacing:0;border-collapse:collapse">
    <tbody><tr>
    <td class="m_7353753856231098037shop-name__cell" style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <h1 style="font-weight:normal;font-size:30px;color:#333;margin:0">
    [[store_name]]
    </h1>
    </td>
    </tr>
    </tbody></table>
    </td>
    </tr>
    </tbody></table>
    </center>
    </td>
    </tr>
    </tbody></table>
    <table style="width:100%;border-spacing:0;border-collapse:collapse">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif;padding-bottom:40px">
    <center>
    <table class="m_7353753856231098037container" style="width:560px;text-align:left;border-spacing:0;border-collapse:collapse;margin:0 auto">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <h2 style="font-weight:normal;font-size:24px;margin:0 0 10px">Activate your account</h2>
    <p style="color:#777;line-height:150%;font-size:16px;margin:0">Hi [[customer_first_name]], you've created a new customer account at [[store_name]]. All you have to do is activate it.</p>
    <table style="width:100%;border-spacing:0;border-collapse:collapse;margin-top:20px">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif;line-height:0.5em">&nbsp;</td>
    </tr>
    <tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <table class="m_7353753856231098037button m_7353753856231098037main-action-cell" style="border-spacing:0;border-collapse:collapse;float:left;margin-right:15px">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif;border-radius:4px" align="center" bgcolor="#1990C6"><a href="[[activate_account_link]]" class="m_7353753856231098037button__text" style="font-size:16px;text-decoration:none;display:block;color:#fff;padding:20px 25px" target="_blank">Activate your account</a></td>
    </tr>
    </tbody></table>
    </td>
    </tr>
    </tbody></table>
    </td>
    </tr>
    </tbody></table>
    </center>
    </td>
    </tr>
    </tbody></table>
    <table style="width:100%;border-spacing:0;border-collapse:collapse;border-top-width:1px;border-top-color:#e5e5e5;border-top-style:solid">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif;padding:35px 0">
    <center>
    <table class="m_7353753856231098037container" style="width:560px;text-align:left;border-spacing:0;border-collapse:collapse;margin:0 auto">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <p style="color:#999;line-height:150%;font-size:14px;margin:0">If you have any questions, reply to this email.</p>
    </td>
    </tr>
    </tbody></table>
    </center>
    </td>
    </tr>
    </tbody></table>
    </td>
    </tr>
    </tbody></table><div class="yj6qo"></div><div class="adL">
    </div></div><div class="adL">
    </div></div></div><div id=":16r" class="ii gt" style="display:none"><div id=":16q" class="a3s aXjCH undefined"></div></div><div class="hi"></div></div>
    `
};


// When customer's wholesale account denied for any reason.
ws_account["account_rejected"] = {
    to: ``,//this is dynamic from the app
    from: ``,// this is dynamic from the app,
    subject: `Wholesale Account Request Denied`,
    html: `<div class=""><div class="aHl"></div><div id=":16m" tabindex="-1"></div><div id=":16b" class="ii gt"><div id=":16a" class="a3s aXjCH msg7353753856231098037"><u></u>
    <div style="margin:0">
    <table style="height:100%!important;width:100%!important;border-spacing:0;border-collapse:collapse">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <table class="m_7353753856231098037header" style="width:100%;border-spacing:0;border-collapse:collapse;margin:40px 0 20px">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <center>
    <table class="m_7353753856231098037container" style="width:560px;text-align:left;border-spacing:0;border-collapse:collapse;margin:0 auto">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <table style="width:100%;border-spacing:0;border-collapse:collapse">
    <tbody><tr>
    <td class="m_7353753856231098037shop-name__cell" style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <h1 style="font-weight:normal;font-size:30px;color:#333;margin:0">
    [[store_name]]
    </h1>
    </td>
    </tr>
    </tbody></table>
    </td>
    </tr>
    </tbody></table>
    </center>
    </td>
    </tr>
    </tbody></table>
    <table style="width:100%;border-spacing:0;border-collapse:collapse">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif;padding-bottom:40px">
    <center>
    <table class="m_7353753856231098037container" style="width:560px;text-align:left;border-spacing:0;border-collapse:collapse;margin:0 auto">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <h2 style="font-weight:normal;font-size:24px;margin:0 0 10px">Your account request is rejected</h2>
    <p style="color:#777;line-height:150%;font-size:16px;margin:0">Hi [[customer_first_name]], Your wholesale account request is rejected for [[reason_for_decline]]. If you have any questions, you can simply reply to this email. </p>
    <table style="width:100%;border-spacing:0;border-collapse:collapse;margin-top:20px">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif;line-height:0.5em">&nbsp;</td>
    </tr>
    <tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <table class="m_7353753856231098037secondary-action-cell" style="border-spacing:0;border-collapse:collapse;margin-top:19px">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">or <a href="[[store_link]]" style="font-size:16px;text-decoration:none;color:#1990c6" target="_blank">Visit our store</a>
    </td>
    </tr>
    </tbody></table>
    </td>
    </tr>
    </tbody></table>
    </td>
    </tr>
    </tbody></table>
    </center>
    </td>
    </tr>
    </tbody></table>
    <table style="width:100%;border-spacing:0;border-collapse:collapse;border-top-width:1px;border-top-color:#e5e5e5;border-top-style:solid">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif;padding:35px 0">
    <center>
    <table class="m_7353753856231098037container" style="width:560px;text-align:left;border-spacing:0;border-collapse:collapse;margin:0 auto">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <p style="color:#999;line-height:150%;font-size:14px;margin:0">If you have any questions, reply to this email.</p>
    </td>
    </tr>
    </tbody></table>
    </center>
    </td>
    </tr>
    </tbody></table>
    </td>
    </tr>
    </tbody></table><div class="yj6qo"></div><div class="adL">
    </div></div><div class="adL">
    </div></div></div><div id=":16r" class="ii gt" style="display:none"><div id=":16q" class="a3s aXjCH undefined"></div></div><div class="hi"></div></div>
    `
};

// An email notification to shop owner [shop customer email]
/**

[[order_number]]
[[customer_first_name]]
[[customer_last_name]]
[[customer_full_name]]
[[store_contact_email]]
[[line_items]]
[[entire_order_subtotal]]
[[entire_order_shipping_cost]]
[[order_total_price]]
[[you_saved_price]]
[[entire_order_discount]]
[[shipping_method]]
[[shipping_address]]
[[billing_address]]

 * **/
net_terms.owner = {
    to: "",
    from: "",
    subject: "New Net Order Received",
    html: `
    <html><head><meta http-equiv="Content-Type" content="text/html; charset=windows-1252"></head><body><div style="margin:0">
    <table style="height:100%!important;width:100%!important;border-spacing:0;border-collapse:collapse">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <table class="m_53005864149787142header" style="width:100%;border-spacing:0;border-collapse:collapse;margin:40px 0 20px">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <center>
    <table class="m_53005864149787142container" style="width:560px;text-align:left;border-spacing:0;border-collapse:collapse;margin:0 auto">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <table style="width:100%;border-spacing:0;border-collapse:collapse">
    <tbody><tr>
    <td class="m_53005864149787142shop-name__cell" style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <h1 style="font-weight:normal;font-size:30px;color:#333;margin:0">
    Net Term Order Received
    </h1>
    </td>
    <td class="m_53005864149787142order-number__cell" style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif;text-transform:uppercase;font-size:14px;color:#999" align="right">
    <span style="font-size:16px">
    <span class="il">Order</span> [[order_number]]
    </span>
    </td>
    </tr>
    </tbody></table>
    </td>
    </tr>
    </tbody></table>
    </center>
    </td>
    </tr>
    </tbody></table>
    <table style="width:100%;border-spacing:0;border-collapse:collapse">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif;padding-bottom:40px">
    <center>
    <table class="m_53005864149787142container" style="width:560px;text-align:left;border-spacing:0;border-collapse:collapse;margin:0 auto">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">

    <p style="color:#777;line-height:150%;font-size:16px;margin:0">
    You have received a NET Term order from customer [[customer_first_name]].
    </p>
    </td>
    </tr>
    </tbody></table>
    </center>
    </td>
    </tr>
    </tbody></table>
    <table style="width:100%;border-spacing:0;border-collapse:collapse;border-top-width:1px;border-top-color:#e5e5e5;border-top-style:solid">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif;padding:40px 0">
    <center>
    <table class="m_53005864149787142container" style="width:560px;text-align:left;border-spacing:0;border-collapse:collapse;margin:0 auto">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <h3 style="font-weight:normal;font-size:20px;margin:0 0 25px"><span class="il">Order</span> summary</h3>
    </td>
    </tr>
    </tbody></table>
    <table class="m_53005864149787142container" style="width:560px;text-align:left;border-spacing:0;border-collapse:collapse;margin:0 auto">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <table style="width:100%;border-spacing:0;border-collapse:collapse">
    <tbody>
    [[line_items]]
    </tbody>
    </table>
    <table style="width:100%;border-spacing:0;border-collapse:collapse;margin-top:15px;border-top-width:1px;border-top-color:#e5e5e5;border-top-style:solid">
    <tbody><tr>
    <td class="m_53005864149787142subtotal-spacer" style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif;width:40%"></td>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <table style="width:100%;border-spacing:0;border-collapse:collapse;margin-top:20px">
    <tbody>
        [[entire_order_discount]]
        [[entire_order_subtotal]]
        [[entire_order_shipping_cost]]
    </tbody></table>
    <table style="width:100%;border-spacing:0;border-collapse:collapse;margin-top:20px;border-top-width:2px;border-top-color:#e5e5e5;border-top-style:solid">
    <tbody>
    [[order_total_price]]
    </tbody></table>
    [[you_saved_price]]
    </td>
    </tr>
    </tbody></table>
    </td>
    </tr>
    </tbody></table>
    </center>
    </td>
    </tr>
    </tbody></table>
    <table style="width:100%;border-spacing:0;border-collapse:collapse;border-top-width:1px;border-top-color:#e5e5e5;border-top-style:solid">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif;padding:40px 0">
    <center>
    <table class="m_53005864149787142container" style="width:560px;text-align:left;border-spacing:0;border-collapse:collapse;margin:0 auto">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <h3 style="font-weight:normal;font-size:20px;margin:0 0 25px">Customer information</h3>
    </td>
    </tr>
    </tbody></table>
    <table class="m_53005864149787142container" style="width:560px;text-align:left;border-spacing:0;border-collapse:collapse;margin:0 auto">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <table style="width:100%;border-spacing:0;border-collapse:collapse">
    <tbody><tr>
    <td class="m_53005864149787142customer-info__item" style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif;padding-bottom:40px;width:50%">
    <h4 style="font-weight:500;font-size:16px;color:#555;margin:0 0 5px">Shipping address</h4>
    [[shipping_address]]
    </td>
    <td class="m_53005864149787142customer-info__item" style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif;padding-bottom:40px;width:50%">
    <h4 style="font-weight:500;font-size:16px;color:#555;margin:0 0 5px">Billing address</h4>
    [[billing_address]]
    </td>
    </tr>
    </tbody></table>
    <table style="width:100%;border-spacing:0;border-collapse:collapse">
    <tbody><tr>
    <td class="m_53005864149787142customer-info__item" style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif;padding-bottom:40px;width:50%">
    <h4 style="font-weight:500;font-size:16px;color:#555;margin:0 0 5px">Shipping method</h4>
    [[shipping_method]]
    </td>
    </tr>
    </tbody></table>
    </td>
    </tr>
    </tbody></table>
    </center>
    </td>
    </tr>
    </tbody></table>
    <table style="width:100%;border-spacing:0;border-collapse:collapse;border-top-width:1px;border-top-color:#e5e5e5;border-top-style:solid">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif;padding:35px 0">
    <center>
    <table class="m_53005864149787142container" style="width:560px;text-align:left;border-spacing:0;border-collapse:collapse;margin:0 auto">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <p style="color:#999;line-height:150%;font-size:14px;margin:0">If you have any questions, reply to this email.</p>
    </td>
    </tr>
    </tbody></table>
    </center>
    </td>
    </tr>
    </tbody></table>
    <img src="./order confirm customer email_1580728881_files/unnamed(2).png" class="m_53005864149787142spacer CToWUd" height="1" style="min-width:600px;height:0">
    </td>
    </tr>
    </tbody></table><div class="yj6qo"></div><div class="adL">
    </div></div></body></html>    
`
}

// An email notification to client, who placed net term order [to user email]
net_terms.customer = {
    to: "",
    from: "",
    subject: "Email regarding your recent order",
    html: `
    <html><head><meta http-equiv="Content-Type" content="text/html; charset=windows-1252"></head><body><div style="margin:0">
    <table style="height:100%!important;width:100%!important;border-spacing:0;border-collapse:collapse">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <table class="m_53005864149787142header" style="width:100%;border-spacing:0;border-collapse:collapse;margin:40px 0 20px">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <center>
    <table class="m_53005864149787142container" style="width:560px;text-align:left;border-spacing:0;border-collapse:collapse;margin:0 auto">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <table style="width:100%;border-spacing:0;border-collapse:collapse">
    <tbody><tr>
    <td class="m_53005864149787142shop-name__cell" style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <h1 style="font-weight:normal;font-size:30px;color:#333;margin:0">
    Net Term Order
    </h1>
    </td>
    <td class="m_53005864149787142order-number__cell" style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif;text-transform:uppercase;font-size:14px;color:#999" align="right">
    <span style="font-size:16px">
    <span class="il">Order</span> [[order_number]]
    </span>
    </td>
    </tr>
    </tbody></table>
    </td>
    </tr>
    </tbody></table>
    </center>
    </td>
    </tr>
    </tbody></table>
    <table style="width:100%;border-spacing:0;border-collapse:collapse">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif;padding-bottom:40px">
    <center>
    <table class="m_53005864149787142container" style="width:560px;text-align:left;border-spacing:0;border-collapse:collapse;margin:0 auto">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <h2 style="font-weight:normal;font-size:24px;margin:0 0 10px">Thank you for your purchase! </h2>
    <p style="color:#777;line-height:150%;font-size:16px;margin:0">
    Hi [[customer_first_name]], we're getting your <span class="il">order</span> ready to be shipped. We will notify you when it has been sent.
    </p>
    </td>
    </tr>
    </tbody></table>
    </center>
    </td>
    </tr>
    </tbody></table>
    <table style="width:100%;border-spacing:0;border-collapse:collapse;border-top-width:1px;border-top-color:#e5e5e5;border-top-style:solid">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif;padding:40px 0">
    <center>
    <table class="m_53005864149787142container" style="width:560px;text-align:left;border-spacing:0;border-collapse:collapse;margin:0 auto">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <h3 style="font-weight:normal;font-size:20px;margin:0 0 25px"><span class="il">Order</span> summary</h3>
    </td>
    </tr>
    </tbody></table>
    <table class="m_53005864149787142container" style="width:560px;text-align:left;border-spacing:0;border-collapse:collapse;margin:0 auto">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <table style="width:100%;border-spacing:0;border-collapse:collapse">
    <tbody>
    [[line_items]]
    </tbody>
    </table>
    <table style="width:100%;border-spacing:0;border-collapse:collapse;margin-top:15px;border-top-width:1px;border-top-color:#e5e5e5;border-top-style:solid">
    <tbody><tr>
    <td class="m_53005864149787142subtotal-spacer" style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif;width:40%"></td>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <table style="width:100%;border-spacing:0;border-collapse:collapse;margin-top:20px">
    <tbody>
        [[entire_order_discount]]
        [[entire_order_subtotal]]
        [[entire_order_shipping_cost]]
    </tbody></table>
    <table style="width:100%;border-spacing:0;border-collapse:collapse;margin-top:20px;border-top-width:2px;border-top-color:#e5e5e5;border-top-style:solid">
    <tbody>
    [[order_total_price]]
    </tbody></table>
    [[you_saved_price]]
    </td>
    </tr>
    </tbody></table>
    </td>
    </tr>
    </tbody></table>
    </center>
    </td>
    </tr>
    </tbody></table>
    <table style="width:100%;border-spacing:0;border-collapse:collapse;border-top-width:1px;border-top-color:#e5e5e5;border-top-style:solid">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif;padding:40px 0">
    <center>
    <table class="m_53005864149787142container" style="width:560px;text-align:left;border-spacing:0;border-collapse:collapse;margin:0 auto">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <h3 style="font-weight:normal;font-size:20px;margin:0 0 25px">Customer information</h3>
    </td>
    </tr>
    </tbody></table>
    <table class="m_53005864149787142container" style="width:560px;text-align:left;border-spacing:0;border-collapse:collapse;margin:0 auto">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <table style="width:100%;border-spacing:0;border-collapse:collapse">
    <tbody><tr>
    <td class="m_53005864149787142customer-info__item" style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif;padding-bottom:40px;width:50%">
    <h4 style="font-weight:500;font-size:16px;color:#555;margin:0 0 5px">Shipping address</h4>
    [[shipping_address]]
    </td>
    <td class="m_53005864149787142customer-info__item" style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif;padding-bottom:40px;width:50%">
    <h4 style="font-weight:500;font-size:16px;color:#555;margin:0 0 5px">Billing address</h4>
    [[billing_address]]
    </td>
    </tr>
    </tbody></table>
    <table style="width:100%;border-spacing:0;border-collapse:collapse">
    <tbody><tr>
    <td class="m_53005864149787142customer-info__item" style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif;padding-bottom:40px;width:50%">
    <h4 style="font-weight:500;font-size:16px;color:#555;margin:0 0 5px">Shipping method</h4>
    [[shipping_method]]
    </td>
    </tr>
    </tbody></table>
    </td>
    </tr>
    </tbody></table>
    </center>
    </td>
    </tr>
    </tbody></table>
    <table style="width:100%;border-spacing:0;border-collapse:collapse;border-top-width:1px;border-top-color:#e5e5e5;border-top-style:solid">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif;padding:35px 0">
    <center>
    <table class="m_53005864149787142container" style="width:560px;text-align:left;border-spacing:0;border-collapse:collapse;margin:0 auto">
    <tbody><tr>
    <td style="font-family:-apple-system,BlinkMacSystemFont,&quot;Segoe UI&quot;,&quot;Roboto&quot;,&quot;Oxygen&quot;,&quot;Ubuntu&quot;,&quot;Cantarell&quot;,&quot;Fira Sans&quot;,&quot;Droid Sans&quot;,&quot;Helvetica Neue&quot;,sans-serif">
    <p style="color:#999;line-height:150%;font-size:14px;margin:0">If you have any questions, reply to this email.</p>
    </td>
    </tr>
    </tbody></table>
    </center>
    </td>
    </tr>
    </tbody></table>
    <img src="./order confirm customer email_1580728881_files/unnamed(2).png" class="m_53005864149787142spacer CToWUd" height="1" style="min-width:600px;height:0">
    </td>
    </tr>
    </tbody></table><div class="yj6qo"></div><div class="adL">
    </div></div></body></html>
    `
}



template.ws_account = ws_account;
template.net_terms = net_terms;
module.exports = template;