import React, { Component } from 'react';
import { Layout, Card, Heading, Link, TextContainer } from '@shopify/polaris';
const BUILD_VERSION_EXTENSION =  process.env.REACT_APP_BUILD_VERSION_EXTENSION;

export default class ShopInfo extends Component{
   constructor(props){
      super(props);
      this.state = {
         shopDetail: {},
         page_loaded: false,
         showToast: false,
         toastMsg: "",
         toastError: false,
      };
   }

   render(){

      return(
         <Layout>
            <Layout.Section>
               <Card>
                  <Card.Section>
                     <TextContainer>
                        <Heading>Oops! This page could not be found!</Heading>
                        <p>
                           The page you are looking for does not exist, have been removed or name changed.
                        </p>
                        <p>You might be looking for <Link url={BUILD_VERSION_EXTENSION+'/dashboard'}>Dashboard</Link></p>
                     </TextContainer>
                  </Card.Section>
               </Card>
            </Layout.Section>
         </Layout>
      );
   }

}
