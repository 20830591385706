import React, { Component } from 'react';
import { Button, TextField, TextStyle, Stack, Icon, Card, Form, FormLayout } from '@shopify/polaris';
import { ChevronDownMinor, ChatMajor } from '@shopify/polaris-icons';
import store from 'store2';

class Chatbot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chatVisible: false,
            messages: this.props.messages || [],
            inputText: '',
            typing: false,
            socket: this.props.socket || null,
        };
    }

    toggleChatbox = () => {
        if(this.state.chatVisible) { 
            document.querySelector('#chatbox').classList.remove('slide-in-up');
            document.querySelector('#chatbox').classList.add('slide-out-down');
            setTimeout(() => {
                this.setState({ chatVisible: false });
            }, 400);
        }
        else {
            this.setState({ chatVisible: true }, () => {
                document.querySelector('#chatbox').classList.remove('slide-out-down');
                document.querySelector('#chatbox').classList.add('slide-in-up');
            });
            // could be unncecessary
            if(this.state.socket === null){
                this.showTypingIndicator();
                const socket = new WebSocket('wss://wsaioserver.digitalcoo.com/');
                this.setState({ socket }, () => {
                    // Handle WebSocket open
                    if(this.state.messages.length === 0) {
                        socket.onopen = () => {
                            this.removeTypingIndicator();
                            this.welcomeMessage();
                        };
                        
                    }
                    else {
                        this.removeTypingIndicator();
                    }
                });
            }
        }
    };

    sendMessage = () => {
        const { inputText } = this.state;
        const trimmedText = inputText.trim();

        if (trimmedText) {
            this.addMessage(trimmedText, 'user');
            this.setState({ inputText: '' }, async () => {
                this.showTypingIndicator();
                let { socket } = this.state;
                // Track the latest message
                let latestMessage = '';

                // Send the message to the chatbot
                if(socket.readyState === socket.OPEN) {
                    socket.send(JSON.stringify(this.state.messages));
                }
                else {
                    // socket is closed, reopen it and send the message after opening
                    socket = new WebSocket('wss://wsaioserver.digitalcoo.com/');
                    this.setState({ socket }, () => {
                        socket.onopen = () => {
                            this.removeTypingIndicator();
                            socket.send(JSON.stringify(this.state.messages));
                        };
                    });
                }
                

                // Handle incoming messages
                socket.onmessage = (event) => {
                    this.removeTypingIndicator();
                    if (latestMessage === '') {
                        if(event.data === '') {
                            latestMessage = event.data;
                        }
                        else {
                            latestMessage = event.data;
                            this.addMessage(latestMessage, 'assistant');
                        }
                    } else {
                        latestMessage += event.data;
                        let oldMessages = [...this.state.messages];
                        if(oldMessages[oldMessages.length - 1].role === 'assistant') {
                            this.setState((prevState) => {
                                let updatedMessages = [...prevState.messages];
                                updatedMessages[updatedMessages.length - 1].content = latestMessage;
                                return { messages: updatedMessages };
                            });
                        }
                        else {
                            this.addMessage(latestMessage, 'assistant');
                        }
                    }
                    this.scrollToBottom();
                    store('chatbot_messages', JSON.stringify(this.state.messages));
                };

                // Handle WebSocket close (stream end)
                socket.onclose = () => {
                    this.removeTypingIndicator();
                    store('chatbot_messages', []);
                };

                // Handle WebSocket errors
                socket.onerror = (error) => {
                    console.error('WebSocket error:', error);
                    this.removeTypingIndicator();
                };
            });
        }
    };

    addMessage = (content, role) => {
        this.setState((prevState) => ({
            messages: [...prevState.messages, { content, role }],
        }), this.scrollToBottom);
    };

    showTypingIndicator = () => {
        this.setState({ typing: true }, this.scrollToBottom);
    };

    removeTypingIndicator = () => {
        this.setState({ typing: false });
    };

    welcomeMessage = () => {
        this.removeTypingIndicator();
        this.addMessage("Hi there! I am a chatbot provided by Wholesale All In One Application and I am here to help you. Feel free to ask anything", 'assistant');
    };

    scrollToBottom = () => {
        const chatBody = document.getElementById('chatbox-body');
        if (chatBody) {
            chatBody.scrollTop = chatBody.scrollHeight;
        }
    };

    endChat = () => {
        const { socket } = this.state;
        if(socket.readyState === socket.OPEN) {
            socket.close();
            this.setState({ socket: null });
        }
        this.setState({ messages: [], inputText: '' });
        store('chatbot_messages', []);
        this.addMessage("Chat ended by user", 'assistant');
        setTimeout(() => {
            this.toggleChatbox();
            this.setState({ messages: [], inputText: '' });
        }, 3000);
    }

    render() {
        const { chatVisible, messages, inputText, typing } = this.state;

        return (
            <div>
                <style>
                    {`
                        @keyframes slideInUp {
                            0% { transform: translateY(100%); opacity: 0; }
                            100% { transform: translateY(0); opacity: 1; }
                        }
                        @keyframes slideOutDown {
                            0% { transform: translateY(0); opacity: 1; }
                            100% { transform: translateY(100%); opacity: 0; }
                        }
                        .slide-in-up {
                            animation: slideInUp 0.4s ease forwards;
                        }
                        .slide-out-down {
                            animation: slideOutDown 0.4s ease forwards;
                        }
                        @keyframes bounce {
                            0%, 100% { transform: translateY(0); }
                            50% { transform: translateY(-5px); }
                        }
                        hr {
                            border: none;
                            height: 0.5px;
                            border-top: 1px solid #ccc;
                        }
                        .typing-dot {
                            width: 8px;
                            height: 8px;
                            background-color: #008060;
                            border-radius: 50%;
                            margin-right: 4px;
                            animation: bounce 1s infinite;
                        }
                        input:focus {
                            outline: none;
                        }
                        #end-chat-button:hover {
                            background-color: #c12222 !important;
                        }
                        @media (max-width: 400px) {
                            #chatbox {
                                width: 100%;
                                right: 0;
                                border-radius: 0;
                            }
                        }
                        @media (max-height: 600px) {
                            #chatbox {
                                bottom: 0;
                                top: 0;
                                height: 100%;
                                border-radius: 0;
                            }
                        }
                    `}
                </style>
                {/* Chatbot Icon */}
                <div
                    onClick={this.toggleChatbox}
                    style={{
                        position: 'fixed',
                        bottom: '20px',
                        right: '20px',
                        backgroundColor: '#008060',
                        borderRadius: '10px',
                        padding: '15px',
                        cursor: 'pointer',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                        transition: 'transform 0.3s ease',
                        zIndex: '9999',
                    }}
                >
                    {
                        !chatVisible ? (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Icon source={ChatMajor}></Icon>
                                <span style={{ color: 'white', marginLeft: '10px', userSelect: 'none' }}>Ask Chatbot</span>
                            </div>
                        ) : (
                            <div style={{ alignItems: 'center' }}>
                                <Icon source={ChevronDownMinor}></Icon>
                            </div>
                        )
                    }
                </div>

                {/* Chatbox */}
                <div
                    id="chatbox"
                    className={chatVisible ? 'slide-in-up' : 'slide-out-down'}
                    style={{
                        position: 'fixed',
                        bottom: '80px',
                        right: '20px',
                        width: '400px',
                        backgroundColor: 'white',
                        borderRadius: '15px',
                        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
                        display: chatVisible ? 'flex' : 'none', // Hide after slide-out animation completes
                        flexDirection: 'column',
                        overflow: 'hidden',
                        userSelect: 'none',
                        zIndex: '9999',
                    }}
                    onAnimationEnd={() => {
                        if (!chatVisible) {
                            this.setState({ chatVisible: false });
                        }
                    }}
                >
                    {/* Chatbox Header */}
                    <div
                        id="chatbox-header"
                        style={{
                            backgroundColor: '#008060',
                            color: 'white',
                            padding: '15px',
                            fontSize: '16px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            userSelect: 'none',
                        }}
                    >
                        <span style={{display: 'flex', alignItems: 'center', gap: '10px'}}><img width='30px' height='30px' src='/images/chatbot.png'></img><span>Wholesale All In One Chatbot</span></span>
                        <span style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                            <button
                                id='end-chat-button'
                                style={{
                                    backgroundColor: '#d73131',
                                    color: 'white',
                                    border: 'none',
                                    padding: '8px 10px',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                    userSelect: 'none',

                                }}
                                onClick={this.endChat}
                            >
                                End Chat
                            </button>
                            <button
                                onClick={this.toggleChatbox}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    color: 'white',
                                    fontSize: '20px',
                                    cursor: 'pointer',
                                    userSelect: 'none',
                                }}
                            >
                                ×
                            </button>
                        </span>
                    </div>

                    {/* Chatbox Body */}
                    <div
                        id="chatbox-body"
                        style={{
                            padding: '15px',
                            height: '400px',
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            backgroundColor: 'white',
                        }}
                    >
                        {messages.map((message, index) => (
                            message.role === 'assistant' ?
                            <div style={{display: 'flex', gap: '10px'}}><div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}><img style={{backgroundColor: 'white', borderRadius: '50px', padding: '5px', boxShadow: '0px 0px 5px 0px'}} width='40px' height='40px' src='/images/chatbot.png'></img><span>Bot</span></div>
                                <Card key={index}>
                                    <div style={{padding: '10px', marginBottom: '-5px'}}><TextStyle variation={message.role === 'assistant' ? 'inverse' : 'subdued'}>{message.content}</TextStyle></div>
                                    <hr />
                                    <div style={{fontSize: '75%', padding: '10px', display: 'flex', alignItems: 'center', gap: '5px', marginTop: '-5px'}}><img width='15px' height='15px' src='/images/chatbot.png'></img><TextStyle variation='subdued'>AI based answer</TextStyle></div>
                                </Card>
                            </div>
                            :
                            <div style={{display: 'flex', gap: '10px', justifyContent: 'right'}}>
                                <div key={index} style={{ padding: '10px', textAlign: 'right', backgroundColor: '#008060', color: 'white', borderRadius: '10px', marginTop: '20px', marginBottom: '20px' }}>
                                    <TextStyle>{message.content}</TextStyle>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px'}}><img style={{backgroundColor: '#008060', borderRadius: '50px', padding: '5px', boxShadow: '0px 0px 5px 0px'}} width='40px' height='40px' src='/images/person.png'></img><span>You</span></div>
                            </div>
                        ))}
                        {typing && (
                            <div
                                className="typing-indicator"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    margin: '10px',
                                }}
                            >
                                <div className="typing-dot"></div>
                                <div className="typing-dot" style={{ animationDelay: '0.2s' }}></div>
                                <div className="typing-dot" style={{ animationDelay: '0.4s' }}></div>
                            </div>
                        )}
                    </div>

                    {/* Chatbox Footer */}
                    <div style={{ padding: '10px' }}>
                        <Form onSubmit={this.sendMessage}>
                            <FormLayout>
                                <Stack spacing='extraTight'>
                                    <Stack.Item fill>
                                        <TextField
                                            value={inputText}
                                            onChange={v => this.setState({ inputText: v })}
                                            placeholder="Type a message..."
                                        />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Button 
                                            primary
                                            onClick={this.sendMessage}
                                        >
                                            Send
                                        </Button>
                                    </Stack.Item>
                                </Stack>
                            </FormLayout>
                        </Form>
                    </div>
                </div>
            </div>
        );
    }
}

export default Chatbot;
