import React from 'react';
import {Card,TextField, Badge,Button,Stack,Icon,ResourceList,ResourceItem,TextStyle,Checkbox,Collapsible } from '@shopify/polaris';
import { SearchMinor,ChevronDownMinor,ChevronUpMinor} from '@shopify/polaris-icons';
import CountriesList from '../Json/countries_list.json';
import store from 'store2';

const countries_list = CountriesList;
var selectedItems = [];
var initCountries = store("shipping_selected_countries");
var allShippingRules = store("all_shipping_rules");
var shippingState = store("shipping_state");
try {
    if(initCountries){
        initCountries = JSON.parse(initCountries);
    }
    if(allShippingRules){
        allShippingRules = JSON.parse(allShippingRules);
    }
    if(shippingState){
        shippingState = JSON.parse(shippingState);
    }
} catch (e){}

try {
    if(initCountries && initCountries.length){
        selectedItems = initCountries.map(x => {
            return x.id
        })
    }
} catch (e){}

try{
    initCountries.forEach(country => {
        if(country && country.provinces && country.provinces.length > 0){
            try {
                country.provinces.forEach(prov => {
                    selectedItems.push(prov.id);
                });
            } catch (e) { }
        }
    })
} catch (e){}

class ShippingCountries extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            selectedItems: selectedItems || this.props.selectedItems || [],
            selectedData: initCountries || this.props.initialQuery || [],
            search:'',
            temp:[],
            temp2:[]  
        }
        this.handleSave = this.handleSave.bind(this);
    }
    componentDidMount(){
        this.initProvinceStatetToFalse();
        if(typeof this.props.onUpdate === "function"){
            this.props.onUpdate(this.state.selectedData);
        }
        if(typeof this.props.selectedCountries === "function"){
            this.props.selectedCountries(this.state.selectedData,this.state.selectedItems);
        }
    }
    initProvinceStatetToFalse(){
        var temp = this.state.temp;
        var temp2 = this.state.temp2;
        countries_list.forEach((x) => {
            if(x.provinces && x.provinces.length > 0){
                temp.push({id:x.id,state:false});
                temp2.push({id:x.id,state:false});
            }
        })

        this.setState({temp,temp2});
    }
    handleSave = () => {
    }
    render(){
        const {search } = this.state;
        store("shipping_selected_countries", JSON.stringify(this.state.selectedData));
        if(search && search.length !== 0){
            var searchBar = [];
            countries_list.forEach((k,ind1) => {
                var r2 = k.value.match(new RegExp(search,"gi"));
                if(r2){
                    if(searchBar.findIndex((d)=> d.id === k.id) === -1){
                        searchBar.push(k);
                    }
                }

                if(k.provinces && k.provinces.length > 0){
                    k.provinces.forEach((lm) => {
                        var r3 = lm.value.match(new RegExp(search,"gi"));
                        if(r3){
                            if(searchBar.findIndex((d)=> d.id === k.id) === -1){
                                searchBar.push({id:k.id,label:k.label,value:k.value,provinces:[{id:lm.id,value:lm.value}]})
                            }
                        }
                    });
                }
            });  
        }
        return(
            <div>
                <div className="shipping--countries-selection-modal">
                    <div className="waio_shipping left_" style={{maxWidth: '48%'}}>
                        <Card>
                            <div className="search_shipping">
                                <TextField label="Search" prefix={<Icon source={SearchMinor} />} labelHidden value={this.state.search} onChange={(value)=>{
                                    this.setState({search:value});
                                }} placeholder="Search countries,states,provinces" />
                            </div>
                            <div className="waio_shipping_list_main">
                                <div className="list_country">
                                    <ResourceList
                                    showHeader={false}
                                    items={(search.length > 0 ? searchBar:countries_list)}
                                    selectedItems={this.state.selectedItems}
                                    onSelectionChange={(value)=> {
                                        var selectedData = this.state.selectedData;
                                        var selectedItems = this.state.selectedItems;
                                        selectedItems = value;
                                        this.setState({selectedItems})
                                        JSON.parse(JSON.stringify(countries_list)).forEach((yz) => {
                                            if(selectedItems.findIndex((jk)=> jk === yz.id) > -1)
                                            {
                                                if(selectedData.findIndex((mn)=>mn.id === yz.id) === -1){
                                                    yz.provinces = yz.provinces.filter(x => !x.disabled); // filter disabled states
                                                    if(yz.provinces && yz.provinces.length>0){
                                                        yz.provinces.forEach((pk)=>{
                                                            selectedItems.push(pk.id);              
                                                        })
                                                        this.setState({selectedItems})                   // Add all Provinces id
                                                    }
                                                    selectedData.push(yz);                                // Add Single countries data
                                                this.setState({selectedData});                          
                                                }
                                            }else{
                                                    selectedData.forEach((dd)=>{
                                                        if(selectedItems.findIndex((kj) => kj === dd.id) === -1){
                                                            if(dd.provinces && dd.provinces.length > 0){
                                                                dd.provinces.forEach((bk) => {
                                                                    if(selectedItems.findIndex((kj) => kj === bk.id) > -1){
                                                                        selectedItems = selectedItems.filter((k) => k !== bk.id );   // Remove provinces id 
                                                                        this.setState({selectedItems})
                                                                    }
                                                                })
                                                            }
                                                            selectedData = selectedData.filter((k) => k.id !== dd.id );       // Remove countries data 
                                                            this.setState({selectedData})
                                                        }
                                                    });
                                                }
                                            })

                                    }}
                                    selectable
                                    renderItem={(item) => {
                                        let {label,value, provinces,id} = item;
                                        var _index = this.state.temp.findIndex((z) => z.id === id);
                                        if(_index > -1 && this.state.temp.length>0){
                                            var new_ind = this.state.temp[_index].state;
                                        }
                                        var chk = false;
                                        if(this.state.selectedData.findIndex((x) => x.value === value)>-1){
                                            chk = true;
                                        }
                                        
                                        let all_provinces = [];
                                        let countryFound = false;
                                        allShippingRules.filter(rule => {
                                            if(typeof shippingState !== "undefined" && shippingState){
                                                // filter current shipping rules
                                                if(shippingState.show && shippingState.show === "edit" && shippingState.edit_rule_id === rule._id) return false;

                                                // filter customer group (all & not same with current rule)
                                                if(shippingState.customer_group !== rule.customer_group || rule.customer_group === "all")return false;
                                                else{
                                                    // if group are same, if tag_based, need to match the tags too
                                                    if(rule.customer_group === "tag_based"){
                                                        const tagFound = rule.customer_tags.some(tag => shippingState.customer_tags.some(currentTags => currentTags.toLowerCase() === tag.toLowerCase()) );

                                                        return tagFound;
                                                    }
                                                }
                                            }

                                            return true;
                                        }).forEach(rule => {
                                            // Check if tags matched, need to check if country selection are different
                                            if(rule.country_selection === "selected" && rule.selected_countries.length > 0){
                                                // Generate indexed array of province codes prefixed with country code like CA-AB, US-AL 
                                                const province_codes = rule.selected_countries.flatMap(x=>x.provinces.map(y=>x.code+'-'+y.code));
                                                province_codes.forEach(province_code => {
                                                    all_provinces.push(province_code);
                                                });
                                                // eslint-disable-next-line
                                                if(!countryFound)countryFound = rule.selected_countries.some(x=>x.id == id);
                                            }
                                        });

                                        provinces = provinces.map(province => {

                                            const code = item.code+'-'+province.code;
                                            const index = all_provinces.indexOf(code);
                                            if(index > -1) province.disabled = true;
                                            else province.disabled = false;

                                            return province;
                                        });

                                        let all_provinces_disabled = false;
                                        if(provinces.length > 0){
                                            if(provinces.filter(x => !x.disabled).length === 0){
                                                all_provinces_disabled = true;
                                            }
                                        }
                                        else{
                                            // Check if country found
                                            if(countryFound)  all_provinces_disabled = true;
                                        }

                                        

                                        return (
                                            <>
                                        <div className={"country_name " + (chk === true ? " bg_color":"")}>
                                        <div
                                            id={id}
                                            label={label}
                                            value={value}
                                        >

<Stack alignment="center">
                                                <Stack.Item>
                                                    <div className="lbl_resourse">
                                                    <Checkbox labelHidden label="chk" disabled={!chk && all_provinces_disabled} checked={chk} onChange={(check)=>{
                                                        var selectedData = this.state.selectedData;
                                                        var selectedItems = this.state.selectedItems;
                                                        if(check === true){
                                                            selectedItems.push(id);
                                                        }else{
                                                            if(selectedItems.findIndex((x) => x === id)>-1){
                                                                selectedItems = selectedItems.filter((y) => y !== id)
                                                            }
                                                        }
                                                        this.setState({selectedItems})
                                                        JSON.parse(JSON.stringify(countries_list)).forEach((yz) => {
                                                            if(selectedItems.findIndex((jk)=> jk === yz.id) > -1)
                                                            {
                                                                if(selectedData.findIndex((mn)=>mn.id === yz.id) === -1){
                                                                    if(yz.provinces && yz.provinces.length>0){
                                                                        yz.provinces = yz.provinces.filter(x => !x.disabled); // filter disabled states
                                                                        yz.provinces.forEach((pk)=>{
                                                                            selectedItems.push(pk.id);              
                                                                        });
                                                                        this.setState({selectedItems})                   // Add all Provinces id
                                                                    }
                                                                    selectedData.push(yz);                                // Add Single countries data
                                                                    this.setState({selectedData});                          
                                                                }
                                                            }else{
                                                                    selectedData.forEach((dd)=>{
                                                                        if(selectedItems.findIndex((kj) => kj === dd.id) === -1){
                                                                            if(dd.provinces && dd.provinces.length > 0){
                                                                                dd.provinces.forEach((bk) => {
                                                                                    if(selectedItems.findIndex((kj) => kj === bk.id) > -1){
                                                                                        selectedItems = selectedItems.filter((k) => k !== bk.id );   // Remove provinces id 
                                                                                        this.setState({selectedItems})
                                                                                    }
                                                                                })
                                                                            }
                                                                            selectedData = selectedData.filter((k) => k.id !== dd.id );       // Remove countries data 
                                                                            this.setState({selectedData})
                                                                        }
                                                                    });
                                                                }
                                                            })
                                                    } } /></div>
                                                </Stack.Item>
                                                <Stack.Item fill>
                                                    <p>
                                                        <TextStyle>{label}</TextStyle>
                                                    </p>
                                                    {all_provinces_disabled && 
                                                        <small>
                                                            <TextStyle variation="subdued">(Already selected in other rule)</TextStyle>
                                                        </small>
                                                    }
                                                </Stack.Item>
                                            {(provinces && provinces.length > 0) && <Stack.Item><Button plain onClick={()=>{
                                                if(this.state.temp.findIndex((z) => z.id === id)>-1){ 
                                                    var temp = this.state.temp;
                                                    var new_index = this.state.temp.findIndex((z) => z.id === id)
                                                    temp[new_index].state = !temp[new_index].state;
                                                    this.setState({temp})
                                                }
                                            }} icon={ new_ind ? ChevronUpMinor:ChevronDownMinor}><Badge >{provinces.length} State & territories</Badge></Button></Stack.Item>}
                                            </Stack>
                                        </div>
                                        </div>
                                            {(new_ind && provinces && provinces.length > 0) &&
                                            <Collapsible
                                                open={new_ind}
                                            >
                                            <div className="list_provinces">
                                                <ResourceList
                                                showHeader={false}
                                                items={provinces}
                                                selectedItems={this.state.selectedItems}
                                                onSelectionChange={(value)=> {
                                                    var selectedData = this.state.selectedData;
                                                    var selectedItems = this.state.selectedItems;
                                                    selectedItems = value;
                                                    this.setState({selectedItems});
                                                    JSON.parse(JSON.stringify(countries_list)).forEach((yz) => {
                                                        
                                                        if(yz.provinces && yz.provinces.length > 0){
                                                            yz.provinces.forEach((rp) => {
                                                                var ghq = 0;
                                                                    if(selectedItems.findIndex((jk)=> jk === rp.id) > -1)
                                                                    {
                                                                        if(selectedItems.findIndex((k)=> k === yz.id) === -1){
                                                                            selectedItems.push(yz.id);           // ADD Country & Country Id and data
                                                                            selectedData.push({id:yz.id,label:yz.label,value:yz.value,code:yz.code,provinces:[{id:rp.id,value:rp.value,code:rp.code}]});
                                                                            this.setState({selectedItems,selectedData})
                                                                        }
                                                                        if(selectedData.findIndex((mn)=>mn.id === yz.id) > -1){
                                                                            selectedData.forEach((dk,indexes) => {
                                                                                if(dk.id === yz.id)
                                                                                {
                                                                                    if(dk.provinces && dk.provinces.length>0){
                                                                                        dk.provinces.forEach((gg)=> {
                                                                                            if(gg.id === rp.id){                  // ADD Single Provinces data
                                                                                                ghq = gg.id;
                                                                                            }
                                                                                        })
                                                                                        if(ghq === 0){
                                                                                            selectedData[indexes].provinces.push({id:rp.id,value:rp.value,code:rp.code});
                                                                                            this.setState({selectedData})
                                                                                        }
                                                                                    }
                                                                                }
                                                                            })
                                                                            
                                                                        }
                                                                }else{
                                                                    JSON.parse(JSON.stringify(selectedData)).forEach((dd,index)=>{
                                                                        if(dd.provinces && dd.provinces.length>0){
                                                                            dd.provinces.forEach((fd) => {                  // Remove Single Province data
                                                                                if(selectedItems.findIndex((qd)=> qd === fd.id) === -1){
                                                                                    selectedData[index].provinces = selectedData[index].provinces.filter((hf) => hf.id !== fd.id);
                                                                                    this.setState({selectedData})
                                                                                }
                                                                            })
                                                                        }
                                                                    });

                                                                    countries_list.forEach((cd) => {
                                                                        if(selectedItems.findIndex((g) => g === cd.id) > -1){
                                                                            var check2=0;
                                                                                if(cd.provinces && cd.provinces.length>0)
                                                                                {
                                                                                    cd.provinces.forEach((fd)=>{
                                                                                        if(selectedItems.findIndex((opq) => opq === fd.id) > -1){
                                                                                            check2 = check2 + 1;
                                                                                        }
                                                                                    })
                                                                                    if(check2 === 0 ){
                                                                                        selectedItems = selectedItems.filter((lm)=>lm !== cd.id);
                                                                                        selectedData = selectedData.filter((dg)=> dg.id !== cd.id);
                                                                                        this.setState({selectedItems,selectedData});                          // Remove Single Country id
                                                                                    }
                                                                                } 
                                                                            }
                                                                        })
                                                                }
                                                                
                                                            })
                                                        }
                                                    })
                                                }}
                                                selectable
                                                renderItem={(item,index) => {
                                                    const {value,id,disabled} = item;

                                                    return (
                                                        <>
                                                            {disabled ? 
                                                                <div style={{padding: "10px", paddingLeft: "12%", borderBottom: "1px solid #ddd", borderTop: "1px solid #ddd"}} className="province_name">
                                                                    <div
                                                                        id={id}
                                                                        value={value}
                                                                    >

                                                                        <Stack alignment="center">
                                                                            <Stack.Item>
                                                                                <div className="lbl_resourse">
                                                                                    <Checkbox labelHidden label="chk" disabled checked={false} />
                                                                                </div>
                                                                            </Stack.Item>
                                                                            <Stack.Item fill>
                                                                                <Stack spacing="extraTight">
                                                                                    <TextStyle variation="subdued">{value}</TextStyle>
                                                                                    <small>
                                                                                        <TextStyle variation="subdued">(Already selected in other rule)</TextStyle>
                                                                                    </small>
                                                                                </Stack>
                                                                            </Stack.Item>
                                                                        </Stack>
                                                                    </div>
                                                                </div>
                                                            :
                                                                <ResourceItem
                                                                id={id}
                                                                value={value}
                                                                >
                                                                    <TextStyle>{value}</TextStyle>
                                                                </ResourceItem>
                                                            }
                                                        </>
                                                    );
                                                }}
                                                />
                                            </div>
                                            </Collapsible>
                                            }
                                        </>
                                        );
                                    }}
                                    />
                                        
                                    </div>
                                </div>
                        </Card>
                    </div>
                    <div className="waio_shipping right_">
                        <Card>
                            <div className="search_shipping">
                                {this.state.selectedData.length > 0 ? <> <h3>{this.state.selectedData.length} Countries Selected</h3></>:<h3>No Country Selected</h3>} 
                            </div>
                            <div className="waio_shipping_list_main">
                                <div className="list_country">
                                    <ResourceList
                                    showHeader={false}
                                    items={this.state.selectedData}
                                    selectedItems={this.state.selectedItems}
                                    onSelectionChange={()=> {
                                    }}
                                    selectable
                                    renderItem={(item,index) => {
                                        const {label,value, provinces,id} = item;
                                        var _index = this.state.temp2.findIndex((z) => z.id === id);
                                        if(_index > -1 && this.state.temp2.length>0){
                                            var new_ind = this.state.temp2[_index].state;
                                        }
                                        var chk2 = false;
                                        if(this.state.selectedData.findIndex((x) => x.value === value)>-1){
                                            chk2 = true;
                                        }
                                        return (
                                            <>
                                        <div className={"country_name " + (chk2 === true ? " bg_color":"")}>
                                        <div
                                            id={id}
                                            label={label}
                                            value={value}
                                        >

                                            <Stack alignment="center">
                                                <Stack.Item>
                                                    <div className="lbl_resourse">
                                                    <Checkbox labelHidden label="chk" checked={chk2} onChange={(check)=>{
                                                        var selectedData = this.state.selectedData;
                                                        var selectedItems = this.state.selectedItems;
                                                        if(check === true){
                                                            selectedItems.push(id);
                                                        }else{
                                                            if(selectedItems.findIndex((x) => x === id)>-1){
                                                                selectedItems = selectedItems.filter((y) => y !== id)
                                                            }
                                                        }
                                                        this.setState({selectedItems})
                                                        JSON.parse(JSON.stringify(countries_list)).forEach((yz) => {
                                                            if(selectedItems.findIndex((jk)=> jk === yz.id) > -1)
                                                            {
                                                                if(selectedData.findIndex((mn)=>mn.id === yz.id) === -1){
                                                                    if(yz.provinces && yz.provinces.length>0){
                                                                        yz.provinces.forEach((pk)=>{
                                                                            selectedItems.push(pk.id);              
                                                                        })
                                                                        this.setState({selectedItems})                   // Add all Provinces id
                                                                    }
                                                                    selectedData.push(yz);                                // Add Single countries data
                                                                this.setState({selectedData});                          
                                                                }
                                                            }else{
                                                                    selectedData.forEach((dd)=>{
                                                                        if(selectedItems.findIndex((kj) => kj === dd.id) === -1){
                                                                            if(dd.provinces && dd.provinces.length > 0){
                                                                                dd.provinces.forEach((bk) => {
                                                                                    if(selectedItems.findIndex((kj) => kj === bk.id) > -1){
                                                                                        selectedItems = selectedItems.filter((k) => k !== bk.id );   // Remove provinces id 
                                                                                        this.setState({selectedItems})
                                                                                    }
                                                                                })
                                                                            }
                                                                            selectedData = selectedData.filter((k) => k.id !== dd.id );       // Remove countries data 
                                                                            this.setState({selectedData})
                                                                        }
                                                                    });
                                                                }
                                                            })
                                                    } } /></div>
                                                </Stack.Item>
                                            <Stack.Item fill><TextStyle>{label}</TextStyle></Stack.Item>
                                            {(provinces && provinces.length > 0) && <Stack.Item><Button plain onClick={()=>{
                                                if(this.state.temp2.findIndex((z) => z.id === id)>-1){ 
                                                    var temp2 = this.state.temp2;
                                                    var new_index = this.state.temp2.findIndex((z) => z.id === id)
                                                    temp2[new_index].state = !temp2[new_index].state;
                                                    this.setState({temp2})
                                                }
                                            }} icon={ new_ind ? ChevronUpMinor:ChevronDownMinor}><Badge >{provinces.length} State & territories</Badge></Button></Stack.Item>}
                                            </Stack>
                                        </div>
                                        </div>
                                            {(new_ind && provinces && provinces.length > 0) &&
                                            <Collapsible
                                                open={new_ind}
                                            >
                                            <div className="list_provinces">
                                                <ResourceList
                                                showHeader={false}
                                                items={provinces}
                                                selectedItems={this.state.selectedItems}
                                                onSelectionChange={(value)=> {
                                                    var selectedData = this.state.selectedData;
                                                    var selectedItems = this.state.selectedItems;
                                                    selectedItems = value;
                                                    this.setState({selectedItems});
                                                    JSON.parse(JSON.stringify(countries_list)).forEach((yz) => {
                                                        
                                                        if(yz.provinces && yz.provinces.length > 0){
                                                            yz.provinces.forEach((rp) => {
                                                                var ghq = 0;
                                                                    if(selectedItems.findIndex((jk)=> jk === rp.id) > -1)
                                                                    {
                                                                        if(selectedItems.findIndex((k)=> k === yz.id) === -1){
                                                                            selectedItems.push(yz.id);           // ADD Country & Country Id and data
                                                                            selectedData.push({id:yz.id,label:yz.label,value:yz.value,provinces:[{id:rp.id,value:rp.value}]});
                                                                            this.setState({selectedItems,selectedData})
                                                                        }
                                                                        if(selectedData.findIndex((mn)=>mn.id === yz.id) > -1){
                                                                            selectedData.forEach((dk,indexes) => {
                                                                                if(dk.id === yz.id)
                                                                                {
                                                                                    if(dk.provinces && dk.provinces.length>0){
                                                                                        dk.provinces.forEach((gg)=> {
                                                                                            if(gg.id === rp.id){                  // ADD Single Provinces data
                                                                                                ghq = gg.id;
                                                                                            }
                                                                                        })
                                                                                        if(ghq === 0){
                                                                                            selectedData[indexes].provinces.push({id:rp.id,value:rp.value});
                                                                                            this.setState({selectedData})
                                                                                        }
                                                                                    }
                                                                                }
                                                                            })
                                                                            
                                                                        }
                                                                }else{
                                                                    JSON.parse(JSON.stringify(selectedData)).forEach((dd,index)=>{
                                                                        if(dd.provinces && dd.provinces.length>0){
                                                                            dd.provinces.forEach((fd) => {                  // Remove Single Province data
                                                                                if(selectedItems.findIndex((qd)=> qd === fd.id) === -1){
                                                                                    selectedData[index].provinces = selectedData[index].provinces.filter((hf) => hf.id !== fd.id);
                                                                                    this.setState({selectedData})
                                                                                }
                                                                            })
                                                                        }
                                                                    });

                                                                    countries_list.forEach((cd) => {
                                                                        if(selectedItems.findIndex((g) => g === cd.id) > -1){
                                                                            var check2=0;
                                                                                if(cd.provinces && cd.provinces.length>0)
                                                                                {
                                                                                    cd.provinces.forEach((fd)=>{
                                                                                        if(selectedItems.findIndex((opq) => opq === fd.id) > -1){
                                                                                            check2 = check2 + 1;
                                                                                        }
                                                                                    })
                                                                                    if(check2 === 0 ){
                                                                                        selectedItems = selectedItems.filter((lm)=>lm !== cd.id);
                                                                                        selectedData = selectedData.filter((dg)=> dg.id !== cd.id);
                                                                                        this.setState({selectedItems,selectedData});                          // Remove Single Country id
                                                                                    }
                                                                                } 
                                                                            }
                                                                        })
                                                                }
                                                                
                                                            })
                                                        }
                                                    })
                                                }}
                                                selectable
                                                renderItem={(item,index) => {
                                                    const {value,id,code} = item;
                                                    return (
                                                    <ResourceItem
                                                        key={id}
                                                        id={id}
                                                        value={value}
                                                        code={code}
                                                    >
                                                        <TextStyle>{value}</TextStyle>
                                                    </ResourceItem>
                                                    );
                                                }}
                                                />
                                            </div>
                                            </Collapsible>
                                            }
                                        </>
                                        );
                                    }}
                                    />
                                        
                                    </div>
                                </div>
                        </Card>
                    </div>
                </div>
            </div>
        );
    }
}

export default ShippingCountries;