import React, { Component } from 'react';
import {TextField,Stack } from '@shopify/polaris';
import store from 'store2';

export default class VariantPickerForSelectedProducts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shop_id:this.props.shop_id,
            shop:this.props.shop,
            search:'',
            all_products:[],
            selected_variants: store('variants') || [],
            selected_products: [],
            collections: [],
            collection_selected: '',
            collection_options: [],
            tem_product: []
        };
    }
    componentDidMount(){
        let selected_products = store('selected_products') || [];
        if(selected_products.length > 0){
            this.setState({
                all_products: selected_products,
                tem_product: selected_products,
            });
        }
    }
    variantsUI(products){
        let variants = products.variants;
        let res;
        if(variants.length > 0){
            res = variants.map((variant,idx)=>{
                return <li key={idx} id='variant' data-id={variant.id}>
                <label className="Polaris-Choice" htmlFor={variant.title+variant.id}><span className="Polaris-Choice__Control"><span className="Polaris-Checkbox">
                    <input id={variant.title+variant.id} type="checkbox" className="Polaris-Checkbox__Input" aria-invalid="false" aria-checked="false" title={variant.title} name={variant.product_id} value={variant.id || ''} onChange={this.handleVariantSelected.bind(this, idx)}
                    checked={this.state.selected_variants.findIndex(x => (x.id).toString() === (variant.id).toString()) > -1 ?true:false}
                    ></input>
                    <span className="Polaris-Checkbox__Backdrop"></span><span className="Polaris-Checkbox__Icon">
                    <span className="Polaris-Icon"><svg className="Polaris-Icon__Svg" viewBox="0 0 20 20" focusable="false" aria-hidden="true"><path d="M8.315 13.859l-3.182-3.417a.506.506 0 0 1 0-.684l.643-.683a.437.437 0 0 1 .642 0l2.22 2.393 4.942-5.327a.437.437 0 0 1 .643 0l.643.684a.504.504 0 0 1 0 .683l-5.91 6.35a.437.437 0 0 1-.642 0"></path></svg></span></span></span></span><span className="Polaris-Choice__Label">{variant.title}  (${variant.price}) </span>
                </label> 
                </li>
            });
        }
        return <ul className="variant_ul">{res}</ul>;
    }
    handleChangeSearch = (value) => {
        if(value.trim() !== ''){
            this.setState({
                tem_product: this.state.all_products
            })
            let new_pro = this.state.all_products.filter(product => product.title.match(new RegExp(value, 'i')));
            this.setState({
                search: value,
                tem_product: new_pro
            });
        }
        else{
            this.setState({
                search: value,
                tem_product: this.state.all_products
            });
        }
    };
    handleVariantSelected(i,e){
        let product_array = [];
        let all_products = this.state.all_products;
        const { value, title, name} = e.target;
        let product_id = name;
        let index = this.state.selected_variants.findIndex(x => x.id===value);
        let p_index = all_products.findIndex(x=>parseInt(x.id,10) === parseInt(product_id,10));
        if( p_index > -1){
            product_array.push({
                id: all_products[p_index].id,
                title: all_products[p_index].title,
                handle:all_products[p_index].handle,
                image: all_products[p_index].image
            });
        }
        if(index === -1){
            this.setState({
                selected_variants: this.state.selected_variants.concat({ // concat returns a new array
                    id:value,
                    title: title,
                    product_id: product_id,
                    product: product_array
                })
            });
        }
        else{
            let new_selected_variants = this.state.selected_variants.filter(variant => variant.id !== value);
            this.setState({
                selected_variants: new_selected_variants
            });
        }
    }
    render() {
        if(this.state.selected_variants){
            store('variants', this.state.selected_variants);
        }
        let result_variants =  this.state.tem_product.map((product, i) => {
              return  <li key={i} id='product' className="variants-list-select-item"> 
                    <strong>{product.title}</strong>
                    {this.variantsUI(product)}
                </li>;
            });
        if(this.state.all_products.length < 1){
            return(
                <div>
                    <center>
                    {/* <ReactLoading type='spokes' color='#5c6ac4'/> */}
                    <br></br>
                    <span>No product was selected.</span>
                    </center> 
                </div>
            );
        }
        else{
            return (
                <div>
                    <Stack vertical spacing="extraTight">
                    <TextField
                        label=""
                        value={this.state.search}
                        autoComplete={false}
                        placeholder={'Search product by title'}
                        onChange={this.handleChangeSearch}
                    />
                    </Stack>
                    {this.state.selected_variants.length} variants selected
                    <br></br>
                    <ul className="variants-ul">{result_variants}</ul>
                </div>
            );
        }
    }
}