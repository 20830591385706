import React, { Component } from 'react';
import { Card, Stack, Checkbox, TextField, RadioButton, Button, Heading, Collapsible, TextStyle, Select } from '@shopify/polaris';
import emailTemplates from './emailTemplates';
import PageTitleBar from '../Common/TitleBar';
import SkeletonPageComp from './SkeletonPageComp';
import ToastMessage from '../Common/ToastMessage';
import axios from '../Axios';
import CommonHelper from '../Helpers/Common';
const BUILD_VERSION_EXTENSION =  process.env.REACT_APP_BUILD_VERSION_EXTENSION;

export default class NetTerms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enabled: false,
      payment_options: "Net 15, Net 30, Net 45",
      disable_checkout: true,
      show_delivery_date: true,
      cart_message: "Net order terms",
      checkout_btn_label: "Net 15/30 Orders Checkout",
      messages: {
        "confirmation": "Please review your order below",
        "thank_you": "Your order has been successfully placed. You will now be redirected to our homepage.",
      },
      customers_tag: "waio-nt",
      show_payment_mode: true,
      customers_group: "loggedin",
      email_template: {
        "to_owner": {
          "email": this.props.shopData?this.props.shopData.email:"",
          "subject": `New Net Order Received`,
          "message": `Thank you for placing your order with [shop_name]!
This email is to confirm your recent order.
Date[order_date]
[line_items]
Total order price [total_price]
`,
        }
      },
      owner_email_notify: true,
      customer_email_notify: true,
      order_method: "draft_order",
      edit_id: null,
      page_loaded: false,
      email_template_new: emailTemplates.net_terms,
      owner_template_toggle: false,
      customer_template_toggle: false,
      send_invoice: false,
      price_design: "1",
      upgrading: false
    }
  }

  showToastMessage(msg, error){
    this.setState({
      showToast: true,
      toastMsg: msg,
      toastError: error||false,
    });
  }
  
  dissmissToast = () =>{
    this.setState({
      showToast: false,
    });
  }

  componentDidMount(){
    this.getNetTerm();
  }

  getNetTerm(){
    axios.get('/app/get-net-term/' + this.props.shop)
      .then(response => {
        if (response && response.data && response.data.status === 1) {
          if (response.data.netTerms) {
            var netTerm = response.data.netTerms;
            var {enabled,disable_checkout,show_delivery_date,show_payment_mode,payment_options,owner_email_notify,customer_email_notify,cart_message,checkout_btn_label,messages,customers_tag,customers_group,email_template,order_method} = response.data.netTerms;
            var email_template_new = this.state.email_template_new;
            var price_design = this.state.price_design;
            var send_invoice = this.state.send_invoice;
            try{
              if(netTerm.email_template_new){
                email_template_new = netTerm.email_template_new;
              } 
              if(!email_template_new.owner.to || email_template_new.owner.to === ""){
                if(this.props.shopData && this.props.shopData.email){
                  email_template_new.owner.to = this.props.shopData.email;
                }
              }
            }catch(e){}
            try{
              if(netTerm.price_design){
                price_design = netTerm.price_design;
              } 
            }catch(e){}
            try{
              if(netTerm.send_invoice){
                send_invoice = netTerm.send_invoice;
              } 
            }catch(e){}
            this.setState({
              edit_id:netTerm._id,enabled,disable_checkout,show_delivery_date,show_payment_mode,payment_options,owner_email_notify,customer_email_notify,cart_message,checkout_btn_label,messages,customers_tag,customers_group,email_template,order_method,email_template_new,send_invoice,price_design
            })
          }
          else{
            // net term not found, means its new
            let email_template_new = this.state.email_template_new;
            if(!email_template_new.owner.to || email_template_new.owner.to === ""){
              email_template_new.owner.to = this.props.shopData?this.props.shopData.email:"";
            }
            if(!email_template_new.owner.from || email_template_new.owner.from === ""){
              email_template_new.owner.from = "noreply@digitalcoo.com";
            }
            if(!email_template_new.owner.to || email_template_new.owner.to === ""){
              if(this.props.shopData && this.props.shopData.email){
                email_template_new.owner.to = this.props.shopData.email;
              }
            }

            this.setState({email_template_new});
          }
        }
        this.setState({page_loaded:true});
      })
      .catch(error => {
        this.showToastMessage(error,true);
        this.setState({page_loaded:true});
      });
  }

  IsValid(){
    var valid = true;
    var {
      enabled,
      customers_group,
      customers_tag
    } = this.state;
    if(enabled){
      try{
        if("tag_based"===customers_group && 1>customers_tag.length)valid=false
      }catch(e){}
    }
    return valid;
  }

  saveData(){
    if(this.IsValid()){
      let customer_tags = this.state.customers_tag;
      customer_tags && (customer_tags=customer_tags.trim());
      if(customer_tags.indexOf(',')>-1){
        // Remove spaces between tags
        customer_tags = customer_tags.split(',').map(x=>x.trim()).join(',');

      }
      var data = {
        shop: this.props.shop,
        enabled: this.state.enabled,
        disable_checkout: this.state.disable_checkout,
        show_delivery_date: this.state.show_delivery_date,
        show_payment_mode: this.state.show_payment_mode,
        payment_options: this.state.payment_options,
        owner_email_notify: this.state.owner_email_notify,
        customer_email_notify: this.state.customer_email_notify,
        cart_message: this.state.cart_message,
        checkout_btn_label: this.state.checkout_btn_label,
        messages: this.state.messages,
        customers_tag: customer_tags,
        customers_group: this.state.customers_group,
        email_template: this.state.email_template,
        email_template_new: this.state.email_template_new,
        send_invoice: this.state.send_invoice,
        price_design: this.state.price_design,
        order_method: this.state.order_method
      };
      if(this.state.edit_id){
        axios.put('/app/update-net-term/' + this.state.edit_id, data)
        .then(response => {
          if (response && response.data) {
            if (response.data.status === 1) {
              this.showToastMessage(response.data.message);
            }
            else{
              this.showToastMessage(response.data.message, true);
            }
          }
          else{
            this.showToastMessage("An error occure", true); 
          }
        })
        .catch(error => {
          this.showToastMessage(error,true);
        });
      }
      else{
        axios.post('/app/save-net-term/', data)
        .then(response => {
          if (response && response.data) {
            if (response.data.status === 1) {
              this.showToastMessage(response.data.message);
            }
            else{
              this.showToastMessage(response.data.message, true);
            }
          }
          else{
            this.showToastMessage("An error occure", true); 
          }
        })
        .catch(error => {
          this.showToastMessage(error,true);
        });
      }
    }
    else{
      this.showToastMessage("Validation error",true);
    }
  }

  render() {
    const {price_design,send_invoice,customer_template_toggle,owner_template_toggle,email_template_new,enabled,payment_options,disable_checkout,show_delivery_date,customers_tag,messages,checkout_btn_label,cart_message,show_payment_mode,customers_group,owner_email_notify,customer_email_notify,order_method} = this.state;
    const section1 = <Card title="Net Terms" primaryAction={{content: "Save", onAction: () => this.saveData() }}>
      <Card.Section>
        <Stack vertical spacing="tight">
          <Checkbox
            label="Enable Net Terms Feature "
            checked={enabled}
            onChange={ (enabled) => this.setState({enabled})}
          />
          {
            enabled && <Checkbox
              label="Show a delivery date option on the net order form"
              checked={show_delivery_date}
              onChange={ (show_delivery_date) => this.setState({show_delivery_date})}
            />
          }
          {
            enabled && <Checkbox
              label="Remove default Shopify checkout button when Net Terms feature is enabled."
              checked={disable_checkout}
              onChange={ (disable_checkout) => this.setState({disable_checkout})}
            />
          }
        </Stack>
      </Card.Section>
      { enabled && <Card.Section title="Cart labels">
          <Stack vertical>
            <TextField
              label="Cart message"
              value={cart_message}
              onChange={(v) => {this.setState({cart_message:v})}}
            />
            <TextField
              label="Checkout button label"
              value={checkout_btn_label}
              onChange={(v) => {this.setState({checkout_btn_label:v})}}
            />
          </Stack>
          </Card.Section>
      }
    </Card>;

    const section2 = <Card title="Offer net terms checkout options to">
      <Card.Section>
        <Stack vertical spacing="extraTight">
          <RadioButton
            label="All logged in customers"
            helpText="Offer the net terms checkout option to all login customers regardless the customer tags."
            checked={"loggedin" === customers_group}
            id="loggedin"
            name="customers_group"
            onChange={(v,id) => {
              this.setState({customers_group:id})
            }}
          />
          <RadioButton
            label="Specific tagged customers"
            helpText="Only offer the net terms checkout option to those customer accounts tagged with the tag you specify here."
            checked={"tag_based" === customers_group}
            id="tag_based"
            name="customers_group"
            onChange={(v,id) => {
              this.setState({customers_group:id})
            }}
          />
          {
            "tag_based" === customers_group && 
            <TextField
              autoFocus
              label="Enter customers tag"
              helpText="Enter comma {,} separated customers tag"
              value={customers_tag}
              onChange={(v) => {
                this.setState({customers_tag:v})
              }}
            />
          }
        </Stack>
      </Card.Section>
    </Card>;

    const section3 = <Card title="Payment options">
      <Card.Section>
        <Stack vertical>
          <Checkbox
            label="Show payment mode on net term order form"
            checked={show_payment_mode}
            onChange={(show_payment_mode) => {this.setState({show_payment_mode})}}
          />
          {
            show_payment_mode && <TextField
              label="Selected payment mode will be displayed inside note in the draft order"
              helpText="Enter comma {,} separated payment mode"
              value={payment_options}
              onChange={(v) => {this.setState({payment_options:v})}}
            />
          }
        </Stack>
      </Card.Section>
    </Card>;

    const section4 = <Card title="Order method">
    <Card.Section>
      <Stack vertical spacing="tight">
        <RadioButton
          label="Draft order"
          helpText="Order is created as a draft in the Shopify admin (Admin > Orders > Draft)."
          name="order_method"
          checked={"draft_order"===order_method}
          id="draft_order"
          onChange={(v,id) => { this.setState({order_method:id})}}
        />
        <RadioButton
          label="Final order"
          helpText="Order is created and marked as unpaid in the Shopify admin (Admin > Orders)."
          name="order_method"
          checked={"final_order"===order_method}
          id="final_order"
          onChange={(v,id) => { this.setState({order_method:id})}}
        />
      </Stack>
    </Card.Section>
    </Card>;

    const section5 = <Card title="Notifications">
      <Card.Section title="Alerts">
        <Stack vertical>
          <TextField
            label="Confirmation message"
            value={messages.confirmation}
            onChange={(v) => {
              var messages = this.state.messages;
              messages.confirmation = v;
              this.setState({messages:messages})
            }}
          />
          <TextField
            label="Thank you message"
            value={messages.thank_you}
            onChange={(v) => {
              var messages = this.state.messages;
              messages.thank_you = v;
              this.setState({messages:messages})
            }}
          />
        </Stack>
      </Card.Section>
    </Card>;

    const section6 = <Card title="Email templates" sectioned>
      <Stack vertical spacing="tight">
        <div>
          <Stack vertical spacing="extraTight">
            <div>
              <Stack>
                <Stack.Item fill>
                    <Heading>Store owner email configurations</Heading>
                    <div>
                        <TextStyle variation="subdued">Email from, to, subject and body</TextStyle>
                    </div>
                </Stack.Item>
                <Stack.Item>
                    <Button size="slim"
                        onClick={() => {this.setState({owner_template_toggle:!owner_template_toggle})}}
                    >{owner_template_toggle?"Close":"Open"}</Button>
                    </Stack.Item>
              </Stack>
            </div>
          </Stack>
        </div>
        <div>
          <Collapsible
            open={owner_template_toggle}
            id="basic-collapsible"
            transition={{duration: '150ms', timingFunction: 'ease'}}
          >
            <Stack vertical spacing="tight">
              <Checkbox
                helpText="When this is checked, customer will receive an invoice"
                label="Send invoice automatically"
                checked={send_invoice}
                id="send_invoice"
                onChange={(v) => {this.setState({send_invoice:v})}}
              />
              <Checkbox
                label="Receive an internal Email once customer place a net term order"
                checked={owner_email_notify}
                id="owner_email_notify"
                onChange={(v) => {this.setState({owner_email_notify:v})}}
              />
              {
                owner_email_notify && <div>
                  <Stack>
                    <Stack.Item fill>
                      <Select
                        label="Price design"
                        value={price_design}
                        options={[
                          { value: "1", label: "Template 1" },
                          { value: "2", label: "Template 2" },
                          { value: "3", label: "Template 3" }
                        ]}
                        onChange={(v) => {this.setState({price_design:v})}}
                      ></Select>
                    </Stack.Item>
                    <Stack.Item>
                      <div>
                        {
                          price_design === "1" && <img src="/images/temp1.png" alt="sd"></img>
                        }
                        {
                          price_design === "2" && <img src="/images/temp2.png" alt="sdd"></img>
                        }
                        {
                          price_design === "3" && <img src="/images/temp3.png" alt="sdds"></img>
                        }
                      </div>
                    </Stack.Item>
                  </Stack>
                </div>
              }
              {
                owner_email_notify && <div>
                  <Stack vertical>
                    <Stack distribution="fill">
                      <Stack.Item>
                        <TextField
                          label="Send email From"
                          value={email_template_new.owner.from}
                          onChange={(v) => {
                            var email_template_new = this.state.email_template_new;
                            email_template_new.owner.from = v;
                            this.setState({email_template_new});
                          }}
                        />
                      </Stack.Item>
                      <Stack.Item>
                        <TextField
                          label="Send email to"
                          value={email_template_new.owner.to}
                          onChange={(v) => {
                            var email_template_new = this.state.email_template_new;
                            email_template_new.owner.to = v;
                            this.setState({email_template_new});
                          }}
                        />
                      </Stack.Item>
                    </Stack>
                    <TextField
                      label="Subject"
                      value={email_template_new.owner.subject}
                      onChange={(v) => {
                        var email_template_new = this.state.email_template_new;
                        email_template_new.owner.subject = v;
                        this.setState({email_template_new});
                      }}
                    />
                    <TextField
                      label="Content"
                      helpText="Use these values: [[customer_first_name]], [[customer_last_name]], [[customer_email]], [[store_contact_email]], [[line_items]], [[order_total_price]], [[entire_order_subtotal]], [[entire_order_shipping_cost]], [[you_saved_price]], [[order_number]], [[order_date]], [[view_order_button]], [[entire_order_discount]], [[shipping_method]], [[shipping_address]], [[shipping_notes]], [[billing_address]], [[payment_option]], [[delivery_date]]"
                      multiline={3}
                      value={email_template_new.owner.html}
                      onChange={(v) => {
                        var email_template_new = this.state.email_template_new;
                        email_template_new.owner.html = v;
                        this.setState({email_template_new});
                      }}
                    />
                  </Stack>
                </div>
              }
            </Stack>
          </Collapsible>
        </div>
        <div>
          <Stack vertical spacing="extraTight">
            <div>
              <Stack>
                <Stack.Item fill>
                    <Heading>Customer email configurations</Heading>
                    <div>
                        <TextStyle variation="subdued">subject and body</TextStyle>
                    </div>
                </Stack.Item>
                <Stack.Item>
                    <Button size="slim"
                        onClick={() => {this.setState({customer_template_toggle:!customer_template_toggle})}}
                    >{customer_template_toggle?"Close":"Open"}</Button>
                    </Stack.Item>
              </Stack>
            </div>
          </Stack>
        </div>
        <div>
          <Collapsible
            open={customer_template_toggle}
            id="basic-collapsible"
            transition={{duration: '150ms', timingFunction: 'ease'}}
          >
            <div>
              <Stack vertical spacing="tight">
                <Checkbox
                  label="Send customer order confirmation Email"
                  checked={customer_email_notify}
                  id="customer_email_notify"
                  onChange={(v) => {this.setState({customer_email_notify:v})}}
                />
                {
                  customer_email_notify && <div>
                    <Stack vertical>
                      <TextField
                        label="Subject"
                        value={email_template_new.customer.subject}
                        onChange={(v) => {
                          var email_template_new = this.state.email_template_new;
                          email_template_new.customer.subject = v;
                          this.setState({email_template_new});
                        }}
                      />
                      <TextField
                        label="Content"
                        helpText="Use these values: [[customer_first_name]], [[customer_last_name]], [[customer_email]], [[store_contact_email]], [[line_items]], [[order_total_price]], [[entire_order_subtotal]], [[entire_order_shipping_cost]], [[you_saved_price]], [[order_number]], [[order_date]], [[view_order_button]], [[entire_order_discount]], [[shipping_method]], [[shipping_address]], [[shipping_notes]], [[billing_address]], [[payment_option]], [[delivery_date]]"
                        multiline={3}
                        value={email_template_new.customer.html}
                        onChange={(v) => {
                          var email_template_new = this.state.email_template_new;
                          email_template_new.customer.html = v;
                          this.setState({email_template_new});
                        }}
                      />
                    </Stack>
                  </div>
                }
              </Stack>
            </div>
          </Collapsible>
        </div>
      </Stack>
    </Card>;

var toast = this.state.showToast? (
  <ToastMessage
	onDismiss={this.dissmissToast}
	message={this.state.toastMsg}
	error={this.state.toastError}
  />
): (null);


    if(this.state.page_loaded){
      return (
        <div>
          <PageTitleBar
            title="Net Term addon"
            breadcrumbs={[
              { content: 'Dashboard', url: BUILD_VERSION_EXTENSION+'/dashboard', target: 'APP' }
            ]}
            primaryAction={{content: "Save", onAction: () => this.saveData(), disabled: !this.IsValid() }}
            secondaryActions={[{content: "Back", url:BUILD_VERSION_EXTENSION+"/dashboard", target:"APP"}]}
          />
          {toast}
          {
            CommonHelper.isAdmin() && <div style={{marginBottom: "1rem"}}>
              <Stack distribution="trailing">
                <Button 
                  primary
                  loading={this.state.upgrading}
                  disabled={this.state.upgrading}
                  onClick={() => {
                    this.setState({
                      upgrading: true
                    });

                    axios.post('/app/upgrade-net-term-version', {
                      version: 1,
                      shop: this.props.shop,
                    })
                    .then(response => {
                      if (response && response.data) {
                        if (response && response.data && response.data.status === 1) {

                          this.setState({
                            upgrading: false
                          });
                          this.showToastMessage(response.data.message);

                          window.location.reload();
                        }
                        else{
                          this.showToastMessage(response.data.message, true); 
                        }
                        
                      }
                      else{
                        this.showToastMessage("An error occured", true); 
                      }
                    })
                    .catch(error => {
                      this.showToastMessage(error,true);
                    });
                  }}
                >
                  Upgrade to v1
                </Button>
              </Stack>
            </div>
          }
          {section1}
          {enabled && section2}
          {enabled && section3}
          {enabled && section4}
          {enabled && section5}
          {enabled && section6}
        </div>
      );
    }
    else{
      return (<SkeletonPageComp></SkeletonPageComp>);
    }
  }
}