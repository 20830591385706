import React from "react";

export default class LoadingOverlay extends React.Component{
   render(){
      return(
         <div className="wsaio--LoadingOverlay">
            <div className="spanner show">
              <div>
                 <div className="loader"></div>
                 <p>{this.props.message || "Saving data. Please wait..."}</p>
              </div>
            </div>
         </div>
      );
   }
}