import React, { Component } from 'react';
import { Card, TextStyle, Spinner, ResourceList, Link, Avatar, ResourceItem, EmptyState } from '@shopify/polaris';
import axios from '../Axios';
import ToastMessage from '../Common/ToastMessage';
const BUILD_VERSION_EXTENSION =  process.env.REACT_APP_BUILD_VERSION_EXTENSION;

export default class CustomersList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shop: this.props.shop,
            all_customers: [],
            selectedCustomers: [],
            loading: true,
            appliedFilters: [],
            searchValue: "",
            filtered_all_customers: [],
            page_loaded: false,
            selected_tab: this.props.match.params.status === "pending"?0:1,
            customer_status: this.props.status || "pending",
            wholesale_form_exists: false,
            wholesale_form_fetched: false,
            pending_customer_count: 0,
            rejected_customer_count: 0,
            showToast: false,
            toastMsg: '',
            toastError: false
        }
        axios.defaults.headers.common['X-Ws-Shop-Domain'] = "https://"+this.props.shop;
        console.log("this.props", this.props);
    }

    showToastMessage(msg, error){
        this.setState({
          showToast: true,
          toastMsg: msg,
          toastError: error||false,
        });
    }
    
    dissmissToast = () =>{
        this.setState({
          showToast: false,
        });
    }

    componentDidMount() {
        if(this.props.shop){
            this.getCount("pending");
            this.getCount("rejected");
            this.fetchCustomers(this.state.customer_status);
            this.getWholesaleForm();
        }
        else{
            this.setState({page_loaded: true, loading: false});
        }
    }

    getWholesaleForm(){
        if(this.props.shop){
            axios.get('/app/get-wholesale-form/'+ this.props.shop).then(r=>{
                if(r && r.data && r.data.status === 1){
                    if(r.data.wholesale_form){
                        this.setState({wholesale_form_exists:true,wholesale_form_fetched:true});
                    }
                    else{
                        this.setState({wholesale_form_exists:false,wholesale_form_fetched:true});
                    }
                }
                else{
                    this.setState({wholesale_form_exists:false,wholesale_form_fetched:true});
                }
            }).catch(e=>{
                this.setState({wholesale_form_exists:false,wholesale_form_fetched:true});
            })
        }
    }

    fetchCustomers(status){
        this.setState({loading:true});
        this.getCount("pending");
        this.getCount("rejected");
        axios.get('/customer/api/335/admin/get?shop='+ this.props.shop+'&status='+status).then(r=>{
            if(r && r.data){
                var all_customers = r.data;
                var filtered_all_customers = r.data;
                this.setState({all_customers,filtered_all_customers})
            }
            else{
                this.flashMessage("Server error. Rrefresh page", true);
            }
            this.setState({page_loaded: true,loading:false});
        }).catch(e=>{
            this.flashMessage("Server error", true);
            this.setState({page_loaded: true,loading:false});
        });
    }

    flashMessage(message, error) {
        this.showToastMessage(message, error);
    }

    getCount(status){
        axios.get('/customer/api/335/admin/count?shop='+this.props.shop+"&status="+status).then(response => {
            if(response && response.data && typeof response.data.count !== "undefined"){
                if(status === "pending"){
                    this.setState({pending_customer_count: response.data.count});
                }
                else if(status === "rejected"){
                    this.setState({rejected_customer_count: response.data.count});
                }
            }
        }).catch(e => {this.flashMessage("Server error",true)});
    }

    approveCustomer(item){
        try{
            item.version = "234";
        }catch(e){}
        axios.post('/customer/v/2/approve', item).then(response => {
            if(response && response.data.status === 1){
                this.flashMessage(response.data.message);
                this.fetchCustomers("pending");
            }
            else{
                this.flashMessage(response.data.message,true);
            }
        }).catch(e => {this.flashMessage("Server error",true)});
    }

    denyCustomer(item){
        try{
            item.version = "234";
        }catch(e){}
        axios.post('/customer/v/2/deny', item).then(response => {
            if(response && response.data.status === 1){
                this.flashMessage(response.data.message);
                this.fetchCustomers("pending");
            }
            else{
                this.flashMessage(response.data.message,true);
            }
        }).catch(e => {this.flashMessage("Server error",true)});
    }

    renderItem = (item, id) => {
        const {customer} = item;
        const key = item.id || item._id;
        var media = <Avatar customer size="medium" name={customer.first_name+" "+customer.last_name} />;
        var url_edit = this.state.selected_tab===2?(BUILD_VERSION_EXTENSION+"/customer/shopify/"+key):(BUILD_VERSION_EXTENSION+"/customer/"+item._id);


        

        return (
            <div className="CustomerListItem">
                <ResourceItem
                    id={id}
                    media={media}
                    url={url_edit}
                >
                <div>
                <Link url={this.state.selected_tab===2?(BUILD_VERSION_EXTENSION+"/customer/shopify/"+key):(BUILD_VERSION_EXTENSION+"/customer/"+key)} target="APP">{customer.first_name} {customer.last_name}</Link>
                </div>
                <div>{customer.email}</div>
                </ResourceItem>
            </div>
        );
    };

    handleSelectionChange = (selectedItems) => {
        this.setState({
            selectedCustomers: selectedItems
        });
    };

    handleFiltersChange = (appliedFilters) => {
        this.setState({appliedFilters});
    };

    handleSearchChange = (searchValue) => {
        try{
            var tem_items = this.state.all_customers.filter(x => x.customer.first_name.match(new RegExp(searchValue, 'i')));
            if(tem_items.length < 1){
                tem_items = this.state.all_customers.filter(x => x.customer.last_name.match(new RegExp(searchValue, 'i')));
            }
            if(tem_items.length < 1){
                tem_items = this.state.all_customers.filter(x => x.customer.email.match(new RegExp(searchValue, 'i')));
            }
            this.setState({
                filtered_all_customers: tem_items,
                searchValue: searchValue
            })
        }catch(e){}
    };


    redirectShopifyCustomerList(query){
        window.open("https://"+this.props.shop+"/admin/customers"+query)
    }

    render() {
        const { selected_tab } = this.state;

        const resourceName = {
            singular: 'customer',
            plural: 'customers',
        };

        const emptyStateMarkup = <EmptyState
            heading="No customers found"
        >
        <p>
            Nobody applied for wholesale account.
        </p>
      </EmptyState>;

        const resourceList = this.state.filtered_all_customers.length > 0 ?
        <Card.Section>
            <ResourceList
              resourceName={resourceName}
              items={this.state.filtered_all_customers}
              renderItem={this.renderItem}
              selectedItems={this.state.selectedCustomers}
              onSelectionChange={this.handleSelectionChange}
              loading={this.state.loading}
            ></ResourceList>
        </Card.Section>:
        <Card.Section>
            <ResourceList
              emptyState={emptyStateMarkup}
              resourceName={resourceName}
              items={[]}
              renderItem={() => {}}
              loading={this.state.loading}
            ></ResourceList>
        </Card.Section>;

        const customersListSection = <Card>
            {
                selected_tab < 2 && <Card.Section>
                    <TextStyle variation="subdued">
                        {
                            selected_tab === 0 && <span>See list of customers who are waiting for account approval</span>
                        }
                        {
                            selected_tab === 1 && <span>Customers list</span>
                        }
                    </TextStyle>
                </Card.Section>
            }
            {
                selected_tab < 2 && resourceList
            }
        </Card>;

        var toast = this.state.showToast? (
        <ToastMessage
          onDismiss={this.dissmissToast}
          message={this.state.toastMsg}
          error={this.state.toastError}
        />
        ): (null);

        if(this.state.page_loaded){
            return (
                <div>
                    {toast}
                    {customersListSection}
                </div>
            );
        }
        else{
            return (
                <div>
                    {toast}
                    <div className="snipper-container">
                        <center><Spinner color="teal" size="large" /></center>
                    </div>
                </div>
            )
        }
    }
}